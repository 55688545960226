import { useClickOutside } from '@/app/hooks/useClickOutside';
import Styles from './index.module.scss';
import ReactDom from 'react-dom';
import { useEffect, useLayoutEffect } from 'react';

interface Props {
	onCloseRequested?: () => void;
	parentRef?: React.RefObject<HTMLElement>;
	children?: React.ReactNode;
	className?: string;
	alignment?: 'left' | 'right';
	width?: number | 'auto';
}

export const ContextMenu = ({
	onCloseRequested,
	parentRef,
	children,
	className,
	alignment = 'right',
	width = 148,
}: Props) => {
	const { ref } = useClickOutside<HTMLDivElement>(() => {
		onCloseRequested?.();
	});

	useEffect(() => {
		const eventHandler = () => {
			onCloseRequested?.();
		};

		const timer = setTimeout(() => {
			window.addEventListener('resize', eventHandler, true);
		}, 0);

		return () => {
			clearTimeout(timer);
			window.removeEventListener('resize', eventHandler, true);
		};
	}, [onCloseRequested, ref]);

	useLayoutEffect(() => {
		if (parentRef?.current && ref.current) {
			const offsetLeft = width === 'auto' ? 10 : 0;
			const offsetRight = width === 'auto' ? 12 : 0;

			const parentRect = parentRef.current.getBoundingClientRect();
			ref.current.style.bottom = `${Math.max(0, window.innerHeight - parentRect.bottom - ref.current.getBoundingClientRect().height - window.scrollY)}px`;
			ref.current.style.right = `${Math.max(0, window.innerWidth - parentRect.right - window.scrollX + (window.scrollX >= 0 ? 0 : offsetRight))}px`;

			if (alignment === 'left') {
				ref.current.style.left = `${parentRect.left + window.scrollX + offsetLeft}px`;
			}

			if (width === 'auto') {
				ref.current.style.width = 'auto';
			} else {
				ref.current.style.width = `${width}px`;
			}
		}
	}, [parentRef, ref, alignment, width]);

	return (
		<>
			{ReactDom.createPortal(
				<div className={Styles.container}>
					<div
						ref={ref}
						className={`${Styles.popup} ${className ?? ''}`}
						style={{
							width: width === 'auto' ? undefined : `${width}px`,
						}}
					>
						{children}
					</div>
				</div>,
				document.body
			)}
		</>
	);
};
