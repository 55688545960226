import { useSearchUsersQuery } from '@/app/api/Users';
import { PaginatedResponse } from '@/app/types/Response';
import { User } from '@/app/types/User';

const USERS_EMPTY_ARRAY = [];

export const useUsersDictionary = (): {
	users: {
		[key: string]: User;
	};
	isFetching: boolean;
} => {
	const { data = {} as PaginatedResponse<User>, isFetching } = useSearchUsersQuery({
		filter: {
			pageSize: 300,
		},
	});

	const { items = USERS_EMPTY_ARRAY } = data;

	return {
		users: items.reduce((acc, curr) => ((acc[curr.id ?? ''] = curr), acc), {}),
		isFetching,
	};
};
