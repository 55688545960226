import Styles from './index.module.scss';
import ReactDOM from 'react-dom';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';
import { ChevronDownIcon, ChevronUpIcon } from 'components/Elements/Icons';
import { SelectProps } from './types';

export const Select = ({
	value,
	options = [],
	placeholder,
	onChange,
	parentScrollRef,
	renderMode = 'default',
	disabled = false,
}: SelectProps) => {
	const [visible, setVisible] = useState(false);
	const popupRef = useRef<HTMLDivElement>(null);

	const { ref: containerRef } = useClickOutside<HTMLDivElement>(() => {
		setVisible(false);
	});

	useLayoutEffect(() => {
		if (visible && containerRef.current && popupRef.current) {
			const x = containerRef.current.getBoundingClientRect().x;
			const width = containerRef.current.getBoundingClientRect().width;
			let bottom = containerRef.current.getBoundingClientRect().bottom;

			if (popupRef.current.getBoundingClientRect().height + bottom > window.innerHeight) {
				bottom =
					bottom -
					(window.innerHeight -
						(popupRef.current.getBoundingClientRect().height + bottom)) *
						-1;
			}

			popupRef.current.style.top = `${bottom}px`;
			popupRef.current.style.left = `${x}px`;
			popupRef.current.style.width = `${width}px`;
		}
	}, [visible, containerRef]);

	useEffect(() => {
		const handler = () => {
			setVisible(false);
		};

		if (visible) {
			window.addEventListener('resize', handler);
		}

		return () => {
			window.removeEventListener('resize', handler);
		};
	}, [visible]);

	useEffect(() => {
		const element = parentScrollRef?.current;
		if (!element) {
			return;
		}

		const handler = () => {
			setVisible(false);
		};

		element.addEventListener('scroll', handler, true);

		return () => {
			element.removeEventListener('scroll', handler, true);
		};
	}, [visible, parentScrollRef]);

	const selected = value?.name ?? placeholder;

	return (
		<div
			className={`${Styles.wrapper} ${disabled ? Styles.disabled : ''} ${renderMode === 'flat' ? Styles.flat : ''}`}
			ref={containerRef}
		>
			<div
				className={Styles.container}
				onClick={() => {
					setVisible(true);
				}}
			>
				<div className={Styles.inputArea}>
					<div className={Styles.value}>{selected ?? ''}</div>
				</div>
				<div className={Styles.separator} />
				<div className={Styles.button}>
					{visible ? (
						<ChevronUpIcon className={Styles.chevron} />
					) : (
						<ChevronDownIcon className={Styles.chevron} />
					)}
				</div>
			</div>
			{visible && (
				<>
					{ReactDOM.createPortal(
						<div
							className={`${Styles.popup} ${renderMode === 'flat' ? Styles.flatPopup : ''}`}
							ref={popupRef}
						>
							<div className={Styles.scrollView}>
								{options.map((item, index) => {
									return (
										<span
											key={index}
											className={`${Styles.item}`}
											onClick={() => {
												onChange?.(item);
												setVisible(false);
											}}
										>
											{item.name}
										</span>
									);
								})}
							</div>
						</div>,
						document.body
					)}
				</>
			)}
		</div>
	);
};
