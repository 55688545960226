import { SettingsDefaultImage } from '@/components/Elements/Images';
import Styles from './index.module.scss';

export const SettingsDefault = () => {
	return (
		<div className={Styles.container}>
			<img src={SettingsDefaultImage} alt="placeholder" />
			<span>
				Select a feature from the toolbox on the left to add configurations to the
				organization.
			</span>
		</div>
	);
};
