import { ProfileDropdown } from 'components/ProfileDropdown';
import Styles from './index.module.scss';

interface Props {
	children?: React.ReactNode;
	title: string;
	filter?: React.ReactElement;
}

export const PageLayout = ({ children, title, filter }: Props) => {
	return (
		<div className={Styles.container}>
			<div className={Styles.header}>
				<h1>{title}</h1>
				{filter}
				<ProfileDropdown />
			</div>
			{children}
		</div>
	);
};
