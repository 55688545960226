import Styles from './index.module.scss';
import { useCallback } from 'react';
import { ResponseRatioFilter } from '@/app/types/Dashboard';

interface Props {
	value: ResponseRatioFilter;
	onChange: (e: ResponseRatioFilter) => void;
}

export const Filter = ({ value, onChange }: Props) => {
	const register = useCallback(
		(e: ResponseRatioFilter) => {
			return {
				className: value === e ? Styles.active : '',
				onClick: () => onChange(e),
			};
		},
		[onChange, value]
	);

	return (
		<div className={Styles.filter}>
			<button type="button" {...register('all')}>
				<div />
				<span>all</span>
			</button>
			<button type="button" {...register('accepted')}>
				<div />
				<span>accepted</span>
			</button>
			<button type="button" {...register('declined')}>
				<div />
				<span>declined</span>
			</button>
			<button type="button" {...register('unanswered')}>
				<div />
				<span>no response</span>
			</button>
		</div>
	);
};
