import { isEndOfDay, isEndOfDayToday, isLaterToday, isTomorrow } from './date';
import { Moment } from 'moment';

export const formatStart = (time: Moment) => {
	if (!time.isValid()) {
		return '';
	}

	if (time.isSame(new Date(), 'day') && time.isAfter(new Date())) {
		return `Today, ${time.format('HH:mm')}`;
	}

	return 'Today';
};

export const formatEnd = (time: Moment) => {
	if (!time.isValid()) {
		return '';
	}

	if (isEndOfDayToday(time)) {
		return `End of Day`;
	}

	if (isLaterToday(time)) {
		return `Today, ${time.format('HH:mm')}`;
	}

	if (isTomorrow(time) && !isEndOfDay(time)) {
		return `Tomorrow, ${time.format('HH:mm')}`;
	}

	if (isTomorrow(time)) {
		return `Tomorrow`;
	}

	if (!isEndOfDay(time)) {
		return `${time.format('ddd, DD MMM')}, ${time.format('HH:mm')}`;
	}

	return `${time.format('ddd, DD MMM')}`;
};

export default {
	formatStart,
	formatEnd,
};
