import React from 'react';
import { InputSelect } from '../Elements/InputSelect';
import Styles from './index.module.scss';
import { formatInitials } from '@/utils/strings';
import { Day, WorkingHoursValue } from './types';
import { DAYS, DEFAULT_WORKING_HOURS } from './constants';

interface Props {
	value?: WorkingHoursValue;
	onChange?: (e: WorkingHoursValue) => void;
	variant?: 'default' | 'inline';
	title?: string;
}

export const WorkingHoursPicker = ({
	value = DEFAULT_WORKING_HOURS,
	onChange,
	variant = 'default',
	title = 'Day',
}: Props) => {
	const hours = Array.from({ length: 24 }, (_, i) => {
		return {
			text: i.toString().padStart(2, '0'),
			value: i,
		};
	});

	const minutes = Array.from({ length: 60 }, (_, i) => {
		return {
			text: i.toString().padStart(2, '0'),
			value: i,
		};
	});

	const handleChange = (
		day: Day,
		type: 'start' | 'end',
		time: number,
		key: 'hours' | 'minutes'
	) => {
		onChange?.({
			...value,
			[day]: {
				...value[day],
				[type]: {
					...value[day][type],
					[key]: time,
				},
			},
		});
	};

	return (
		<div className={`${Styles.container} ${variant === 'inline' ? Styles.inline : ''}`}>
			{variant === 'default' && (
				<>
					<div className={Styles.header}>
						<span>{title}</span>
					</div>
					<div className={Styles.header}>
						<span>Start</span>
					</div>
					<div className={Styles.header}>
						<span>End</span>
					</div>
					<div className={Styles.separator} />
				</>
			)}

			{DAYS.map((day, index) => {
				const startHours = value?.[day]?.start.hours ?? 0;
				const startMinutes = value?.[day]?.start.minutes ?? 0;
				const endHours = value?.[day]?.end.hours ?? 0;
				const endMinutes = value?.[day]?.end.minutes ?? 0;

				return (
					<React.Fragment key={day}>
						{variant === 'inline' && index === 0 && (
							<div className={Styles.rowTitle}>
								<span>{title}</span>
							</div>
						)}
						<div className={Styles.title}>
							<div className={Styles.avatar}>{formatInitials(day)}</div>
							<span>{day}</span>
						</div>
						{variant === 'inline' && index === 0 && (
							<div className={Styles.rowTitle}>
								<span>Start</span>
							</div>
						)}
						<div className={`${Styles.picker} ${Styles.pickerFirst}`}>
							<InputSelect
								onChange={(e) => handleChange(day, 'start', e as number, 'hours')}
								value={startHours}
								variant="time"
								items={hours}
							/>
							<InputSelect
								onChange={(e) => handleChange(day, 'start', e as number, 'minutes')}
								value={startMinutes}
								variant="time"
								items={minutes}
							/>
						</div>
						{variant === 'inline' && index === 0 && (
							<div className={Styles.rowTitle}>
								<span>End</span>
							</div>
						)}
						<div className={`${Styles.picker} ${Styles.pickerLast}`}>
							<InputSelect
								onChange={(e) => handleChange(day, 'end', e as number, 'hours')}
								value={endHours}
								variant="time"
								items={hours}
							/>
							<InputSelect
								onChange={(e) => handleChange(day, 'end', e as number, 'minutes')}
								value={endMinutes}
								variant="time"
								items={minutes}
							/>
						</div>
						<div
							className={`${Styles.separator} ${variant === 'inline' && index > 0 ? Styles.reducedWith : ''}`}
						/>
					</React.Fragment>
				);
			})}
		</div>
	);
};
