import Styles from './index.module.scss';
import { useMemo } from 'react';

import { FilterStatusType, StatusList } from 'types/TaskStatusFilter';

interface Props {
	value: FilterStatusType;
	onChange?: (e: FilterStatusType) => void;
}

export const TaskStatusFilter = ({ value, onChange }: Props) => {
	const statuses = useMemo((): StatusList => {
		return [
			{
				status: 'all',
			},
			{
				status: 'scheduled',
				css: 'scheduled',
			},
			{
				status: 'ongoing',
				css: 'ongoing',
			},
			{
				status: 'completed',
				css: 'completed',
			},
			{
				status: 'attention',
				css: 'attention',
			},
		];
	}, []);

	return (
		<div className={Styles.container}>
			{statuses.map((item) => {
				return (
					<button
						onClick={() => {
							onChange?.(item.status);
						}}
						key={item.status}
						className={value === item.status ? Styles.active : ''}
					>
						{item.css && <div className={Styles[item.css]} />}
						<span>{item.status}</span>
					</button>
				);
			})}
		</div>
	);
};
