import { Button } from '@/components/Elements/Button';
import Styles from './index.module.scss';
import { msalInstance } from '@/services/msal';
import { MsalAuthenticationResult } from '@azure/msal-react';
import { JsonViewer } from '@/components/JsonViewer';

export const ErrorPage = ({ error }: MsalAuthenticationResult) => {
	return (
		<div className={Styles.container}>
			<div className={Styles.border}>
				<p>Failed to authenticate!</p>

				<span>
					We are unable to authenticate you. Please try to logout and login again.
				</span>

				{/* <pre>{`${JSON.stringify(error, null, 2)}`}</pre> */}

				{error && <JsonViewer item={error} />}

				<Button
					onClick={async () => {
						try {
							const logoutRequest = {
								account: msalInstance.getAllAccounts[0],
							};

							await msalInstance.logoutRedirect(logoutRequest);
						} catch (e) {
							console.error(e);
						}
					}}
				>
					Logout
				</Button>
			</div>
		</div>
	);
};
