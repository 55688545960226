import Styles from './index.module.scss';
import { Sidebar } from 'components/Sidebar';
import { Outlet } from 'react-router-dom';
// import { Toaster } from 'react-hot-toast';
// import { ToastContainer } from 'react-toastify';

export const DefaultLayout = () => {
	return (
		<div className={Styles.container}>
			<Sidebar />
			<Outlet />
		</div>
	);
};
