import { SettingsClockStopWatchIcon } from '@/components/Elements/Icons';
import { SettingsRowElement } from '@/components/SettingsRowElement';
import { SettingsView } from '@/components/SettingsView';
import { Controller, useFormContext } from 'react-hook-form';
import { CallForHelpField } from './CallForHelpField';
import { InputField } from './InputField';
import { UploadImageField } from './UploadImageField';
import { OrganizationFormValues } from '@/app/types/OrganizationFormValues';

export const FormWatch = () => {
	const { control } = useFormContext<OrganizationFormValues['settings']>();

	return (
		<SettingsView title="Watch.">
			<SettingsView.Section title="Home Screen">
				<UploadImageField />
			</SettingsView.Section>
			<SettingsView.Section title="Other Settings">
				<InputField name="welcomeMessage" title="Welcome Message" />
				<InputField name="goodbyeMessage" title="Goodbye Message" />
				<CallForHelpField />
				<SettingsRowElement
					title="Time for force return to watchface"
					subtitle="(milliseconds)"
					icon={<SettingsClockStopWatchIcon />}
				>
					<Controller
						control={control}
						name="watch.watchFaceForceReturn"
						render={({ field }) => {
							return (
								<SettingsRowElement.InputItem
									variant="small"
									value={field.value}
									onChange={(e) => {
										try {
											if (e.target.value === '') {
												field.onChange(0);
												return;
											}

											const number = Number.parseInt(e.target.value);

											if (isNaN(number)) {
												field.onChange(field.value);
												return;
											}
											field.onChange(number);
										} catch {
											field.onChange(field.value);
										}
									}}
								/>
							);
						}}
					/>
				</SettingsRowElement>
			</SettingsView.Section>
		</SettingsView>
	);
};
