import { useCallback, useEffect, useRef } from 'react';
import Styles from './index.module.scss';
import _ from 'lodash';

interface Props extends React.ComponentPropsWithRef<'textarea'> {
	className?: string;
}

export const ResizableTextArea = ({ className, onChange, ...props }: Props) => {
	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	const resize = useCallback(() => {
		if (textAreaRef.current) {
			textAreaRef.current.style.height = 'auto';
			textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
		}
	}, []);

	useEffect(() => {
		const element = textAreaRef?.current;
		if (!element) return;

		const debounceHandle = _.debounce(() => {
			resize();
		}, 500);

		const observer = new ResizeObserver(debounceHandle);

		observer.observe(element);
		return () => {
			observer.disconnect();
		};
	}, [resize]);

	return (
		<textarea
			{...props}
			ref={textAreaRef}
			className={`${Styles.container} ${className ?? ''}`}
			onChange={(e) => {
				resize();
				onChange?.(e);
			}}
		/>
	);
};
