import { apiSlice } from '../api';
import { PaginatedResponse } from 'types/Response';

import { User } from '@/app/types/User';
import { UsersSearchRequest } from '@/app/types/Request';

export const usersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		searchUsers: builder.query<PaginatedResponse<User>, Partial<UsersSearchRequest>>({
			query: ({ continuationToken, filter } = {}) => ({
				url: `users/search`,
				method: 'POST',
				body: {
					...(filter ?? {}),
					continuationToken,
				},
			}),
		}),
	}),
	overrideExisting: false,
});

export const { useSearchUsersQuery } = usersApiSlice;
