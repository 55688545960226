import { useDefaultSettingsQuery } from '@/app/api/Organizations';
import { FormOrganizationDataProvider } from '@/components/Organizations/FormOrganizationDataProvider';
import { FormValuesRouteProtector } from '@/components/Organizations/FormValuesRouteProtector';
import { GeneralInfoPathProtector } from '@/components/Organizations/GeneralInfoPathProtector';
import { Outlet } from 'react-router-dom';

export const CreateOrganization = () => {
	useDefaultSettingsQuery();

	return (
		<FormOrganizationDataProvider>
			<GeneralInfoPathProtector>
				<FormValuesRouteProtector protectedUrl="/organizations/create">
					<Outlet />
				</FormValuesRouteProtector>
			</GeneralInfoPathProtector>
		</FormOrganizationDataProvider>
	);
};
