import { DateTimeOffset } from '@/app/types/MessageContainer';
import { Task } from '@/app/types/Task';
import moment from 'moment';
import { Moment } from 'moment';

export const isLaterToday = (time: Moment | Date) => {
	return moment(time).isSame(new Date(), 'day') && moment(time).isAfter(new Date());
};

export const isEndOfDayToday = (time: Moment | Date) => {
	return moment(time).isSame(moment(new Date()).endOf('day'));
};

export const isEndOfDay = (time: Moment | Date) => {
	return moment(time).isSame(moment(time).endOf('day'));
};

export const isStartOfDay = (time: Moment | Date) => {
	return moment(time).isSame(moment(time).startOf('day'));
};

export const isTomorrow = (time: Moment | Date) => {
	return moment(time).isSame(moment(new Date()).add(1, 'day'), 'day');
};

export const parseTime = (task: Task) => {
	const { date = '', time = '', offset = '' } = task?.config?.start ?? ({} as DateTimeOffset);

	const start = moment.parseZone(`${date}T${time}${offset}`);

	const {
		date: endDate = '',
		time: endTime = '',
		offset: endOffset = '',
	} = task?.config?.end ?? ({} as DateTimeOffset);

	const end = moment.parseZone(`${endDate}T${endTime}${endOffset}`);

	return { start, end };
};
