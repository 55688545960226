import { ElementProps } from './types';
import Styles from './index.module.scss';

export const Status = ({ item }: ElementProps) => {
	const max = item.progress?.max ?? 0;
	const value = item.progress?.value ?? 0;

	return (
		<div className={Styles.status}>
			<span>{item.status}</span>
			{max > 0 && (
				<>
					<div className={Styles.progressBar}>
						<div
							className={Styles.value}
							style={{
								width: `${(value / max) * 100}%`,
							}}
						></div>
					</div>
					<span className={Styles.valueText}>{`${value}/${max}`}</span>
				</>
			)}
		</div>
	);
};
