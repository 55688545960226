import { SettingHelpWheelIcon } from '@/components/Elements/Icons';
import { SettingsRowElement } from '@/components/SettingsRowElement';
import { Controller, useFormContext } from 'react-hook-form';

export const CallForHelpField = () => {
	const { control } = useFormContext();
	return (
		<SettingsRowElement title="Call for Help Menu" icon={<SettingHelpWheelIcon />}>
			<Controller
				control={control}
				name="watch.callForHelp"
				render={({ field }) => {
					return (
						<SettingsRowElement.SelectItem
							text="Select Trigger Group from Automation"
							items={[]}
							size="medium"
							value={field.value}
							onChange={field.onChange}
						/>
					);
				}}
			/>
		</SettingsRowElement>
	);
};
