import Styles from './index.module.scss';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import { ChevronDownIcon } from 'components/Elements/Icons';
import { useState } from 'react';

export const DropdownNavigation = () => {
	const [isVisible, setIsVisible] = useState(false);

	const className = ({ isActive }: { isActive: boolean }) => {
		return isActive ? Styles.active : '';
	};

	const { pathname } = useLocation();
	const isMatch = ['/organizations', '/users', '/devices', '/settings'].some((path) =>
		matchPath(path, pathname)
	);

	return (
		<div className={Styles.submenu}>
			<NavLink to={'/system-status'} className={className}>
				System Status
			</NavLink>
			<div
				className={`${Styles.dropdown} ${isMatch ? Styles.visible : ''}`}
				onClick={() => {
					setIsVisible((state) => !state);
				}}
			>
				Setup
				<ChevronDownIcon />
			</div>
			{isVisible && (
				<div className={`${Styles.dropdownContent}`}>
					<NavLink to={'/organizations'} className={className}>
						Organizations
					</NavLink>
					<NavLink to={'/users'} className={className}>
						Users
					</NavLink>
					<NavLink to={'/devices'} className={className}>
						Devices
					</NavLink>
					<NavLink to={'/settings'} className={className}>
						Settings
					</NavLink>
				</div>
			)}
		</div>
	);
};
