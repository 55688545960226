import { RepeatIcon, ShareIcon } from '@/components/Elements/Icons';
import Styles from './index.module.scss';
import { Select } from '@/components/Elements/Select';
import { SelectTime } from '@/components/Elements/SelectTime';
import { Calendar } from '@/components/Elements/Calendar';
import { CheckBox } from '@/components/Elements/CheckBox';
import { SelectTextOptions } from '@/components/Elements/SelectTextOptions';
import { SelectCheckbox } from '@/components/Elements/SelectCheckbox';
import { Button } from '@/components/Elements/Button';

interface Props {
	isLoading: boolean;
}

export const NotificationForm = ({ isLoading }: Props) => {
	return (
		<div className={Styles.container}>
			{/* <section>
				<div className={`${Styles.field} ${Styles.timeField}`}>
					<ShareIcon />
					<span>Send at</span>
					<Select
						placeholder="Right Now"
						renderMode="flat"
						disabled={true}
						options={[
							{ name: 'Right Now', value: 'Now', type: 'Now' },
							{
								name: 'Later Today',
								value: 'Today',
								type: 'Today',
							},
							{
								name: 'Tomorrow',
								value: 'Tomorrow',
								type: 'Tomorrow',
							},
							{
								name: 'Date',
								value: 'Date',
								type: 'Date',
							},
						]}
					/>
					<SelectTime />
				</div>
				<Calendar />
			</section>
			<section>
				<div className={`${Styles.field} ${Styles.checkField}`}>
					<RepeatIcon width={16} height={16} viewBox="0 0 12 12" />
					<span>Repeat</span>
					<CheckBox value={false} disabled={true} />
				</div>
				<SelectTextOptions
					value={[
						{ title: 'Everyday', value: false },
						{ title: 'Weekdays', value: false },
						{ title: 'Weekend', value: false },
						{ title: 'Custom', value: true },
					]}
				/>
				<SelectCheckbox
					value={[
						{ title: 'Mon', value: true },
						{ title: 'Tue', value: true },
						{ title: 'Wed', value: false },
						{ title: 'Thu', value: false },
						{ title: 'Fri', value: false },
						{ title: 'Sat', value: false },
						{ title: 'Sun', value: false },
					]}
				/>
			</section> */}

			<div className={Styles.submitArea}>
				<Button type="submit" disabled={isLoading}>
					Send Notification
				</Button>
			</div>
		</div>
	);
};
