import { Notification } from 'types/Notification';
import { apiSlice } from '../api';
import { PaginatedResponse } from 'types/Response';
import { NotificationSearchRequest } from 'types/Request';

export const notificationsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		searchNotifications: builder.query<
			PaginatedResponse<Notification>,
			NotificationSearchRequest
		>({
			query: ({ continuationToken, ...params }) => ({
				url: `notifications/search`,
				method: 'POST',
				body: {
					...params.filter,
					continuationToken,
				},
			}),
			forceRefetch: ({ currentArg, previousArg }) => {
				return (
					currentArg?.continuationToken !== previousArg?.continuationToken &&
					currentArg?.continuationToken !== undefined
				);
			},
			serializeQueryArgs: ({ endpointName, queryArgs }) => {
				return `${endpointName}(${JSON.stringify(queryArgs.filter)})`;
			},
			merge: (currentState, incomingState) => {
				currentState.items = [
					...(currentState.items ?? []),
					...(incomingState.items ?? []),
				];

				currentState.meta.continuation = incomingState?.meta?.continuation;
				currentState.meta.moreResults = incomingState?.meta?.moreResults;
			},
			providesTags: [{ type: 'NOTIFICATIONS', id: 'PARTIAL-LIST' }],
		}),
		createNotification: builder.mutation<void, Partial<Notification>>({
			query: (body) => {
				return {
					url: `/notifications`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: () => [{ type: 'NOTIFICATIONS', id: 'PARTIAL-LIST' }],
		}),
	}),

	overrideExisting: false,
});

export const {
	useSearchNotificationsQuery,
	useLazySearchNotificationsQuery,
	useCreateNotificationMutation,
} = notificationsApiSlice;
