import { useFormContext, useWatch } from 'react-hook-form';
import Styles from './index.module.scss';
import { OrganizationFormValues } from '@/app/types/OrganizationFormValues';
import { OrganizationErrorObject } from '@/app/types/Organization';
import { toErrorsDictionary } from '@/app/utils/organization/data';

interface Props {
	errors: OrganizationErrorObject[] | undefined;
}

export const MessageSettings = ({ errors }: Props) => {
	const { control } = useFormContext<OrganizationFormValues>();
	const { actions, tasks } = useWatch({ control, name: 'settings' });
	const mapper = toErrorsDictionary(errors);

	return (
		<>
			<div className={`${Styles.actions}`}>
				<h4 className={Styles.fullWidth}>Actions</h4>
				<h5>{`Auto-forward Time (Seconds)`}</h5>
				<div className={Styles.list}>
					{actions.autoForward.map((time, index) => {
						return (
							<div key={index} className={Styles.item}>
								<span>{index + 1}</span>
								<span>{time}</span>
							</div>
						);
					})}
				</div>
				{mapper?.['settings.actions.autoforwardafterseconds'] && (
					<span className={Styles.error}>
						{mapper?.['settings.actions.autoforwardafterseconds']}
					</span>
				)}
				<h5 className={`${Styles.fullWidth} ${Styles.bold}`}>{`Send Feedback`}</h5>
				<p>Acceptance Feedback:</p>
				<span>{actions.acceptanceFeedback.value ?? ''}</span>
				{mapper?.['settings.actions.feedbackwhenaccepted.message'] && (
					<span className={Styles.error}>
						{mapper?.['settings.actions.feedbackwhenaccepted.message']}
					</span>
				)}
				<p>Rejection Feedback:</p>
				<span>{actions.rejectionFeedback.value ?? ''}</span>
				{mapper?.['settings.actions.feedbackwhendeclined.message'] && (
					<span className={Styles.error}>
						{mapper?.['settings.actions.feedbackwhendeclined.message']}
					</span>
				)}

				<h5
					className={`${Styles.fullWidth} ${Styles.bold}`}
				>{`Accepted by colleague custom message`}</h5>
				<p>Title:</p>
				<span>{actions.acceptedByMessageTitle ?? ''}</span>
				{mapper?.['settings.actions.acceptedbyothertitle'] && (
					<span className={Styles.error}>
						{mapper?.['settings.actions.acceptedbyothertitle']}
					</span>
				)}
				<p>Body:</p>
				<span>{actions.acceptedByMessageBody ?? ''}</span>

				<h5>{`Completion Confirmation:`}</h5>
				<span>{actions.completionConfirmation ? 'ON' : 'OFF'}</span>
				{mapper?.['settings.actions.acceptedbyotherbody'] && (
					<span className={Styles.error}>
						{mapper?.['settings.actions.acceptedbyotherbody']}
					</span>
				)}

				<h5 className={Styles.fullWidth}>
					{`Use Splash Screens for incoming (Watch):`}
					<span>{actions.useSplashScreen ? 'ON' : 'OFF'}</span>
				</h5>

				<h5 className={Styles.fullWidth}>
					{`Duration of incoming message splash screen in milliseconds (Watch):`}
					<span>{actions.incomingMessageDuration}</span>
				</h5>
			</div>
			<div className={`${Styles.tasks} `}>
				<h4>Tasks</h4>

				<h5>{`Reminder Before Start:`}</h5>
				<span>
					{tasks?.reminderBeforeStart?.active
						? tasks?.reminderBeforeStart?.value || 'OFF'
						: 'OFF'}
				</span>

				<h5>{`Reminder Before End:`}</h5>
				<span>
					{tasks?.reminderBeforeEnd?.active
						? tasks?.reminderBeforeEnd?.value || 'OFF'
						: 'OFF'}
				</span>

				<h5>{`Overdue Reminder:`}</h5>
				<span>
					{tasks?.reminderOverdue?.active
						? tasks?.reminderOverdue?.value || 'OFF'
						: 'OFF'}
				</span>

				<h5>{`Overdue Reminders Interval:`}</h5>
				<span>
					{tasks?.reminderOverdueInterval
						? tasks?.reminderOverdueInterval || 'OFF'
						: 'OFF'}
				</span>

				<h5>{`Number of Overdue Reminders:`}</h5>
				<span>
					{tasks?.overdueRemindersNumber ? tasks?.overdueRemindersNumber || 'OFF' : 'OFF'}
				</span>
			</div>
		</>
	);
};
