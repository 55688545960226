import { useSearchUsersQuery } from '@/app/api/Users';
import { UsersSearchFilter } from '@/app/types/Filter';
import { PaginatedResponse } from '@/app/types/Response';
import { User } from '@/app/types/User';
import { useState } from 'react';

export const useUsersList = (value: User[], onChange: (e: User[]) => void) => {
	const [filter, setFilter] = useState<Partial<UsersSearchFilter>>({
		name: '',
		organizationIds: [],
	});

	const { data } = useSearchUsersQuery({
		filter,
	});

	const { items: users = [] } = data ?? ({} as PaginatedResponse<User>);

	const items = users.map((user) => {
		const selected = value?.find((val) => val.id === user.id) !== undefined;

		return {
			user,
			selected,
		};
	});

	const handleChange = (userItem: { selected: boolean; user: User }) => {
		if (userItem.selected) {
			onChange(value.filter((user) => user.id !== userItem.user.id));
			return;
		}

		onChange([...(value ?? []), userItem.user]);
	};

	return {
		items,
		filter,
		setFilter,
		handleChange,
	};
};
