import Styles from './index.module.scss';
import { NotificationListItem } from '@/app/types/Notification';
import { type MRT_Cell } from 'material-react-table';

export const OrganizationCell = (props: { cell: MRT_Cell<NotificationListItem, unknown> }) => {
	const item = props.cell.row.original;

	return (
		<div className={Styles.organization}>
			<h3>{item.organizationName}</h3>
			<span>{item.organizationLocation}</span>
		</div>
	);
};
