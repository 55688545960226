import { PaginatedResponse } from '@/app/types/Response';
import { apiSlice } from '../api';
import { OrganizationCreateDto, OrganizationInfo } from 'types/Organization';
import { SystemStatus } from '@/app/types/SystemStatus';
import { SystemStatusRequest } from '@/app/types/Request';
import { Settings } from '@/app/types/Settings';

export const organizationsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		organizations: builder.query<OrganizationInfo, void>({
			query: () => ({
				url: `me`,
			}),
			providesTags: ['ORGANIZATIONS'],
		}),
		createOrganization: builder.mutation<OrganizationCreateDto, Partial<OrganizationCreateDto>>(
			{
				query: (body) => {
					return {
						url: `/organizations`,
						method: 'POST',
						body,
					};
				},
				invalidatesTags: ['ORGANIZATIONS'],
			}
		),
		deleteOrganization: builder.mutation<OrganizationCreateDto, string>({
			query: (id) => {
				return {
					url: `/organizations/${id}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['ORGANIZATIONS'],
		}),
		systemStatus: builder.query<PaginatedResponse<SystemStatus>, SystemStatusRequest>({
			query: (body) => ({
				url: `organizations/status/search`,
				method: 'POST',
				body: {
					...body,
					pageSize: 500,
				},
			}),
		}),
		defaultSettings: builder.query<Settings, void>({
			query: () => ({
				url: `organizations/defaults/settings`,
				method: 'GET',
			}),
		}),
		checkName: builder.query<
			{
				isAvailable: true;
				availableHostname: string;
			},
			{ organizationName?: string }
		>({
			query: (body) => ({
				url: `organizations/check-name`,
				method: 'POST',
				body,
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useOrganizationsQuery,
	useLazyOrganizationsQuery,
	useSystemStatusQuery,
	useDefaultSettingsQuery,
	useCheckNameQuery,
	useLazyCheckNameQuery,
	useCreateOrganizationMutation,
	useDeleteOrganizationMutation,
} = organizationsApiSlice;
