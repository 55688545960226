import { createContext } from 'react';

export type FormSectionProps = {
	children: React.ReactNode;
	urlParam: string;
	backUrl?: string;
	nextUrl?: string;
	submitText?: string;
	mode?: 'create' | 'edit' | 'create-confirmation';
};

export const FormSectionContext = createContext<FormSectionProps>({} as FormSectionProps);
