import Styles from './index.module.scss';
import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { useOrganizationFormDataProvider } from '../FormOrganizationDataProvider';
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { withDisable } from '@/components/Elements/DisableLink';
import { ArrowIcon, CloseIcon } from '@/components/Elements/Icons';
import { FormSectionContext, FormSectionProps } from './types';
import { Footer } from './Footer';

const DisableMavLink = withDisable(NavLink);

export const FormSection = ({
	children,
	urlParam,
	backUrl,
	nextUrl,
	submitText,

	mode = 'create',
}: FormSectionProps) => {
	const { formValues } = useOrganizationFormDataProvider();
	const { isSubmitted, isDirty, isSubmitting } = useFormState();
	const { reset } = useFormContext();
	const navigate = useNavigate();

	const lockNavigation = useMemo(() => {
		return (mode === 'edit' && isDirty && !isSubmitted) || isSubmitting;
	}, [mode, isDirty, isSubmitted, isSubmitting]);

	const links = useMemo(() => {
		return [
			{
				name: 'General Info',
				url: `/organizations/${urlParam}/general-info`,
				disabled: lockNavigation || false,
			},
			{
				name: 'User Info',
				url: `/organizations/${urlParam}/user-info`,
				disabled: lockNavigation || !formValues.generalInfo,
			},
			{
				name: 'Configure Settings',
				url: `/organizations/${urlParam}/configure-settings`,
				disabled: lockNavigation || !formValues.userInfo,
			},
			{
				name: 'Summary',
				url: `/organizations/${urlParam}/summary`,
				disabled: lockNavigation || !formValues.settings,
			},
		];
	}, [urlParam, formValues, lockNavigation]);

	const className = useCallback(({ isActive }) => {
		return isActive ? Styles.active : '';
	}, []);

	useLayoutEffect(() => {
		if (mode === 'create' && nextUrl && isSubmitted) {
			navigate(nextUrl, { replace: true });
		}
	}, [mode, nextUrl, navigate, isSubmitted]);

	useEffect(() => {
		if (mode === 'edit' && isDirty && isSubmitted) {
			reset({}, { keepValues: true });
		}
	}, [isDirty, isSubmitted, mode, reset]);

	return (
		<FormSectionContext.Provider
			value={{ children, urlParam, submitText, backUrl, nextUrl, mode }}
		>
			<div className={Styles.header}>
				{backUrl && !lockNavigation && (
					<NavLink className={Styles.backButton} type="button" to={backUrl} replace>
						<ArrowIcon />
					</NavLink>
				)}
				<div className={Styles.navigation}>
					{links.map((link, index) => (
						<React.Fragment key={index}>
							<DisableMavLink
								className={className}
								to={link.url}
								replace
								title={link.name}
								disabled={link.disabled}
							>
								{link.name}
							</DisableMavLink>
							{index !== links.length - 1 && <div className={Styles.separator} />}
						</React.Fragment>
					))}
				</div>
				<NavLink className={Styles.closeButton} to={'/organizations'} replace>
					<CloseIcon />
				</NavLink>
			</div>
			<div className={Styles.content}>{children}</div>
			<Footer />
		</FormSectionContext.Provider>
	);
};
