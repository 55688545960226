import { NotificationListItem } from '@/app/types/Notification';
import { type MRT_TableOptions } from 'material-react-table';

export const TableOptions: Partial<MRT_TableOptions<NotificationListItem>> = {
	enableColumnOrdering: true,
	enableColumnDragging: true,
	enableColumnResizing: true,
	enableColumnActions: false,
	enableGlobalFilter: false,
	enableBottomToolbar: false,
	enableTopToolbar: false,
	enablePagination: false,
	enableSortingRemoval: false,
	enableExpandAll: false,
	enableExpanding: false,
	rowVirtualizerOptions: { overscan: 5 },
	enableRowVirtualization: true,
	enableRowActions: true,
	positionActionsColumn: 'last',
};
