import Styles from './index.module.scss';
import { UploadButton } from '@/components/Elements/UploadButton';
import { Controller, useFormContext } from 'react-hook-form';
import { SettingsFormValues } from '@/app/types/OrganizationFormValues';
import { resizeAndConvertFile } from '@/app/utils/images';
import { SettingsRowElement } from '@/components/SettingsRowElement';
import { SettingsImageIcon } from '@/components/Elements/Icons';

export const UploadImageField = () => {
	const { control } = useFormContext<SettingsFormValues>();

	return (
		<Controller
			control={control}
			name="watch.logo"
			render={({ field }) => {
				// const image = field.value ? createImageFromFile(field.value) : null;
				return (
					<SettingsRowElement
						title="Company Logo"
						subtitle="(for black background)"
						icon={<SettingsImageIcon />}
					>
						<div className={Styles.logoContainer}>
							{!field.value && <span>Upload logo from computer</span>}
							{field.value && (
								<div className={Styles.logoImage}>
									<img src={field.value} alt="placeholder" />
								</div>
							)}
							<div className={Styles.logoUpload}>
								<UploadButton
									onChange={async (e) => {
										try {
											if (!e) {
												return;
											}
											const base64Image = await resizeAndConvertFile(
												e,
												242,
												96
											);
											field.onChange(base64Image);
										} catch {}
									}}
								/>
							</div>
						</div>
					</SettingsRowElement>
				);
			}}
		/>
	);
};
