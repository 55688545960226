import React from 'react';
import Styles from './index.module.scss';

interface Props {
	title: string;
	children?: React.ReactNode;
}

interface SectionProps {
	children: React.ReactNode;
	title: string;
	actionElement?: React.ReactNode;
}

const Section = ({ children, title, actionElement }: SectionProps) => {
	return (
		<div className={Styles.section}>
			<div className={Styles.header}>
				<p>{title}</p>
				{actionElement}
			</div>
			<div>{children}</div>
		</div>
	);
};

export const SettingsView = ({ title, children }: Props) => {
	return (
		<div className={Styles.container}>
			<h3>{title}</h3>
			<div className={Styles.content}>{children}</div>
		</div>
	);
};

SettingsView.Section = Section;
