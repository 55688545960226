import { RouterProvider } from 'react-router-dom';
import pages from './pages';
import { Provider } from 'react-redux';
import { store } from './app/store';

import './assets/scss/fonts.scss';
import './assets/scss/main.scss';
import 'react-toastify/dist/ReactToastify.css';

export const App = () => {
	return (
		<Provider store={store}>
			<RouterProvider router={pages} />
		</Provider>
	);
};
