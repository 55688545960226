import { ElementProps } from './types';
import Styles from './index.module.scss';
import { CheckIcon } from '@/components/Elements/Icons';

export const Title = ({ item }: ElementProps) => {
	return (
		<div className={Styles.title}>
			{item.indicatorVisible && (
				<div className={`${Styles.indicator} ${Styles[item.indicator]}`}>
					<CheckIcon />
				</div>
			)}
			<span>{item.title}</span>
		</div>
	);
};
