import * as msal from '@azure/msal-browser';
import { RedirectRequest } from '@azure/msal-browser';

const msalConfig = {
	auth: {
		clientId: `${import.meta.env.VITE_MSAL_CLIENT_ID}`,
		authority: `${import.meta.env.VITE_MSAL_AUTHORITY}`,
		redirectUri: '/',
		postLogoutRedirectUri: '/',
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
	// system: {
	// 	loggerOptions: {
	// 		loggerCallback: (level, message, containsPii) => {
	// 			if (containsPii) {
	// 				return;
	// 			}
	// 			switch (level) {
	// 				case LogLevel.Error:
	// 					console.error(message);
	// 					return;
	// 				case LogLevel.Info:
	// 					console.info(message);
	// 					return;
	// 				case LogLevel.Verbose:
	// 					console.debug(message);
	// 					return;
	// 				case LogLevel.Warning:
	// 					console.warn(message);
	// 					return;
	// 				default:
	// 					return;
	// 			}
	// 		},
	// 	},
	// },
};

const scopes = [
	'openid',
	'profile',
	'email',
	`api://${import.meta.env.VITE_MSAL_CLIENT_ID}/claim.read`,
	'offline_access',
];

const loginRequest: RedirectRequest = {
	scopes: scopes,
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export { msalInstance, loginRequest, scopes };
