import { SettingsWatchImage } from '@/components/Elements/Images';
import Styles from '../index.module.scss';

interface Props {
	logo?: string;
}
export const LogoPreview = ({ logo }: Props) => {
	return (
		<div className={Styles.preview}>
			<div className={Styles.header}>
				<h4>Preview screens.</h4>
			</div>
			<div className={Styles.imageContainer}>
				<img className={Styles.watch} src={SettingsWatchImage} alt="Preview" />
				{logo && <img className={Styles.logo} src={logo} alt="logo" />}
			</div>
		</div>
	);
};
