import Styles from './index.module.scss';

const COLORS = [
	'#18C096',
	'#2999A3',
	'#2970A3',
	'#2947A3',
	'#3329A3',
	'#5C29A3',
	'#8529A3',
	'#1AE5B2',
	'#1AD5E5',
	'#1A91E5',
	'#1A4DE5',
	'#5547EB',
	'#7A2CE8',
	'#B21AE5',
	'#AD0116',
	'#EA6200',
	'#EBB700',
	'#FF1E1E',
	'#FF9100',
	'#FFD600',
	'#656565',
];

export const DEFAULT_VALUE = COLORS[0];

interface Props {
	onChange?: (e: string) => void;
	value?: string;
}

export const ColorPicker = ({ onChange, value }: Props) => {
	return (
		<div className={Styles.container}>
			{COLORS.map((color, i) => {
				return (
					<div
						key={i}
						className={`${Styles.color} ${value === color ? Styles.active : ''}`}
					>
						<div
							style={{ background: color }}
							onClick={() => {
								onChange?.(color);
							}}
						/>
					</div>
				);
			})}
		</div>
	);
};
