import { Navigate, useLocation } from 'react-router-dom';
import { useOrganizationFormDataProvider } from '../FormOrganizationDataProvider';

interface Props {
	children: React.ReactNode;
}

export const GeneralInfoPathProtector = ({ children }: Props) => {
	const { formValues } = useOrganizationFormDataProvider();
	const location = useLocation();

	if (
		Object.keys(formValues).length == 0 &&
		location.pathname !== '/organizations/create/general-info'
	) {
		return <Navigate to={'/organizations/create/general-info'} replace />;
	}

	return <>{children}</>;
};
