import { OrganizationErrorObject } from '@/app/types/Organization';
import { OrganizationFormValues } from '@/app/types/OrganizationFormValues';
import React from 'react';

export type FormState = {
	formValues: Partial<OrganizationFormValues>;
	setFormValues: (e: Partial<OrganizationFormValues>) => void;
	isSubmitting?: boolean;
	create: (data: Partial<OrganizationFormValues>) => Promise<unknown>;
	errors: OrganizationErrorObject[] | undefined;
	isSuccess?: boolean;
};

export const OrganizationFormValuesContext = React.createContext<FormState | undefined>(undefined);
