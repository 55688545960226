import { GeneralInfoFormValues } from '@/app/types/OrganizationFormValues';
import { FormCreateOrganization } from '@/components/Organizations/FormCreateOrganization';
import { FormGeneralInfo } from '@/components/Organizations/FormGeneralInfo';
import { SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { GeneralInfoFormValuesSchema } from '@/app/schemas/OrganizationFormValuesSchema';
import { useOrganizationFormDataProvider } from '@/components/Organizations/FormOrganizationDataProvider';
import { generalInfoDefaultValues } from '@/components/Organizations/FormOrganizationDataProvider/initialState';
import { useOrganizationGeneralFormOptions } from '@/app/hooks/useOrganizationGeneralFormOptions';
import { FormSection } from '@/components/Organizations/FormSection';
import { useState } from 'react';

export const GeneralInfo = () => {
	const { formValues, setFormValues } = useOrganizationFormDataProvider();
	const [prevValues] = useState(formValues.generalInfo);
	const generalFormOptions = useOrganizationGeneralFormOptions();

	const onSubmit: SubmitHandler<GeneralInfoFormValues> = (data) => {
		setFormValues({ generalInfo: data });
	};

	return (
		<FormCreateOrganization
			defaultValues={formValues.generalInfo || generalInfoDefaultValues}
			resolver={zodResolver(GeneralInfoFormValuesSchema)}
			onSubmit={onSubmit}
		>
			<FormSection
				mode={prevValues ? 'edit' : 'create'}
				urlParam="create"
				submitText="Next: User Info"
				nextUrl="/organizations/create/user-info"
			>
				<FormGeneralInfo {...generalFormOptions} />
			</FormSection>
		</FormCreateOrganization>
	);
};
