import { SettingsView } from '@/components/SettingsView';

import { Field, SELECT_ITEMS, TIME_ITEMS } from './Fields';
import { SettingsRowElement } from '@/components/SettingsRowElement';
import { SettingsBellIcon, SettingsClockIcon } from '@/components/Elements/Icons';
import { Controller, useFormContext } from 'react-hook-form';

export const FormTasks = () => {
	const { control } = useFormContext();
	return (
		<SettingsView title="Tasks.">
			<SettingsView.Section title="">
				<SettingsRowElement icon={<SettingsClockIcon />} title={'Default Task Duration'}>
					<Controller
						control={control}
						name={'tasks.defaultTaskDuration'}
						render={({ field: { value, onChange } }) => {
							return (
								<SettingsRowElement.SelectItem
									value={value}
									onChange={onChange}
									measurement="min"
									items={SELECT_ITEMS}
								/>
							);
						}}
					/>
				</SettingsRowElement>
				<SettingsRowElement icon={<SettingsBellIcon />} title={'Default Reminders On/Off'}>
					<Controller
						control={control}
						name={'tasks.defaultRemindersActive'}
						render={({ field: { value, onChange } }) => {
							return (
								<SettingsRowElement.CheckBoxItem
									value={value}
									onChange={onChange}
								/>
							);
						}}
					/>
				</SettingsRowElement>
				<Field
					name="tasks.reminderBeforeStart"
					title="Reminder Before Start"
					type="select"
				/>
				<Field name="tasks.reminderBeforeEnd" title="Reminder Before End" type="select" />
				<Field name="tasks.reminderOverdue" title="Overdue Reminder" type="select" />

				<SettingsRowElement
					icon={<SettingsBellIcon />}
					title={'Overdue Reminders Interval'}
				>
					<Controller
						control={control}
						name={'tasks.reminderOverdueInterval'}
						render={({ field: { value, onChange } }) => {
							return (
								<SettingsRowElement.SelectItem
									value={value}
									onChange={onChange}
									measurement="min"
									items={SELECT_ITEMS}
								/>
							);
						}}
					/>
				</SettingsRowElement>
				<SettingsRowElement
					icon={<SettingsBellIcon />}
					title={'Number of Overdue Reminders'}
				>
					<Controller
						control={control}
						name={'tasks.overdueRemindersNumber'}
						render={({ field: { value, onChange } }) => {
							return (
								<SettingsRowElement.SelectTimeItem
									value={value}
									onChange={onChange}
									items={TIME_ITEMS}
								/>
							);
						}}
					/>
				</SettingsRowElement>
			</SettingsView.Section>
		</SettingsView>
	);
};
