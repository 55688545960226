import { SortIcon } from '@/components/Elements/Icons';
import Styles from './index.module.scss';
import { useEffect, useRef } from 'react';

const MIN_WIDTH = 20;

interface HeaderProps {
	title: string;
	minSize?: number;
	isSortable?: boolean;
	onClick?: () => void;
	ascending?: boolean;
	tableRef?: React.RefObject<HTMLTableElement>;
}

export const Header = ({ title, isSortable, onClick, ascending = true, tableRef }: HeaderProps) => {
	const headerRef = useRef<HTMLTableCellElement>(null);
	const resizerRef = useRef<HTMLDivElement>(null);
	const mouseLocationRef = useRef<number | null>(null);
	const rectWidth = useRef<number | null>(null);
	const tableWidth = useRef<number | null>(null);
	const isResizingRef = useRef(false);

	useEffect(() => {
		const onMouseMove = (e: MouseEvent) => {
			if (
				isResizingRef.current &&
				mouseLocationRef.current &&
				rectWidth.current &&
				tableWidth.current
			) {
				const diff = e.clientX - mouseLocationRef.current;
				if (headerRef.current && tableRef && tableRef.current) {
					const newWidth = Math.max(rectWidth.current + diff, MIN_WIDTH);
					if (newWidth > MIN_WIDTH) {
						headerRef.current.style.width = `${newWidth}px`;
						tableRef.current.style.width = `${tableWidth.current + diff}px`;
					}
				}
			}
		};

		const onMouseUp = () => {
			isResizingRef.current = false;
			mouseLocationRef.current = null;
			rectWidth.current = null;
			tableWidth.current = null;
			headerRef.current?.classList.remove(Styles.resizing);
		};

		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseup', onMouseUp);

		return () => {
			document.removeEventListener('mousemove', onMouseMove);
			document.removeEventListener('mouseup', onMouseUp);
		};
	}, [tableRef]);

	return (
		<th ref={headerRef} className={isSortable ? Styles.sortable : ''}>
			<div onClick={onClick}>
				<div className={Styles.title}>
					<span>{title}</span>
					{isSortable && (
						<div className={`${Styles.sort} ${!ascending ? Styles.descending : ''}`}>
							<SortIcon />
						</div>
					)}
				</div>
				<div
					onMouseDown={(e) => {
						if (headerRef.current) {
							mouseLocationRef.current = e.pageX;
							rectWidth.current =
								headerRef.current?.getBoundingClientRect().width ?? 0;
							isResizingRef.current = true;
							headerRef.current.classList.add(Styles.resizing);
							tableWidth.current =
								tableRef?.current?.getBoundingClientRect().width ?? null;
							e.stopPropagation();
						}
					}}
					onClick={(e) => e.stopPropagation()}
					ref={resizerRef}
					className={Styles.resizer}
				/>
			</div>
		</th>
	);
};
