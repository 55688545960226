import Styles from './index.module.scss';

interface Props {
	children: React.ReactNode;
	variant?: 'bold' | 'light';
	align?: 'center' | 'left';
}

export const DataLabel = ({ children, variant, align }: Props) => {
	return (
		<span
			className={`${Styles.dataLabel} ${variant ? Styles[variant] : ''} ${align ? Styles[align] : ''}`}
		>
			{children}
		</span>
	);
};
