import { useState } from 'react';
import { Widget } from '../Shared/Widget';
import { SortSwitch } from '../Shared/SortSwitch';
import { Chart } from '../Shared/Chart';
import { Filter } from './Filter';
import { useFormattedData } from './useResponseRatioGraph';
import { ResponseRatioFilter, ResponseRatioItem } from '@/app/types/Dashboard';

interface Props {
	isLoading: boolean;
	data: ResponseRatioItem[];
}

export const ResponseRatio = ({ data = [], isLoading }: Props) => {
	const [ascending, setAscending] = useState<boolean>(true);
	const [filter, setFilter] = useState<ResponseRatioFilter>('all');

	const sortedData = [...data].sort((a, b) => {
		return ascending ? a.total - b.total : b.total - a.total;
	});

	const options = useFormattedData(sortedData, filter);

	return (
		<Widget
			title="Actions Response Ratio"
			toolbar={
				<>
					<Filter value={filter} onChange={setFilter} />
					<SortSwitch value={ascending} onChange={(e) => setAscending(e)} />
				</>
			}
		>
			<Chart isLoading={isLoading} options={options} height={382} />
		</Widget>
	);
};
