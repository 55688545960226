import { Outlet } from 'react-router-dom';
import {
	MsalAuthenticationResult,
	MsalAuthenticationTemplate,
	MsalProvider,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest, msalInstance } from '@/services/msal';
import { ToastContainer } from 'react-toastify';

const LoadingScreen = () => {
	return <></>;
};

interface Props {
	errorComponent?:
		| React.ElementType<MsalAuthenticationResult, keyof React.JSX.IntrinsicElements>
		| undefined;
}

export const AuthenticationLayout = ({ errorComponent }: Props) => {
	return (
		<MsalProvider instance={msalInstance}>
			<MsalAuthenticationTemplate
				interactionType={InteractionType.Redirect}
				authenticationRequest={{
					...loginRequest,
				}}
				errorComponent={errorComponent}
				loadingComponent={LoadingScreen}
			>
				<Outlet />
				<ToastContainer
					bodyStyle={{ fontFamily: 'Graphik' }}
					progressStyle={{ background: '#fe3266' }}
				/>
			</MsalAuthenticationTemplate>
		</MsalProvider>
	);
};
