import { useFormContext, useWatch } from 'react-hook-form';
import Styles from './index.module.scss';
import { OrganizationFormValues } from '@/app/types/OrganizationFormValues';
import { OrganizationErrorObject } from '@/app/types/Organization';
import { toErrorsDictionary } from '@/app/utils/organization/data';

interface Props {
	errors: OrganizationErrorObject[] | undefined;
}

export const DeviceSettings = ({ errors }: Props) => {
	const { control } = useFormContext<OrganizationFormValues>();
	const { watch, tablet } = useWatch({ control, name: 'settings' });
	const mapper = toErrorsDictionary(errors);
	return (
		<div className={`${Styles.container}`}>
			<h4 className={Styles.fullWidth}>Watch</h4>
			<p>{`Welcome Message (Login):`}</p>
			<span>{watch?.welcomeMessage ?? ''}</span>
			{mapper?.['settings.watches.loginmessage'] && (
				<span className={Styles.error}>{mapper?.['settings.watches.loginmessage']}</span>
			)}
			<p>{`Goodbye Message (Login):`}</p>
			<span>{watch?.goodbyeMessage}</span>
			{mapper?.['settings.watches.logoutmessage'] && (
				<span className={Styles.error}>{mapper?.['settings.watches.logoutmessage']}</span>
			)}
			<p>{`Call for Help Menu:`}</p>
			<span>{'...'}</span>
			<p>{`Time for force return to watch face (milliseconds):`}</p>
			<span>{watch.watchFaceForceReturn}</span>

			<h4 className={Styles.fullWidth}>Tablet</h4>
			<p>Tablet Current Status</p>
			<span>{tablet.status}</span>
			<p>No Response Message Timer (Seconds)</p>
			<span>{tablet.noResponseTimer}</span>
			{/* <p>Conference Tablet</p>
			<span>{`settings-file-name2`}</span> */}
		</div>
	);
};
