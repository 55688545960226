import Styles from './styles.module.scss';
import { useCallback, useState } from 'react';
import { Header } from './Header';

export const EditorHeader = ({
	children,
	name,
	isArray,
	isCollapsed,
	items,
}: {
	children: React.ReactNode;
	name: string;
	isCollapsed: boolean;
	isArray: boolean;
	items: number;
}) => {
	const [collapsed, setCollapsed] = useState(isCollapsed);

	const handleClick = useCallback(() => {
		setCollapsed((s) => !s);
	}, []);

	return (
		<ul>
			<Header isArray={isArray} name={name} items={items} onClick={handleClick} />
			{items > 0 && (
				<>
					<div onClick={handleClick} className={Styles.arrow}>
						<div
							className={collapsed ? Styles.arrowIconRight : Styles.arrowIconBottom}
						/>
					</div>
				</>
			)}
			{!collapsed && children}
		</ul>
	);
};
