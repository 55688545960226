import { DateTime } from 'luxon';
import { useSystemStatusQuery } from '../api/Organizations';
import { SystemStatusRequest } from '../types/Request';
import { useMemo } from 'react';

export const useOrganizationsStatusQuery = (filter: SystemStatusRequest) => {
	const { data: organizationData, isFetching, error } = useSystemStatusQuery({ ...filter });

	const data = useMemo(() => {
		return (
			organizationData?.items?.map((organization) => {
				return {
					id: organization.organizationId,
					name: organization.name,
					country: organization?.country ?? '',
					city: organization?.city ?? '',

					devices: organization.devices?.total ?? 0,
					users: organization.users?.total ?? 0,
					created: organization.createdTime
						? DateTime.fromISO(organization.createdTime).toFormat('EEE, dd LLL - HH:mm')
						: '',
					modified: organization.updatedTime
						? DateTime.fromISO(organization.updatedTime).toFormat('EEE, dd LLL - HH:mm')
						: '',
				};
			}) ?? []
		);
	}, [organizationData?.items]);

	return {
		data,
		isFetching,
		error,
	};
};
