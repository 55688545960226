import { skipToken } from '@reduxjs/toolkit/query';
import { useGetActionSummaryQuery, useGetTriggeredQuery } from '../api/Dashboard';
import { Filter } from '../types/Filter';
import { useOrganizations } from './useOrganizations';
import { Organization } from '../types/Organization';
import { TriggeredItem } from '../types/Dashboard';
import {
	AcceptanceTimeItem,
	ResponseRatioItem,
	TotalActionsPerOrganizationItem,
} from '@/app/types/Dashboard';

import { useMemo } from 'react';

export const useActionDashboardQuery = (filter: Filter) => {
	const organizationData = useOrganizations();

	const skipOptions = { skip: organizationData.isFetching ?? skipToken };
	const actionSummeryData = useGetActionSummaryQuery(filter, skipOptions);
	const triggeredData = useGetTriggeredQuery(filter, skipOptions);

	const { organizations, isFetching: isOrganizationFetching } = organizationData;
	const { data: triggered } = triggeredData;
	const { data: actionSummary } = actionSummeryData;

	return useMemo(() => {
		try {
			const orgNameById: Record<string, string> = organizations.reduce(
				(acc: Record<string, string>, org: Organization) => {
					acc[org.id] = org.name;
					return acc;
				},
				{}
			);

			const triggersByOrgId: Record<string, TriggeredItem[]> = (triggered ?? []).reduce(
				(acc: Record<string, TriggeredItem[]>, trigger: TriggeredItem) => {
					const orgId = trigger.organizationId ?? 'unknown';
					if (!acc[orgId]) {
						acc[orgId] = [];
					}
					acc[orgId].push(trigger);
					return acc;
				},
				{}
			);

			const totalActionsSeries: TotalActionsPerOrganizationItem[] = (actionSummary ?? []).map(
				(item) => {
					const organizationId = item.organizationId ?? 'unknown';
					const organizationName = orgNameById[organizationId] || 'Unknown';
					const triggerData = triggersByOrgId[organizationId] || [];

					return {
						organizationName,
						organizationTotal: item.total ?? 0,
						triggeredData: triggerData.map((trigger) => {
							return {
								trigger: trigger.trigger,
								triggerGroup: trigger.triggerGroup,
								percentage: ((trigger.total / (item.total ?? 1)) * 100).toFixed(0),
							};
						}),
					};
				}
			);

			const acceptedTimeSeries: AcceptanceTimeItem[] = (actionSummary ?? []).map((item) => {
				const organizationId = item.organizationId ?? 'Unknown';
				const organizationName = orgNameById[organizationId] || 'Unknown';
				const averageTime =
					item.acceptedTimeAverageInMs != null
						? Math.round(item.acceptedTimeAverageInMs / 1000)
						: 0;
				return {
					organizationName,
					averageTime,
				};
			});

			const responseRationSeries: ResponseRatioItem[] = (actionSummary ?? []).map((item) => {
				const organizationId = item.organizationId ?? 'unknown';
				const organizationName = orgNameById[organizationId] || 'Unknown';
				return {
					organizationName,
					accepted: item.accepted,
					rejected: item.rejected,
					unanswered: item.unanswered,
					total: item.total,
				};
			});

			const isFetching =
				isOrganizationFetching || actionSummeryData.isFetching || triggeredData.isFetching;
			const error = actionSummeryData.error || triggeredData.error;

			return {
				data: {
					totalActionsSeries,
					acceptedTimeSeries,
					responseRationSeries,
				},
				isFetching,
				error,
			};
		} catch (e) {
			return {
				data: {
					totalActionsSeries: [],
					acceptedTimeSeries: [],
					responseRationSeries: [],
				},
				isFetching: false,
				error: e,
			};
		}
	}, [
		actionSummary,
		actionSummeryData.error,
		actionSummeryData.isFetching,
		isOrganizationFetching,
		organizations,
		triggered,
		triggeredData.error,
		triggeredData.isFetching,
	]);
};
