import { PlusIcon } from '@/components/Elements/Icons';
import { SettingsView } from '@/components/SettingsView';
import { Button } from '@/components/Elements/Button';
import { Table } from './Table';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SettingsFormValues } from '@/app/types/OrganizationFormValues';

export const TemplatesField = () => {
	const { control } = useFormContext<SettingsFormValues>();
	const { fields, insert, remove } = useFieldArray({ control, name: 'tablet.templates' });

	return (
		<SettingsView.Section
			title="Custom Design Templates"
			actionElement={
				<Button
					variant="secondary"
					type="button"
					onClick={() => {
						insert(0, { name: '' });
					}}
				>
					<PlusIcon />
					Add Template
				</Button>
			}
		>
			<Table items={fields} onDelete={remove} />
		</SettingsView.Section>
	);
};
