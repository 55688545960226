import React, { useContext } from 'react';
import Styles from './index.module.scss';
import { ExpandableItem } from './types';
import { Context } from './Context';
import { WatchIcon } from '@/components/Elements/Icons';

interface Props {
	status: ExpandableItem;
}

export const Item = ({ status: { item, isExpanded } }: Props) => {
	const { headers } = useContext(Context);
	const colSpan = headers.map((h) => h.element).flat().length;

	if (isExpanded) {
		return (
			<>
				<td className={Styles.expanded}></td>
				<td colSpan={colSpan - 1} className={Styles.expanded}>
					<div className={Styles.devices}>
						<WatchIcon />
						<span>Devices</span>
						<div className={Styles.list}>
							{item.devices.devices.map(({ id, name, isConnected }) => {
								if (!name) {
									return <React.Fragment key={id} />;
								}
								return (
									<p key={id}>
										<span className={!isConnected ? Styles.offline : ''} />
										{name}
									</p>
								);
							})}
						</div>
					</div>
				</td>
			</>
		);
	}

	return (
		<>
			{headers.map((header) => {
				if (Array.isArray(header.element)) {
					return header.element.map((elem, index) => {
						return (
							<td key={`${header.id}${index}`}>
								<div className={Styles.cell}>
									{elem ? elem(item) : `${header.key ?? ''}`}
								</div>
							</td>
						);
					});
				}

				return (
					<td key={header.id} style={{ width: header.width, minWidth: header.minWidth }}>
						<div className={Styles.cell}>
							{header.element ? header.element(item) : `${header.key ?? ''}`}
						</div>
					</td>
				);
			})}
		</>
	);
};
