import { z } from 'zod';

export const GeneralInfoFormValuesSchema = z.object({
	organisationName: z.string().min(1),
	availableHostName: z.string().optional(),
	parentOrganization: z.string().min(1),
	organizationLogo: z.string().optional(),
	organizationType: z.union([
		z.literal('customer'),
		z.literal('demo'),
		z.literal('active'),
		z.literal('partner'),
	]),
	country: z.string().min(1),
	city: z.string().min(1),
	address: z.string().min(1),
	timeZone: z.string().optional(),
	ownerFirstName: z.string().min(1),
	ownerLastName: z.string().min(1),
	ownerEmail: z.string().email().min(1),
	ownerPassword: z.string().min(1),
});

export const UserInfoFormValuesSchema = z.object({
	users: z
		.array(
			z.object({
				firstName: z.string().min(1),
				lastName: z.string().min(1),
				email: z.string().email(),
				password: z.string().min(1),
				pin: z.string().min(4).max(4),
			})
		)
		.optional(),
});

export const SettingsFormValuesSchema = z.object({
	shiftPlanner: z.object({
		activities: z
			.array(z.object({ name: z.string().min(1), color: z.string().min(1) }))
			.optional(),
		workingHours: z.object({
			monday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
			tuesday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
			wednesday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
			thursday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
			friday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
			saturday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
			sunday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
		}),
		zoomLevel: z.number().nonnegative(),
		timeSelectionInterval: z.number().nonnegative(),
	}),
	tasks: z.object({
		defaultTaskDuration: z.number().nonnegative(),
		defaultRemindersActive: z.boolean(),
		reminderBeforeStart: z.object({
			active: z.boolean(),
			value: z.number().nonnegative(),
		}),
		reminderBeforeEnd: z.object({
			active: z.boolean(),
			value: z.number().nonnegative(),
		}),
		reminderOverdue: z.object({
			active: z.boolean(),
			value: z.number().nonnegative(),
		}),
		reminderOverdueInterval: z.number().nonnegative(),
		overdueRemindersNumber: z.number().nonnegative(),
	}),
	actions: z.object({
		autoForward: z.array(z.number().nonnegative()),
		sendFeedback: z.boolean(),
		acceptanceFeedback: z.object({
			active: z.boolean(),
			value: z.string().optional(),
		}),
		rejectionFeedback: z.object({
			active: z.boolean(),
			value: z.string().optional(),
		}),
		acceptedByMessage: z.boolean(),
		acceptedByMessageTitle: z.string().optional(),
		acceptedByMessageBody: z.string().optional(),
		completionConfirmation: z.boolean(),
		useSplashScreen: z.boolean(),
		incomingMessageDuration: z.number().nonnegative(),
	}),
	automation: z.object({
		blockMultiplePresses: z.boolean(),
		minTimeBetweenPushes: z.number().nonnegative(),
	}),
	tablet: z.object({
		status: z.string(),
		workingHours: z.object({
			monday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
			tuesday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
			wednesday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
			thursday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
			friday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
			saturday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
			sunday: z.object({
				start: z.object({ hours: z.number(), minutes: z.number() }),
				end: z.object({ hours: z.number(), minutes: z.number() }),
			}),
		}),
		noResponseTimer: z.number().nonnegative(),
		templates: z
			.array(z.object({ name: z.string().optional(), file: z.instanceof(File).optional() }))
			.optional(),
	}),
	watch: z.object({
		welcomeMessage: z.string().optional(),
		goodbyeMessage: z.string().optional(),
		callForHelp: z.string().optional(),
		watchFaceForceReturn: z.number().nonnegative(),
		logo: z.string().optional(),
	}),
});
