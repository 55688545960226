import Styles from './index.module.scss';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
interface Props {
	options?: Record<string, unknown> | null;
	height: number;
	isLoading: boolean;
}

export const Chart = ({ options, height, isLoading }: Props) => {
	const content = useMemo(() => {
		if (isLoading) {
			return (
				<div className={Styles.placeholder}>
					<CircularProgress />
				</div>
			);
		}

		if (!options) {
			return <div className={Styles.placeholder}>No data</div>;
		}

		return (
			<HighchartsReact
				options={options}
				highcharts={Highcharts}
				containerProps={{
					style: {
						height,
						margin: 0,
						padding: 0,
					},
				}}
			/>
		);
	}, [isLoading, options, height]);

	return (
		<div className={Styles.container} style={{ minHeight: height, height }}>
			{content}
		</div>
	);
};
