import { useMemo } from 'react';
import { ColorString } from 'highcharts';
import { AcceptanceTimeItem } from '@/app/types/Dashboard';

export const config: Highcharts.Options = {
	chart: {
		type: 'bar',
		style: {
			backgroundColor: 'transparent',
		},

		marginRight: 0,
	},
	accessibility: {
		enabled: false,
	},
	plotOptions: {
		bar: {
			grouping: false,
		},
	},
	credits: {
		enabled: false,
	},
	title: {
		text: '',
	},
	tooltip: {
		enabled: false,
	},
	legend: {
		enabled: false,
		itemStyle: {
			fontSize: '16px',
			fontFamily: 'Graphik',
			fontWeight: '400',
		},
		align: 'left',
		verticalAlign: 'middle',
		// layout: 'proximate',
		layout: 'vertical',
		symbolHeight: 8,
		symbolWidth: 8,
		x: -16,

		// symbolPadding: 3,
	},
	xAxis: {
		labels: {
			enabled: true,
		},
	},
	yAxis: {
		labels: {
			enabled: true,
			style: {
				fontSize: '14px',
				color: '#B6B6B6',
				fontFamily: 'Graphik',
				fontWeight: '600',
			},
		},
		title: {
			text: 'Seconds',
			style: {
				fontSize: '14px',
				color: '#B6B6B6',
				fontFamily: 'Graphik',
				textOverflow: 'none',
				fontWeight: '600',
				width: 150,
			},
		},
		min: 0,
		tickAmount: 7,
		alignTicks: false,
		allowDecimals: false,
		startOnTick: false,
		gridLineDashStyle: 'Dash',
		gridLineColor: '#9194A1',
	},
	series: [],
};

export const useFormattedData = (data: AcceptanceTimeItem[] = []) => {
	return useMemo(() => {
		try {
			if (!data || data.length === 0) {
				return null;
			}

			return {
				...config,

				xAxis: {
					type: 'category',
					labels: {
						showLastLabel: true,
						y: 6,
						enabled: true,
						formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
							return `<div>
						    	<div style="color: ${this.axis.series[this.pos].color as ColorString}; font-size:16px; font-family: Graphik-Semibold">&#9679;</div>
						    	<span style="font-family: Graphik; font-size:16px; font-weight:400;x">${this.value}</span>
						    </div>`;
						},
						align: 'left',
						reserveSpace: true,
					},
					lineWidth: 0,
				},
				series: data.map((item) => {
					return {
						name: item.organizationName,
						data: [{ y: item.averageTime, name: item.organizationName }],
						type: 'bar',
						borderRadius: 6,
						pointWidth: 9,
					};
				}),
			};
		} catch (error) {
			return null;
		}
	}, [data]);
};
