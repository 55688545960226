import Styles from './index.module.scss';
import { SearchIcon } from '../Icons';

export const SearchInput = (props: React.ComponentPropsWithRef<'input'>) => {
	return (
		<div className={Styles.container}>
			<SearchIcon />
			<input {...props} />
		</div>
	);
};
