import React from 'react';
import { useCallback } from 'react';
import Styles from './styles.module.scss';
import { EditorHeader } from './EditorHeader';
import { EditorItem } from './EditorItem';
import { NullEditorItem } from './NullEditorItem';
import { EmptyRootItem } from './EmptyRootItem';

export const JsonViewer = ({ item }: { item: object }) => {
	const parseItem = useCallback((obj: object) => {
		const keys = Object.keys(obj);
		return keys.map((key, index) => {
			if (obj[key] === null || obj[key] === undefined) {
				return <NullEditorItem key={index} name={key} value={obj[key]} />;
			}

			if (obj[key] instanceof Date) {
				return <EditorItem key={index} name={key} value={obj[key].toISOString()} />;
			}

			const isArray = Array.isArray(obj[key]);
			if (typeof obj[key] === 'object') {
				const itemsCount = Object.keys(obj[key] as Record<string, unknown>)?.length || 0;

				return (
					<EditorHeader
						key={index}
						isCollapsed={true}
						name={key}
						isArray={isArray}
						items={itemsCount}
					>
						{parseItem(obj[key] as Record<string, unknown>)}
					</EditorHeader>
				);
			} else {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				return <EditorItem key={index} name={key} value={obj[key] as any} />;
			}
		});
	}, []);

	const parseObject = React.useMemo(() => {
		if (item === undefined || item === null) {
			return <NullEditorItem name="root" value={item} />;
		}

		const isArray = Array.isArray(item);
		const items = Object.keys(item)?.length || 0;

		if (items === 0) {
			return <EmptyRootItem isArray={isArray} />;
		}

		return (
			<EditorHeader isCollapsed={false} name="root" isArray={isArray} items={items}>
				{parseItem(item)}
			</EditorHeader>
		);
	}, [item, parseItem]);

	return (
		<div className={Styles.container}>
			<div className={Styles.editor}>{parseObject}</div>
		</div>
	);
};
