import Styles from './index.module.scss';
import { NavLink } from 'react-router-dom';
import { ActionsIcon, DashboardIcon, NotificationIcon, TasksIcon } from 'components/Elements/Icons';

export const Navigation = () => {
	const className = ({ isActive }: { isActive: boolean }) => {
		return isActive ? Styles.active : '';
	};

	return (
		<div className={Styles.menu}>
			<NavLink to={'/dashboard'} className={className}>
				<DashboardIcon />
				<span>Dashboard</span>
			</NavLink>
			<NavLink to={'/notifications'} className={className}>
				<NotificationIcon />
				<span>Notifications</span>
			</NavLink>
			<NavLink to={'/tasks'} className={className}>
				<TasksIcon />
				<span>Tasks</span>
			</NavLink>
			<NavLink to={'/actions'} className={className}>
				<ActionsIcon viewBox="0 0 24 24" />
				<span>Actions</span>
			</NavLink>
		</div>
	);
};
