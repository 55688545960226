import Styles from './index.module.scss';
import { Expandable } from '../Expandable';
import { GeneralInfo } from './GeneralInfo';
import { UserInfo } from './UserInfo';
import { ShiftPlanner } from './ShiftPlanner';
import { MessageSettings } from './MessageSettings';
import { Automation } from './Automation';
import { DeviceSettings } from './DeviceSettings';
import { LogoPreview } from './LogoPreview';
import {
	CloseIcon,
	SettingsCalendarIcon,
	SettingsLayersIcon,
	SettingsSendIcon,
	SettingsToolsIcon,
} from '@/components/Elements/Icons';
import { useWatch } from 'react-hook-form';
import { OrganizationFormValues } from '@/app/types/OrganizationFormValues';
import { OrganizationErrorObject } from '@/app/types/Organization';
import { Errors } from './Errors';

interface Props {
	errors: OrganizationErrorObject[] | undefined;
}

export const FormSummery = ({ errors }: Props) => {
	const formValues = useWatch<Partial<OrganizationFormValues>>();

	return (
		<div className={Styles.container}>
			<div className={Styles.details}>
				{errors && errors.length > 0 && (
					<Expandable
						title={`${errors?.length ?? 0} Error(s)`}
						isExpandable={true}
						to="/organizations/create/general-info"
						icon={<span>!</span>}
						isEditable={false}
						defaultExpanded={false}
						variant="error"
					>
						<Errors errors={errors} />
					</Expandable>
				)}

				<Expandable
					title="General Info"
					isExpandable={false}
					to="/organizations/create/general-info"
					icon={<CloseIcon />}
				>
					<GeneralInfo errors={errors} />
				</Expandable>
				<Expandable
					title="User Info"
					isExpandable={false}
					to="/organizations/create/user-info"
					icon={<CloseIcon />}
				>
					<UserInfo errors={errors} />
				</Expandable>
				<Expandable
					title="Shift Planner"
					isExpandable
					defaultExpanded={true}
					to="/organizations/create/configure-settings/shift-planner"
					icon={<SettingsCalendarIcon />}
				>
					<ShiftPlanner errors={errors} />
				</Expandable>
				<Expandable
					title="Message settings"
					isExpandable
					defaultExpanded={true}
					to="/organizations/create/configure-settings/messaging"
					icon={<SettingsSendIcon />}
				>
					<MessageSettings errors={errors} />
				</Expandable>
				<Expandable
					title="Automation"
					isExpandable
					defaultExpanded={true}
					to="/organizations/create/configure-settings/automation/multiple-presses"
					icon={<SettingsLayersIcon />}
				>
					<Automation errors={errors} />
				</Expandable>
				<Expandable
					title="Device Settings"
					isExpandable
					defaultExpanded={true}
					to="/organizations/create/configure-settings/device-settings"
					icon={<SettingsToolsIcon />}
				>
					<DeviceSettings errors={errors} />
				</Expandable>
			</div>
			<LogoPreview logo={formValues?.settings?.watch?.logo} />
		</div>
	);
};
