import moment from 'moment';
import { FilterTimeType } from 'types/Filter';

export const convertToTimeFromPeriod = (
	timeType: FilterTimeType
): { startTime: string; endTime: string } => {
	const date = moment(new Date());

	switch (timeType) {
		case 'daily':
			return { startTime: date.startOf('day').format(), endTime: date.endOf('day').format() };
		case 'weekly':
			return {
				startTime: date.startOf('isoWeek').format(),
				endTime: date.endOf('isoWeek').format(),
			};
		case 'monthly':
			return {
				startTime: date.startOf('month').format(),
				endTime: date.endOf('month').format(),
			};
		case 'custom':
			return { startTime: date.startOf('day').format(), endTime: date.endOf('day').format() };
		default:
			return { startTime: date.startOf('day').format(), endTime: date.endOf('day').format() };
	}
};
