import { isEndOfDay } from './date';

import moment from 'moment';

import { Moment } from 'moment';

export const formatStart = (time: Moment) => {
	if (!time.isValid()) {
		return '';
	}

	if (time.isSame(moment(new Date()).startOf('day'))) {
		return 'Today';
	}

	if (time.isSame(new Date(), 'day')) {
		return `Today, ${time.format('HH:mm')}`;
	}

	if (time.isSame(moment(new Date()).add(-1, 'day').startOf('day'))) {
		return `Yesterday`;
	}

	if (time.isSame(moment(new Date()).add(-1, 'day'), 'day')) {
		return `Yesterday, ${time.format('HH:mm')}`;
	}

	if (time.isSame(moment(time).startOf('day'))) {
		return `${time.format('ddd, DD MMM')}`;
	}

	return `${time.format('ddd, DD MMM')}, ${time.format('HH:mm')}`;
};

export const formatEnd = (time: Moment) => {
	if (!time.isValid()) {
		return '';
	}

	if (time.isSame(new Date(), 'day')) {
		return `Today, ${time.format('HH:mm')}`;
	}

	if (time.isSame(moment(new Date()).add(-1, 'day').endOf('day'))) {
		return `Yesterday`;
	}

	if (time.isSame(moment(new Date()).add(-1, 'day'), 'day')) {
		return `Yesterday, ${time.format('HH:mm')}`;
	}

	if (!isEndOfDay(time)) {
		return `${time.format('ddd, DD MMM')}, ${time.format('HH:mm')}`;
	}

	return `${time.format('ddd, DD MMM')}`;
};

export default {
	formatStart,
	formatEnd,
};
