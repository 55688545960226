import React from 'react';
import { Button } from '@/components/Elements/Button';
import Styles from './index.module.scss';
import { Input } from '@/components/Elements/Input';
import { ColorPicker, DEFAULT_VALUE } from '@/components/Elements/ColorPicker';
import { CloseIcon } from '@/components/Elements/Icons';

interface Props {
	onChange?: (index, data: { name: string; color: string }) => void;
}

export const ActivitiesForm = ({ onChange }: Props) => {
	const [name, setName] = React.useState('');
	const [color, setColor] = React.useState(DEFAULT_VALUE);

	return (
		<div className={Styles.container}>
			<span>Name: </span>
			<span>Color: </span>
			<Input
				value={name}
				onChange={(e) => {
					setName(e.target.value);
				}}
				placeholder={'Enter Activity Name'}
			/>
			<div className={Styles.pickerContainer}>
				<ColorPicker value={color} onChange={setColor} />
			</div>
			<div className={Styles.button}>
				<Button
					disabled={!name}
					type="button"
					onClick={() => {
						onChange?.(0, { name, color });
						setColor(DEFAULT_VALUE);
						setName('');
					}}
				>
					<CloseIcon />
					<span>Create activity</span>
				</Button>
			</div>
		</div>
	);
};
