import { ActionSummary, TriggeredItem } from '@/app/types/Dashboard';
import { apiSlice } from '../api';
import { ActionSummaryRequest } from '@/app/types/Request';

export const dashboardApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getActionSummary: builder.query<ActionSummary[], ActionSummaryRequest>({
			query: (body) => ({
				url: `/data/actions/summary`,
				method: 'POST',
				body,
			}),
		}),

		getTriggered: builder.query<TriggeredItem[], ActionSummaryRequest>({
			query: (body) => ({
				url: '/data/actions/triggered',
				method: 'POST',
				body,
			}),
		}),
	}),
	overrideExisting: false,
});

export const { useGetActionSummaryQuery, useGetTriggeredQuery } = dashboardApiSlice;
