import React from 'react';
import Styles from './Row.module.scss';

interface Props extends React.ComponentPropsWithRef<'div'> {
	isSelected?: boolean;
}

const Row = ({ isSelected, ...rest }: Props) => {
	return <div {...rest} className={`${Styles.container} ${isSelected ? Styles.selected : ''}`} />;
};

export default Row;
