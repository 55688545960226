import Styles from './index.module.scss';
import { useState } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';
import { useMsal } from '@azure/msal-react';
import { msalInstance } from '@/services/msal';
import { formatInitials } from '@/utils/strings';

export const ProfileDropdown = () => {
	const [isVisible, setIsVisible] = useState(false);
	const { accounts } = useMsal();
	const { ref } = useClickOutside<HTMLDivElement>(() => {
		setIsVisible(false);
	});

	const handleSignOut = () => {
		setTimeout(async () => {
			const logoutRequest = {
				account: msalInstance.getAllAccounts[0],
			};

			await msalInstance.logoutRedirect(logoutRequest);
		}, 0);
	};

	return (
		<div className={Styles.container}>
			<div className={Styles.title}>
				<h3>{accounts[0]?.name ?? ''}</h3>
				<h4>{accounts[0]?.username ?? ''}</h4>
			</div>
			<div
				className={Styles.avatar}
				onClick={() => {
					setIsVisible((state) => !state);
				}}
			>
				<span>{formatInitials(accounts[0]?.name ?? '')}</span>
			</div>
			{isVisible && (
				<div ref={ref} className={Styles.menu}>
					<div className={Styles.arrow}></div>
					<button type="button">Edit profile</button>
					<button onClick={handleSignOut} type="button">
						Sign out
					</button>
				</div>
			)}
		</div>
	);
};
