import React, { useMemo } from 'react';
import Styles from './DayCell.module.scss';

interface Props extends React.ComponentPropsWithRef<'div'> {
	isSelected?: boolean;
	isNotValid?: boolean;
	isFirstSelectedCell?: boolean;
	isLastSelectedCell?: boolean;
	selectedClassName?: string;
}

const DayCell = ({
	isSelected,
	isNotValid = false,
	isFirstSelectedCell = false,
	isLastSelectedCell = false,
	selectedClassName,
	...rest
}: Props) => {
	const selectedStyle = useMemo(() => {
		if (selectedClassName) {
			return selectedClassName;
		}
		return Styles.selected;
	}, [selectedClassName]);
	return (
		<div
			{...rest}
			className={`${Styles.container} 
			${isNotValid ? Styles.isNotValid : ''} 
			${isSelected ? selectedStyle : ''} 
			${isFirstSelectedCell ? Styles.isFirstSelectedCell : ''} 
			${isLastSelectedCell ? Styles.isLastSelectedCell : ''}`}
		/>
	);
};

export default DayCell;
