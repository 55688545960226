import { SettingsLayoutIcon } from '@/components/Elements/Icons';
import Styles from './index.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { InputSelect } from '@/components/Elements/InputSelect';
import { GeneralInfoFormValues } from '@/app/types/OrganizationFormValues';
import { capitalized } from '@/app/utils/strings';

const CUSTOMER_TYPE_OPTIONS: GeneralInfoFormValues['organizationType'][] = [
	'active',
	'demo',
	'customer',
	'partner',
];

export const OrganizationTypeField = () => {
	const { control } = useFormContext();

	return (
		<>
			<SettingsLayoutIcon className={Styles.fieldIcon} />
			<span className={Styles.fieldTitle}>Organization Type</span>
			<Controller
				control={control}
				name="organizationType"
				render={({ field }) => {
					return (
						<InputSelect
							value={field.value}
							onChange={field.onChange}
							items={CUSTOMER_TYPE_OPTIONS.map((type) => ({
								text: capitalized(type),
								value: type,
							}))}
						/>
					);
				}}
			/>
		</>
	);
};
