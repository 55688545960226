import Styles from './index.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { SettingsUserIcon } from '@/components/Elements/Icons';
import { Input } from '@/components/Elements/Input';
import { withPasswordReveal } from '@/components/Elements/PasswordRevealInput';

const PasswordRevealInput = withPasswordReveal(Input);

export const OrganizationDetailsField = () => {
	const { control } = useFormContext();

	return (
		<div className={Styles.fieldSet}>
			<SettingsUserIcon className={Styles.fieldIcon} />
			<span className={Styles.fieldTitle}>
				Organisation Owner Details<span>*</span>
			</span>
			<div className={Styles.fieldGridPassword}>
				<span className={Styles.fieldSmallTitle}>Name</span>
				<div className={Styles.inputContainer}>
					<Controller
						control={control}
						name="ownerFirstName"
						render={({ field }) => {
							return (
								<Input
									value={field.value}
									onChange={field.onChange}
									placeholder="Firstname"
								/>
							);
						}}
					/>
					<div className={Styles.separator} />
					<Controller
						control={control}
						name="ownerLastName"
						render={({ field }) => {
							return (
								<Input
									value={field.value}
									onChange={field.onChange}
									placeholder="Lastname"
								/>
							);
						}}
					/>
				</div>
				<span className={Styles.fieldSmallTitle}>Email</span>
				<Controller
					control={control}
					name="ownerEmail"
					render={({ field }) => {
						return (
							<Input
								type="email"
								value={field.value}
								onChange={field.onChange}
								placeholder="owner@organizationname.com"
							/>
						);
					}}
				/>
				<span className={Styles.fieldSmallTitle}>Password</span>
				<Controller
					control={control}
					name="ownerPassword"
					render={({ field }) => {
						return (
							<PasswordRevealInput
								type="password"
								value={field.value}
								onChange={field.onChange}
								placeholder="Set Password"
							/>
						);
					}}
				/>
			</div>
			<span className={Styles.description}>
				Password must be minimum eight characters, at least one uppercase letter, one
				lowercase letter and one number
			</span>
		</div>
	);
};
