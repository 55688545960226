import { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormCreateOrganization } from '@/components/Organizations/FormCreateOrganization';
import { OrganizationFormValues } from '@/app/types/OrganizationFormValues';
import { useOrganizationFormDataProvider } from '@/components/Organizations/FormOrganizationDataProvider';
import { FormSection } from '@/components/Organizations/FormSection';
import { useNavigate } from 'react-router-dom';
import { FormSummery } from '@/components/Organizations/FormSummery';

export const Summary = () => {
	const { formValues, create, isSuccess, errors } = useOrganizationFormDataProvider();

	const navigate = useNavigate();
	const onSubmit: SubmitHandler<OrganizationFormValues> = () => {
		return create(formValues);
	};

	useEffect(() => {
		if (isSuccess) {
			navigate('/organizations', { replace: true });
		}
	}, [isSuccess, navigate]);

	return (
		<>
			<FormCreateOrganization defaultValues={formValues} onSubmit={onSubmit}>
				<FormSection
					mode="create-confirmation"
					urlParam="create"
					submitText="Create Organization"
					backUrl="/organizations/create/configure-settings"
				>
					<FormSummery errors={errors} />
				</FormSection>
			</FormCreateOrganization>
		</>
	);
};
