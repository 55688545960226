import Styles from './index.module.scss';

interface Props {
	on: number;
	off: number;
}

export const ConnectionStatus = ({ on, off }: Props) => {
	return (
		<div className={Styles.status}>
			<div />
			<span>{on}</span>

			<div />
			<span>{off}</span>
		</div>
	);
};
