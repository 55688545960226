import { useFormContext, useWatch } from 'react-hook-form';
import Styles from './index.module.scss';
import { OrganizationFormValues } from '@/app/types/OrganizationFormValues';
import { OrganizationErrorObject } from '@/app/types/Organization';
import { toErrorsDictionary } from '@/app/utils/organization/data';

interface Props {
	errors: OrganizationErrorObject[] | undefined;
}

export const Automation = ({ errors }: Props) => {
	const { control } = useFormContext<OrganizationFormValues>();
	const { automation } = useWatch({ control, name: 'settings' });
	const mapper = toErrorsDictionary(errors);

	return (
		<div className={`${Styles.container}`}>
			<p>Block Multiple Presses:</p>
			<span>{automation.blockMultiplePresses ? 'ON' : 'OFF'}</span>
			{mapper?.['settings.triggers.shouldthrottle'] && (
				<span className={Styles.error}>
					{mapper?.['settings.triggers.shouldthrottle'] ?? ''}
				</span>
			)}
			<p>Minimum Time Between Pushes (Seconds):</p>
			<span>{automation.minTimeBetweenPushes}</span>
			{mapper?.['settings.triggers.throttleperiodinseconds'] && (
				<span className={Styles.error}>
					{mapper?.['settings.triggers.throttleperiodinseconds'] ?? ''}
				</span>
			)}
		</div>
	);
};
