import Styles from './index.module.scss';

interface Props extends React.HtmlHTMLAttributes<HTMLButtonElement> {
	isSelected?: boolean;
}

export const TabButton = ({ isSelected, ...props }: Props) => {
	return (
		<button
			{...props}
			type="button"
			className={`${Styles.container} ${isSelected ? Styles.selected : ''}`}
		/>
	);
};
