import { endOfDay, format, isSameMonth, startOfDay } from 'date-fns';
import { useCallback } from 'react';
import Container from './Container';
import DayCell from './DayCell';
import Header from './Header';
import HeaderCell from './HeaderCell';
import { useCalendarDates } from './hooks';
import Navigation from './Navigation';
import NavigationButton from './NavigationButton';
import Row from './Row';
import Title from './Title';

interface Props {
	value?: { start: Date; end: Date };
	onChange?: (value: { start: Date; end: Date }) => void;
	// selectionMode?: 'daily' | 'weekly' | 'custom';
	focusDate?: Date;
	containerClassName?: string;
	selectedCellClassName?: string;
	selectionMode?: 'row' | 'custom';
}

export const Calendar = ({
	focusDate,
	value = { start: startOfDay(new Date()), end: endOfDay(new Date()) },
	onChange,
	containerClassName,
	selectedCellClassName,
	selectionMode = 'custom',
	// selectionMode = 'daily',
}: Props) => {
	const [
		weeks,
		{ prev, next, date, isDateSelected, isWeekSelected, isLastSelectedDate, isFirstSelected },
	] = useCalendarDates(value, focusDate);

	const handleClicked = useCallback(
		(week: { week: number; dates: Date[] }, date: Date) => {
			// return () => {
			// // 	if (!onChange) {
			// // 		return;
			// // 	}

			// // 	switch (selectionMode) {
			// // 		case 'custom':
			// // 		case 'daily': {

			// // 			break;
			// // 		}
			// // 		case 'weekly': {
			// // 			onChange({
			// // 				start: startOfDay(week.dates[0]),
			// // 				end: endOfDay(week.dates[6]),
			// // 			});
			// // 			break;
			// // 		}
			// // 		default: {
			// // 			break;
			// // 		}
			// // 	}
			// // };

			return () => {
				onChange?.({
					start: startOfDay(date),
					end: endOfDay(date),
				});
			};
		},
		[onChange]
	);

	return (
		<Container className={containerClassName}>
			<Navigation>
				<NavigationButton direction="left" onClick={prev} />
				<Title date={date} />
				<NavigationButton direction="right" onClick={next} />
			</Navigation>
			<Header>
				<HeaderCell>#</HeaderCell>
				<HeaderCell>M</HeaderCell>
				<HeaderCell>T</HeaderCell>
				<HeaderCell>W</HeaderCell>
				<HeaderCell>T</HeaderCell>
				<HeaderCell>F</HeaderCell>
				<HeaderCell>S</HeaderCell>
				<HeaderCell>S</HeaderCell>
			</Header>
			{weeks.map((week, weekIndex) => {
				return (
					<Row key={weekIndex}>
						<DayCell
							isSelected={selectionMode === 'row' && isWeekSelected(week.week)}
							isFirstSelectedCell={selectionMode === 'row'}
						>
							<span>{week.week}</span>
						</DayCell>

						{week.dates.map((current, index) => {
							return (
								<DayCell
									selectedClassName={selectedCellClassName}
									isSelected={isDateSelected(current)}
									isNotValid={!isSameMonth(current, date)}
									isFirstSelectedCell={
										selectionMode !== 'row' && isFirstSelected(current)
									}
									isLastSelectedCell={isLastSelectedDate(current)}
									onClick={handleClicked(week, current)}
									key={index}
								>
									{format(current, 'd')}
								</DayCell>
							);
						})}
					</Row>
				);
			})}
		</Container>
	);
};
