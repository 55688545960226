import Styles from './index.module.scss';
import React from 'react';
import { ChevronDownIcon } from '@/components/Elements/Icons';

interface Props {
	children?: React.ReactNode;
	title: string;
	icon?: React.ReactElement;
}

export const NavigationGroup = ({ icon, title, children }: Props) => {
	const [isOpen, setIsOpen] = React.useState(false);
	return (
		<div className={`${Styles.border} ${isOpen ? Styles.expanded : ''}`}>
			<div
				className={Styles.sideNavigationHeader}
				onClick={() => {
					setIsOpen(!isOpen);
				}}
			>
				<div>{icon}</div>
				<span>{title}</span>
				<ChevronDownIcon />
			</div>
			{isOpen && <div className={Styles.sideNavigation}>{children}</div>}
		</div>
	);
};
