import { useMemo } from 'react';
import Styles from './index.module.scss';

export type TabsPickerValue = {
	text: string;
	value: string;
};

interface Props {
	value?: string;
	items: TabsPickerValue[];
	onChange?: (e: string) => void;
}

export const TabsPicker = ({ items, value = '', onChange }: Props) => {
	const itemsDictionary = useMemo(() => {
		return items.reduce((acc, curr) => ((acc[curr.value ?? ''] = curr.value), acc), {});
	}, [items]);

	return (
		<div className={`${Styles.container}`}>
			{items.map((item) => {
				return (
					<button
						type="button"
						key={item.value}
						onClick={() => {
							onChange?.(item.value);
						}}
						className={itemsDictionary[item.value] === value ? Styles.active : ''}
					>
						{item.text}
					</button>
				);
			})}
		</div>
	);
};
