import { RecipientsForm } from '@/components/Form/RecipientsForm';
import { FormPage } from '@/layouts/FormPage';
import { PageLayout } from '@/layouts/PageLayout';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useCreateTaskMutation } from '@/app/api/Tasks';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { User } from '@/app/types/User';
import { Task } from '@/app/types/Task';
import toast from 'react-hot-toast';
import { TaskForm } from '@/components/Task';
import { TaskFormValues } from '@/components/Task/TaskForm/types';

export const New = () => {
	const buttons = [
		{ children: 'Save as Template', onClick: console.log },
		{ children: 'Save as Draft', onClick: console.log },
	];

	const navigate = useNavigate();
	const [create, { isSuccess, isError, isLoading }] = useCreateTaskMutation();

	useEffect(() => {
		if (isSuccess && !isError) {
			navigate(-1);
		}

		if (isError) {
			toast.custom(() => (
				<span
					style={{
						background: '#fe3266',
						display: 'flex',
						gap: 8,
						padding: 16,
						borderRadius: 18,
						color: '#fff',
						fontFamily: 'Graphik',
						fontSize: 16,
						maxWidth: 350,
					}}
				>
					Something went wrong! <br /> Please contact system administrator
				</span>
			));
		}
	}, [isSuccess, isError, navigate]);

	const { control, handleSubmit } = useForm<TaskFormValues>({
		defaultValues: {
			title: '',
			body: '',
			users: [],
		},
	});

	const onSubmit: SubmitHandler<TaskFormValues> = async (model) => {
		const groups = new Map<string, User[]>();
		(model.users ?? []).forEach((item) => {
			groups.set(item.org_id ?? '', [...(groups.get(item.org_id ?? '') ?? []), item]);
		});

		const task: Partial<Task> = {
			content: {
				title: {
					text: model.title,
				},
				body: {
					text: model.body,
				},
			},
			recipient_groups: [...(groups ?? [])].map((gr) => {
				return {
					group_number: 0,
					org_id: gr[0],
					recipients: [...(gr[1] ?? [])].map((user) => {
						return {
							type: 'user',
							id: user.id ?? '',
						};
					}),
				};
			}),
		};

		await create(task);
	};

	return (
		<PageLayout title="Tasks">
			<FormPage buttons={buttons}>
				<RecipientsForm
					title="Task"
					control={control}
					titlePath="title"
					bodyPath="body"
					recipientsPath="users"
					onSubmit={handleSubmit(onSubmit)}
				>
					<TaskForm isLoading={isLoading} control={control} checklistPath="checklist" />
				</RecipientsForm>
			</FormPage>
		</PageLayout>
	);
};
