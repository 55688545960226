import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { NotificationsSearchFilter } from 'types/Filter';

const initialState: NotificationsSearchFilter = {
	onlyScheduled: false,
	title: '',
};

export const notificationFilterSlice = createSlice({
	name: 'notificationFilter',
	initialState,
	reducers: {
		showOnlyScheduled: (state, action: PayloadAction<boolean>) => {
			state.onlyScheduled = action.payload;
		},
		search: (state, action: PayloadAction<string | undefined>) => {
			state.title = action.payload;
		},
	},
});

export const { showOnlyScheduled, search } = notificationFilterSlice.actions;

export default notificationFilterSlice.reducer;
