import Styles from './index.module.scss';
import { NotificationListItem } from '@/app/types/Notification';
import { MRT_Row } from 'material-react-table';

export const RowDetails = ({ row }: { row: MRT_Row<NotificationListItem> }) => {
	return (
		<div className={Styles.rowDetails}>
			<span className={Styles.description}>{row.original.body}</span>
			<div className={Styles.users}>
				{row.original.users.map((user) => {
					return (
						<div key={user.id}>
							<div>{user.name}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
