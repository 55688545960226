import React from 'react';
import { createRoot } from 'react-dom/client';
import { msalInstance } from './services/msal';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { App } from './App';

void msalInstance.initialize().then(() => {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	const accounts = msalInstance.getAllAccounts();
	if (accounts.length > 0) {
		msalInstance.setActiveAccount(accounts[0]);
	}

	msalInstance.addEventCallback((event: EventMessage) => {
		if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
			const payload = event.payload as AuthenticationResult;
			const account = payload.account;
			msalInstance.setActiveAccount(account);
		}
	});

	const container = document.getElementById('root');
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-non-null-assertion
	const root = createRoot(container!);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	);
});
