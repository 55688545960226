import Styles from './index.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@/components/Elements/Input';

export const OrganizationAddressField = () => {
	const { control } = useFormContext();

	return (
		<div className={Styles.fieldGridAddress}>
			<span className={Styles.fieldSmallTitle}>
				Country<span>*</span>
			</span>
			<span className={Styles.fieldSmallTitle}>
				City<span>*</span>
			</span>

			<Controller
				name="country"
				control={control}
				render={({ field }) => {
					return (
						<Input
							value={field.value}
							onChange={field.onChange}
							placeholder="Add Country"
						/>
					);
				}}
			/>
			<Controller
				control={control}
				name="city"
				render={({ field }) => {
					return (
						<Input
							value={field.value}
							onChange={field.onChange}
							placeholder="Add City"
						/>
					);
				}}
			/>
			<span className={Styles.fieldSmallTitle}>
				Address<span>*</span>
			</span>
			<Controller
				control={control}
				name="address"
				render={({ field }) => {
					return (
						<Input
							value={field.value}
							onChange={field.onChange}
							placeholder="Enter Street Name and Number"
						/>
					);
				}}
			/>
		</div>
	);
};
