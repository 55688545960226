import Styles from './styles.module.scss';

export const Header = ({
	isArray,
	name,
	items,
	onClick,
}: {
	isArray: boolean;
	name: string;
	items: number;
	onClick?: () => void;
}) => {
	return (
		<span onClick={onClick} className={Styles.header}>
			<span>{`${name}:`}</span>
			<span>{isArray ? `[]` : `{}`}</span>
			<span>
				{`${items}`} {isArray ? 'items' : 'keys'}
			</span>
		</span>
	);
};
