import Styles from './index.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { GeneralFormContext } from './types';
import { useContext } from 'react';
import { SettingsClockIcon } from '@/components/Elements/Icons';
import { InputSelect } from '@/components/Elements/InputSelect';

export const OrganizationTimeZoneField = () => {
	const { control } = useFormContext();
	const { timeZones } = useContext(GeneralFormContext);

	return (
		<>
			<SettingsClockIcon className={Styles.fieldIcon} />
			<span className={Styles.fieldTitle}>Time Zone</span>
			<Controller
				control={control}
				name="timeZone"
				render={({ field }) => {
					return (
						<InputSelect
							placeholder="Select Time Zone"
							value={field.value}
							onChange={field.onChange}
							items={timeZones}
						/>
					);
				}}
			/>
		</>
	);
};
