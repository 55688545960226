import Styles from './index.module.scss';

interface Props {
	icon?: React.ReactElement;
	title: string;
	onClick?: () => void;
}

export const ContextMenuItem = ({ icon, title, onClick }: Props) => {
	return (
		<button className={Styles.container} onClick={onClick}>
			{icon}
			<span>{title}</span>
		</button>
	);
};
