import React from 'react';
import Styles from './index.module.scss';
import { OrganizationFormValues } from '@/app/types/OrganizationFormValues';
import { useFormContext, useWatch } from 'react-hook-form';
import { OrganizationErrorObject } from '@/app/types/Organization';
import { toErrorsDictionary } from '@/app/utils/organization/data';

interface Props {
	errors: OrganizationErrorObject[] | undefined;
}

export const UserInfo = ({ errors }: Props) => {
	const { control } = useFormContext<OrganizationFormValues>();
	const { users = [] } = useWatch({ control, name: 'userInfo' });

	const mapper = toErrorsDictionary(errors);

	return (
		<div className={`${Styles.container}`}>
			{users.map((user, index) => {
				return (
					<React.Fragment key={index}>
						<h5 key={index}>{`${index + 1} User Details`.padStart(2, '0')}</h5>
						<div className={Styles.details}>
							<span className={Styles.title}>{'Name:'}</span>
							<span
								className={Styles.text}
							>{`${user.firstName} ${user.lastName}`}</span>
							{mapper?.[`users[${index + 1}].firstName`] && (
								<span className={Styles.error}>
									{mapper?.[`users[${index + 1}].firstName`]}
								</span>
							)}
							{mapper?.[`users[${index + 1}].lastName`] && (
								<span className={Styles.error}>
									{mapper?.[`users[${index + 1}].LastName`]}
								</span>
							)}
							<span className={Styles.title}>{'Email:'}</span>
							<span className={Styles.text}>{user.email}</span>
							{mapper?.[`users[${index + 1}].email`] && (
								<span className={Styles.error}>
									{mapper?.[`users[${index + 1}].email`]}
								</span>
							)}
							<span className={Styles.title}>{'Password:'}</span>
							<span className={Styles.text}>{user.password}</span>
							{mapper?.[`users[${index + 1}].password`] && (
								<span className={Styles.error}>
									{mapper?.[`users[${index + 1}].password`]}
								</span>
							)}
							<span className={Styles.title}>{'Watch-PIN:'}</span>
							<span className={Styles.text}>{user.pin}</span>
							{mapper?.[`users[${index + 1}].pin`] && (
								<span className={Styles.error}>
									{mapper?.[`users[${index + 1}].pin`]}
								</span>
							)}
						</div>
					</React.Fragment>
				);
			})}
		</div>
	);
};
