import {
	useLazySearchNotificationsQuery,
	useSearchNotificationsQuery,
} from '@/app/api/Notifications';
import { useAppSelector } from '@/app/hooks/useAppSelector';
import { Notification, NotificationListItem } from '@/app/types/Notification';
import { PaginatedResponse, ResponseMeta } from '@/app/types/Response';
import { useOrganizationsDictionary } from './useOrganizations';
import { useUsersDictionary } from './useUsers';

const EMPTY_NOTIFICATION_LIST = [];

export const useNotificationList = (title: string, onlyScheduled: boolean) => {
	const [triggerLoadMoreItems] = useLazySearchNotificationsQuery();
	const filter = useAppSelector((state) => state.filter);
	const organizationsDictionary = useOrganizationsDictionary();
	const { users } = useUsersDictionary();
	const { data = {} as PaginatedResponse<Notification>, isFetching } =
		useSearchNotificationsQuery({
			filter: {
				...filter,
				title,
				onlyScheduled,
			},
		});
	const { items = EMPTY_NOTIFICATION_LIST, meta = {} as ResponseMeta } = data;

	const { continuation, moreResults } = meta;

	const notifications: NotificationListItem[] = items.map((item) => ({
		id: item.id ?? '',
		title: item.content.title?.text ?? '',
		body: item.content.body?.text ?? '',
		sentBy: users[item.created_by ?? '']?.email ?? '',
		sentTo: 'N/A',
		readBy: 'N/A',
		details: 'N/A',
		organizationName: organizationsDictionary[item.org_id ?? '']?.name ?? '',
		organizationLocation: organizationsDictionary[item.org_id ?? '']?.time_zone ?? '',
		users: [],
	}));

	const loadMore = async () => {
		if (moreResults && continuation && !isFetching) {
			await triggerLoadMoreItems({
				filter: {
					...filter,
					title,
					onlyScheduled,
				},
				continuationToken: continuation,
			});
		}
	};

	return [notifications, { loadMore, isFetching }] as const;
};
