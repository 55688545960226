import { useEffect, useRef } from 'react';

export const useClickOutside = <T extends HTMLElement>(
	callback?: () => void,
	excluded?: HTMLElement[]
) => {
	const ref = useRef<T>(null);

	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			if (excluded?.some((el) => el.contains(e.target as Node))) {
				return;
			}

			if (ref.current && !ref.current.contains(e.target as Node)) {
				callback?.();
			}
		};

		const timer = setTimeout(() => {
			document.addEventListener('click', handleClick);
		}, 0);
		return () => {
			document.removeEventListener('click', handleClick);
			clearTimeout(timer);
		};
	}, [callback, excluded]);

	return { ref };
};
