import moment from 'moment';
import { Props } from './types';

export const useHandlers = ({ value, onChange }: Props) => {
	const timeType = value?.timeType ?? 'daily';
	const startTime = moment(value?.startTime ?? moment().startOf('day'));
	const endTime = moment(value?.endTime ?? moment().endOf('day'));

	const headerMapper = {
		weekly: `WEEK ${startTime.week()}`,
		monthly: `${startTime.format('YYYY')}`,
		custom: 'CUSTOM',
	};

	const resolveHeaderName = () => {
		if (timeType === 'daily') {
			return startTime.isSame(new Date(), 'day')
				? 'TODAY'
				: startTime.format('dddd').toUpperCase();
		}

		return headerMapper[timeType];
	};

	const headerValueMapper = {
		daily: `${startTime.format('DD MMM')}`,
		weekly: `${startTime.format('DD')} - ${endTime.format('DD')}`,
		monthly: `${startTime.format('MMMM')}`,
		custom: `${startTime.format('DD MMM')} - ${endTime.format('DD MMM')}`,
	};

	const setValue = (
		amount: number,
		unit: moment.unitOfTime.DurationConstructor,
		startOf?: moment.unitOfTime.StartOf
	) => {
		onChange?.({
			timeType,
			startTime: startTime
				.add(amount, unit)
				.startOf(startOf ?? unit)
				.format(),
			endTime: endTime
				.add(amount, unit)
				.endOf(startOf ?? unit)
				.format(),
		});
	};

	const callbackMapper: {
		[key: string]: (amount: number) => void;
	} = {
		daily: (amount: number) => setValue(amount, 'day'),
		weekly: (amount: number) => setValue(amount, 'week', 'isoWeek'),
		monthly: (amount: number) => setValue(amount, 'month'),
	};

	return {
		header: resolveHeaderName(),
		headerValue: headerValueMapper[timeType],
		handleChange: callbackMapper[timeType],
		timeType,
	};
};
