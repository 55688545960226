import { SettingsBellIcon } from '@/components/Elements/Icons';
import { SettingsRowElement } from '@/components/SettingsRowElement';
import { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

const TIME_OPTIONS = [5, 10, 15, 20, 25];
const INTERVAL_OPTIONS = [1, 2, 3, 4, 5];

export const SELECT_ITEMS = TIME_OPTIONS.map((time) => {
	return { text: `${time}`, value: time };
});

export const TIME_ITEMS = INTERVAL_OPTIONS.map((interval) => {
	return { text: `${interval}`, value: interval };
});

interface Props {
	name: string;
	type: 'select' | 'time';
	title: string;
}

export const Field = ({ title, name, type }: Props) => {
	const { control } = useFormContext();
	const isActive = useWatch({ name: `${name}.active` });

	const timeItems = useMemo(() => {
		return INTERVAL_OPTIONS.map((interval) => {
			const value = interval.toString();
			return { text: `${value}`, value };
		});
	}, []);

	return (
		<SettingsRowElement key={title} icon={<SettingsBellIcon />} title={title}>
			<Controller
				control={control}
				name={`${name}.active`}
				render={({ field: { value, onChange } }) => {
					return <SettingsRowElement.CheckBoxItem value={value} onChange={onChange} />;
				}}
			/>
			<Controller
				control={control}
				name={`${name}.value`}
				render={({ field: { value, onChange } }) => {
					if (type === 'select') {
						return (
							<SettingsRowElement.SelectItem
								value={value}
								onChange={onChange}
								measurement="min"
								items={SELECT_ITEMS}
								disabled={!isActive}
							/>
						);
					}

					return (
						<SettingsRowElement.SelectTimeItem
							items={timeItems}
							value={value}
							onChange={onChange}
							disabled={!isActive}
						/>
					);
				}}
			/>
		</SettingsRowElement>
	);
};
