import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { UsersPicker } from '../UsersPicker';
import { Header } from './Components/Header';
import { Recipients } from './Components/Recipients';
import Styles from './index.module.scss';
import { ResizableTextArea } from '@/components/Elements/ResizebleTextArea';

interface Props<T extends FieldValues> extends React.HTMLAttributes<HTMLFormElement> {
	title: string;
	control: Control<T>;
	recipientsPath: FieldPath<T>;
	titlePath: FieldPath<T>;
	bodyPath: FieldPath<T>;
}

export const RecipientsForm = <T extends FieldValues>({
	title,
	children,
	control,
	recipientsPath,
	titlePath,
	bodyPath,
	...props
}: Props<T>) => {
	return (
		<form className={Styles.container} {...props}>
			<div className={Styles.contentArea}>
				<Header>{title}</Header>
				<div className={Styles.content}>
					<div className={Styles.inputArea}>
						<Controller
							control={control}
							name={recipientsPath}
							render={({ field: { onChange, value } }) => (
								<Recipients value={value} onChange={onChange} />
							)}
						/>
						<div className={Styles.inputContainer}>
							<Controller
								control={control}
								name={titlePath}
								render={({ field: { onChange, value } }) => (
									<input placeholder="Title" value={value} onChange={onChange} />
								)}
							/>
							<Controller
								control={control}
								name={bodyPath}
								render={({ field: { onChange, value } }) => (
									<ResizableTextArea
										placeholder="Body"
										value={value}
										onChange={onChange}
									/>
								)}
							/>
						</div>
					</div>
					{children}
				</div>
			</div>
			<Controller
				control={control}
				name={recipientsPath}
				render={({ field: { onChange, value } }) => (
					<UsersPicker value={value} onChange={onChange} />
				)}
			/>
		</form>
	);
};
