import { useAppDispatch } from '@/app/hooks/useAppDispatch';
import { useAppSelector } from '@/app/hooks/useAppSelector';
import { selectPeriod } from '@/app/slices/Filter';
import { FilterTimeType } from '@/app/types/Filter';

export const usePeriodSelector = () => {
	const {
		timeType = 'daily',
		startTime = '',
		endTime = '',
	} = useAppSelector((state) => state.filter);

	const dispatch = useAppDispatch();

	const onChange = (value: { timeType: FilterTimeType; startTime: string; endTime: string }) => {
		dispatch(selectPeriod(value));
	};

	return [{ timeType, startTime, endTime }, onChange] as const;
};
