import React, { ComponentPropsWithRef } from 'react';
import Styles from './index.module.scss';
import { EyeIcon, EyeOffIcon } from '../Icons';

export const withPasswordReveal = (Component: React.FC<ComponentPropsWithRef<'input'>>) => {
	return (props: ComponentPropsWithRef<'input'>) => {
		const [isRevealed, setIsRevealed] = React.useState(false);

		return (
			<div className={Styles.container}>
				<Component {...props} type={isRevealed ? 'text' : 'password'} />
				{props.value && (
					<button
						type="button"
						onClick={() => {
							setIsRevealed(!isRevealed);
						}}
					>
						{isRevealed ? <EyeOffIcon /> : <EyeIcon />}
					</button>
				)}
			</div>
		);
	};
};
