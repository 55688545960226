import Styles from './index.module.scss';
import { RefObject } from 'react';

interface Props extends React.ComponentPropsWithRef<'button'> {
	variant?: 'primary' | 'secondary';
	ref?: RefObject<HTMLButtonElement>;
}

export const Button = ({
	type = 'button',
	variant = 'primary',
	ref,
	className,
	...props
}: Props) => {
	return (
		<button
			{...props}
			ref={ref}
			type={type}
			className={`${Styles.container} ${className ?? ''} ${variant === 'secondary' ? Styles.secondary : ''}`}
		/>
	);
};
