import React, { ComponentProps, useEffect } from 'react';
import Styles from './index.module.scss';
import { useDebounce } from '@/app/hooks/useDebounce';

interface Props extends React.ComponentProps<'input'> {
	className?: string;
	onDebounceChange?: (value: ComponentProps<'input'>['value']) => void;
}

export const Input = ({ className, onDebounceChange, ...props }: Props) => {
	const debounceValue = useDebounce(props.value, 500);

	useEffect(() => {
		onDebounceChange?.(debounceValue);
	}, [debounceValue, onDebounceChange]);

	return <input {...props} className={`${Styles.container} ${className ?? ''}`} />;
};
