import moment from 'moment';
import { Value } from './types';
import { useCallback } from 'react';

export {};

export const useValueHelpers = (activeButton: string, value?: Value) => {
	const updateValue = useCallback(
		(e: { start: Date }): Value => {
			const { start } = e;

			if (value?.timeType === 'weekly') {
				return {
					startTime: moment(start).startOf('isoWeek').format(),
					endTime: moment(start).endOf('isoWeek').format(),
					timeType: 'weekly',
				};
			}

			if (value?.timeType === 'custom') {
				let args = {
					...value,
					[`${activeButton}Time`]: moment(start).startOf('day').format(),
				};

				if (
					activeButton === 'start' ||
					moment(args.startTime).isAfter(moment(args.endTime))
				) {
					args = {
						...args,
						startTime: moment(start).startOf('day').format(),
						endTime: moment(start).add(1, 'day').endOf('day').format(),
					};
				}

				return args;
			}

			return {
				startTime: moment(start).startOf('day').format(),
				endTime: moment(start).endOf('day').format(),
				timeType: 'daily',
			};
		},
		[activeButton, value]
	);

	return updateValue;
};
