import Styles from './index.module.scss';
import { CollapseIcon, ExpanderIcon } from 'components/Elements/Icons';
import { useState } from 'react';

interface Props {
	children?: React.ReactNode;
}

export const Expander = ({ children }: Props) => {
	const [isExpanded, setIsExpanded] = useState(true);
	return (
		<nav className={`${Styles.container} ${!isExpanded ? Styles.collapsed : ''}`}>
			{children}
			<div className={`${Styles.expander} ${!isExpanded && Styles.collapsed}`}>
				{isExpanded && (
					<ExpanderIcon
						onClick={() => {
							setIsExpanded((state) => !state);
						}}
					/>
				)}
				{!isExpanded && (
					<CollapseIcon
						onClick={() => {
							setIsExpanded((state) => !state);
						}}
					/>
				)}
			</div>
		</nav>
	);
};
