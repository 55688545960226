import { format } from 'date-fns';
import React from 'react';
import Styles from './Title.module.scss';

interface Props extends React.ComponentPropsWithRef<'span'> {
	date: Date;
}

const Title = ({ date, ...props }: Props) => {
	return (
		<span {...props} className={Styles.container}>
			{format(date, 'MMMM')}
			<span>{format(date, 'yyyy')}</span>
		</span>
	);
};

export default Title;
