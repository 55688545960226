import { SettingsCodeIcon, SettingsZoomInIcon } from '@/components/Elements/Icons';
import { ZoomPicker } from '@/components/Elements/ZoomPicker';
import { SettingsRowElement } from '@/components/SettingsRowElement';
import { SettingsView } from '@/components/SettingsView';
import { Controller, useFormContext } from 'react-hook-form';

export const FormShiftPlannerOtherSettings = () => {
	const { control } = useFormContext();

	return (
		<SettingsView title="Other settings.">
			<SettingsView.Section title="">
				<SettingsRowElement title="Time Selection Interval" icon={<SettingsCodeIcon />}>
					<Controller
						name="shiftPlanner.timeSelectionInterval"
						render={({ field }) => {
							return (
								<SettingsRowElement.SelectTimeItem
									value={field.value}
									onChange={field.onChange}
									items={[
										{ text: '10', value: '10' },
										{ text: '15', value: '15' },
										{ text: '20', value: '20' },
										{ text: '30', value: '30' },
									]}
								/>
							);
						}}
					/>
				</SettingsRowElement>
				<SettingsRowElement
					title="Default Zoom Level"
					subtitle="(Timeline)"
					icon={<SettingsZoomInIcon />}
				>
					<Controller
						name="shiftPlanner.zoomLevel"
						control={control}
						render={({ field }) => {
							return <ZoomPicker value={field.value} onChange={field.onChange} />;
						}}
					/>
				</SettingsRowElement>
			</SettingsView.Section>
		</SettingsView>
	);
};
