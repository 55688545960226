import { DeleteModal } from '@/components/Modals/DeleteModal';
import { useLayoutEffect, useRef, useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';
import { useOrganizationFormDataProvider } from '../FormOrganizationDataProvider';

interface Props {
	children: React.ReactNode;
	protectedUrl: string;
}

export const FormValuesRouteProtector = ({ children, protectedUrl }: Props) => {
	const { isSuccess } = useOrganizationFormDataProvider();
	const [visible, setVisible] = useState(false);
	const shouldBlockRef = useRef(true);
	const navigate = useNavigate();

	const blocker = useBlocker(
		({ nextLocation }) =>
			shouldBlockRef.current && !nextLocation.pathname.includes(protectedUrl)
	);

	useLayoutEffect(() => {
		if (blocker.state === 'blocked') {
			setVisible(true);
		}
	}, [blocker]);

	useLayoutEffect(() => {
		shouldBlockRef.current = !isSuccess;
	}, [isSuccess]);

	return (
		<>
			{children}
			{visible && (
				<DeleteModal
					cancelText="No"
					confirmText="Yes"
					title="Are you sure you wish to cancel the changes?"
					onCloseRequest={() => {
						setVisible(false);
					}}
					onClick={() => {
						shouldBlockRef.current = false;
						navigate('/organizations', { replace: true });
					}}
				>
					<></>
				</DeleteModal>
			)}
		</>
	);
};
