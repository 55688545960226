import { NavLink } from 'react-router-dom';
import Styles from './index.module.scss';
import { brandingLinkGroups, linkGroups } from './NavigationConfig';
import { useCallback } from 'react';
import { NavigationGroup } from './NavigationGroup';

interface Props {
	children?: React.ReactNode;
	urlParam: string;
	organization: string;
	numberOfUsers: number;
}

export const OrganizationSettingsLayout = ({
	children,
	urlParam,
	organization,
	numberOfUsers,
}: Props) => {
	const className = useCallback(({ isActive }) => {
		return isActive ? Styles.active : '';
	}, []);

	return (
		<div className={Styles.container}>
			<div className={Styles.navigation}>
				<span className={Styles.breadCrump}>
					{`${organization} | Number of users: ${numberOfUsers}`}
				</span>
				<p className={Styles.groupTitle}>Branding Settings</p>
				{brandingLinkGroups.map((group, index) => {
					return (
						<div key={`branding-${index}`} className={`${Styles.border}`}>
							<div className={Styles.sideNavigation}>
								{group.links.map((link, index) => {
									return (
										<NavLink
											key={`branding-link-${index}-${link.to}`}
											to={`/organizations/${urlParam}/configure-settings/${link.to}`}
											replace
											className={className}
										>
											{link.icon}
											<span>{link.title}</span>
										</NavLink>
									);
								})}
							</div>
						</div>
					);
				})}
				<p className={Styles.groupTitle}>All Settings</p>
				{linkGroups.map((group, index) => {
					return (
						<NavigationGroup
							key={`group-${index}`}
							icon={group.icon}
							title={group.title}
						>
							{group.links.map((link, index) => {
								return (
									<NavLink
										key={`link-${index}-${link.to}`}
										to={`/organizations/${urlParam}/configure-settings/${link.to}`}
										replace
										className={className}
									>
										{link.icon}
										<span>{link.title}</span>
									</NavLink>
								);
							})}
						</NavigationGroup>
					);
				})}
			</div>
			{children}
		</div>
	);
};
