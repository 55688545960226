import { useSystemStatusQuery } from '@/app/api/Organizations';
import Styles from './index.module.scss';
import { useAppSelector } from '@/app/hooks/useAppSelector';
import { useDebounceInputHandler } from '@/app/hooks/useDebounceInputHandler';

import { SearchInput } from '@/components/Elements/SearchInput';
import { FilterPageLayout } from '@/components/FilterPageLayout';
import { SystemStatusTable } from '@/components/SystemStatus';
import { useState } from 'react';

export const SystemStatus = () => {
	const filter = useAppSelector((state) => state.filter);
	const [organizationName, setOrganizationName] = useState('');
	const handler = useDebounceInputHandler(setOrganizationName);
	const { organizationIds = [] } = filter;
	const { data } = useSystemStatusQuery({ organizationIds, organizationName });

	return (
		<FilterPageLayout title="System Status" className={Styles.container}>
			<div className={Styles.filter}>
				<SearchInput placeholder="Search organizations" onChange={handler} />
			</div>
			<SystemStatusTable data={data?.items ?? []} />
		</FilterPageLayout>
	);
};
