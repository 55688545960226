import { SettingsClockIcon, SettingsPressIcon } from '@/components/Elements/Icons';
import { SettingsView } from '@/components/SettingsView';
import { SettingsRowElement } from '@/components/SettingsRowElement';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const FormAutomation = () => {
	const { control } = useFormContext();
	const seconds = useMemo(() => {
		return Array.from({ length: 61 }, (_, i) => {
			return {
				text: i.toString().padStart(2, '0'),
				value: i.toString(),
			};
		});
	}, []);

	return (
		<SettingsView title="Multiple presses.">
			<SettingsView.Section title="">
				<SettingsRowElement title="Block Multiple Presses" icon={<SettingsPressIcon />}>
					<Controller
						control={control}
						name="automation.blockMultiplePresses"
						render={({ field }) => {
							return (
								<SettingsRowElement.CheckBoxItem
									value={field.value}
									onChange={field.onChange}
								/>
							);
						}}
					/>
				</SettingsRowElement>

				<SettingsRowElement
					icon={<SettingsClockIcon />}
					title="Minimum Time Between Pushes"
					subtitle="(Seconds)"
				>
					<Controller
						control={control}
						name="automation.minTimeBetweenPushes"
						render={({ field }) => {
							return (
								<SettingsRowElement.SelectTimeItem
									value={field.value}
									onChange={field.onChange}
									items={seconds}
								/>
							);
						}}
					/>
				</SettingsRowElement>
			</SettingsView.Section>
		</SettingsView>
	);
};
