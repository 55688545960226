import {
	SettingsActivityIcon,
	SettingsCalendarIcon,
	SettingsCheckCircleIcon,
	SettingsClockIcon,
	SettingsLayersIcon,
	SettingsMenuLeftIcon,
	SettingsPressIcon,
	SettingsSendIcon,
	SettingsTabletIcon,
	SettingsToolsIcon,
	SettingsWatchIcon,
} from '@/components/Elements/Icons';

type LinkGroup = {
	title: string;
	icon?: React.ReactElement;
	links: {
		title: string;
		to: string;
		icon: React.ReactElement;
	}[];
};

export const brandingLinkGroups: LinkGroup[] = [
	{
		title: 'Branding',
		icon: <SettingsLayersIcon />,
		links: [
			{
				title: 'Watch',
				to: 'branding/watch',
				icon: <SettingsWatchIcon />,
			},
			{
				title: 'Tablet',
				to: 'branding/tablet',
				icon: <SettingsTabletIcon />,
			},
			{
				title: 'Actions',
				to: 'branding/actions',
				icon: <SettingsClockIcon />,
			},
		],
	},
];

export const linkGroups: LinkGroup[] = [
	{
		title: 'Shift Planner',
		icon: <SettingsCalendarIcon />,
		links: [
			{
				title: 'Activities',
				to: 'shift-planner/activities',
				icon: <SettingsActivityIcon />,
			},
			{
				title: 'Working Hours',
				to: 'shift-planner/working-hours',
				icon: <SettingsClockIcon />,
			},
			{
				title: 'Other Settings',
				to: 'shift-planner/other-settings',
				icon: <SettingsMenuLeftIcon />,
			},
		],
	},
	{
		title: 'Messaging',
		icon: <SettingsSendIcon />,
		links: [
			{
				title: 'Tasks',
				to: 'messaging/tasks',
				icon: <SettingsCheckCircleIcon />,
			},
			{
				title: 'Actions',
				to: 'messaging/actions',
				icon: <SettingsClockIcon />,
			},
		],
	},
	{
		title: 'Automation',
		icon: <SettingsLayersIcon />,
		links: [
			{
				title: 'Multiple presses',
				to: 'automation/multiple-presses',
				icon: <SettingsPressIcon />,
			},
		],
	},
	{
		title: 'Device Settings',
		icon: <SettingsToolsIcon />,
		links: [
			{
				title: 'Watch',
				to: 'device-settings/watch',
				icon: <SettingsWatchIcon />,
			},
			{
				title: 'Tablet',
				to: 'device-settings/tablet',
				icon: <SettingsTabletIcon />,
			},
		],
	},
];
