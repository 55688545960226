import { OrganizationCreateDto } from '@/app/types/Organization';
import { OrganizationFormValues, SettingsFormValues } from '@/app/types/OrganizationFormValues';
import { Settings } from '@/app/types/Settings';
import { settingsDefaultValues } from '@/components/Organizations/FormOrganizationDataProvider/initialState';

export const mapSettingsToFormValues = (settings?: Settings): SettingsFormValues => {
	if (!settings) {
		return settingsDefaultValues;
	}

	let updated: OrganizationFormValues['settings'] = {
		...settingsDefaultValues,
	};

	if (settings) {
		updated = {
			...updated,
			shiftPlanner: {
				...updated.shiftPlanner,
				activities: [],
				workingHours: {
					monday: {
						start: {
							hours: settings.general.shift_planner.work_hours.monday
								.start_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.monday.start_time_minutes,
						},
						end: {
							hours: settings.general.shift_planner.work_hours.monday.end_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.monday.end_time_minutes,
						},
					},
					tuesday: {
						start: {
							hours: settings.general.shift_planner.work_hours.tuesday
								.start_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.tuesday
									.start_time_minutes,
						},
						end: {
							hours: settings.general.shift_planner.work_hours.tuesday.end_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.tuesday.end_time_minutes,
						},
					},
					wednesday: {
						start: {
							hours: settings.general.shift_planner.work_hours.wednesday
								.start_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.wednesday
									.start_time_minutes,
						},
						end: {
							hours: settings.general.shift_planner.work_hours.wednesday
								.end_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.wednesday
									.end_time_minutes,
						},
					},
					thursday: {
						start: {
							hours: settings.general.shift_planner.work_hours.thursday
								.start_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.thursday
									.start_time_minutes,
						},
						end: {
							hours: settings.general.shift_planner.work_hours.thursday
								.end_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.thursday.end_time_minutes,
						},
					},
					friday: {
						start: {
							hours: settings.general.shift_planner.work_hours.friday
								.start_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.friday.start_time_minutes,
						},
						end: {
							hours: settings.general.shift_planner.work_hours.friday.end_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.friday.end_time_minutes,
						},
					},
					saturday: {
						start: {
							hours: settings.general.shift_planner.work_hours.saturday
								.start_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.saturday
									.start_time_minutes,
						},
						end: {
							hours: settings.general.shift_planner.work_hours.saturday
								.end_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.saturday.end_time_minutes,
						},
					},
					sunday: {
						start: {
							hours: settings.general.shift_planner.work_hours.sunday
								.start_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.sunday.start_time_minutes,
						},
						end: {
							hours: settings.general.shift_planner.work_hours.sunday.end_time_hours,
							minutes:
								settings.general.shift_planner.work_hours.sunday.end_time_minutes,
						},
					},
				},
				zoomLevel: settings.general.shift_planner.default_zoom ?? 30,
				timeSelectionInterval: settings.general.shift_planner.default_intervals,
			},
			tasks: {
				...updated.tasks,
				defaultTaskDuration: settings.general.tasks.frontend.end_duration_minutes,
				defaultRemindersActive: settings.general.tasks.defaults.config.reminders_enabled,
				reminderBeforeStart: {
					active: settings.general.tasks.defaults.config.start_reminder_enabled,
					value: settings.general.tasks.defaults.config.start_reminder_time,
				},
				reminderBeforeEnd: {
					active: settings.general.tasks.defaults.config.end_reminder_enabled,
					value: settings.general.tasks.defaults.config.end_reminder_time,
				},
				reminderOverdue: {
					active: settings.general.tasks.defaults.config.complete_reminder_enabled,
					value: settings.general.tasks.defaults.config.complete_reminder_time,
				},
			},
			actions: {
				...updated.actions,
				autoForward: [
					settings.general.actions.frontend.autoforward_enum.medium,
					settings.general.actions.frontend.autoforward_enum.normal,
					settings.general.actions.frontend.autoforward_enum.urgent,
				],
				sendFeedback: settings.general.actions.defaults.config.send_feedback,
				acceptanceFeedback: {
					active: settings.general.actions.defaults.config.acceptance_feedback,
					value: settings.general.actions.defaults.config.acceptance_feedback_content,
				},
				rejectionFeedback: {
					active: settings.general.actions.defaults.config.rejection_feedback,

					value: settings.general.actions.defaults.config.rejection_feedback_content,
				},
				acceptedByMessage: settings.general.actions.defaults.config.send_feedback,
				acceptedByMessageTitle:
					settings.general.actions.defaults.config
						.accepted_by_other_custom_alert_screen_title,
				acceptedByMessageBody:
					settings.general.actions.defaults.config
						.accepted_by_other_custom_alert_screen_body,
				completionConfirmation:
					settings.general.actions.defaults.config.demand_action_completion,
				useSplashScreen:
					settings.general.actions.defaults.config.is_action_alert_screen_enabled,
				incomingMessageDuration:
					settings.general.actions.defaults.config.action_alert_screen_duration_in_ms,
			},
			automation: {
				...updated.automation,
				blockMultiplePresses: settings.general.automation.defaults.throttle_enabled,
				minTimeBetweenPushes: settings.general.automation.defaults.throttle_threshold,
			},
			tablet: {
				...updated.tablet,
				status: settings.tablet.show_screen_status,
				workingHours: {
					monday: {
						start: {
							hours: settings.tablet.show_screen_schedule.monday.start_time_hours,

							minutes: settings.tablet.show_screen_schedule.monday.start_time_minutes,
						},
						end: {
							hours: settings.tablet.show_screen_schedule.monday.end_time_hours,
							minutes: settings.tablet.show_screen_schedule.monday.end_time_minutes,
						},
					},
					tuesday: {
						start: {
							hours: settings.tablet.show_screen_schedule.tuesday.start_time_hours,
							minutes:
								settings.tablet.show_screen_schedule.tuesday.start_time_minutes,
						},
						end: {
							hours: settings.tablet.show_screen_schedule.tuesday.end_time_hours,
							minutes: settings.tablet.show_screen_schedule.tuesday.end_time_minutes,
						},
					},
					wednesday: {
						start: {
							hours: settings.tablet.show_screen_schedule.wednesday.start_time_hours,
							minutes:
								settings.tablet.show_screen_schedule.wednesday.start_time_minutes,
						},
						end: {
							hours: settings.tablet.show_screen_schedule.wednesday.end_time_hours,
							minutes:
								settings.tablet.show_screen_schedule.wednesday.end_time_minutes,
						},
					},
					thursday: {
						start: {
							hours: settings.tablet.show_screen_schedule.thursday.start_time_hours,
							minutes:
								settings.tablet.show_screen_schedule.thursday.start_time_minutes,
						},
						end: {
							hours: settings.tablet.show_screen_schedule.thursday.end_time_hours,
							minutes: settings.tablet.show_screen_schedule.thursday.end_time_minutes,
						},
					},
					friday: {
						start: {
							hours: settings.tablet.show_screen_schedule.friday.start_time_hours,
							minutes: settings.tablet.show_screen_schedule.friday.start_time_minutes,
						},
						end: {
							hours: settings.tablet.show_screen_schedule.friday.end_time_hours,
							minutes: settings.tablet.show_screen_schedule.friday.end_time_minutes,
						},
					},
					saturday: {
						start: {
							hours: settings.tablet.show_screen_schedule.saturday.start_time_hours,
							minutes:
								settings.tablet.show_screen_schedule.saturday.start_time_minutes,
						},
						end: {
							hours: settings.tablet.show_screen_schedule.saturday.end_time_hours,
							minutes: settings.tablet.show_screen_schedule.saturday.end_time_minutes,
						},
					},
					sunday: {
						start: {
							hours: settings.tablet.show_screen_schedule.sunday.start_time_hours,
							minutes: settings.tablet.show_screen_schedule.sunday.start_time_minutes,
						},
						end: {
							hours: settings.tablet.show_screen_schedule.sunday.end_time_hours,
							minutes: settings.tablet.show_screen_schedule.sunday.end_time_minutes,
						},
					},
				},
				templates: [],
				noResponseTimer: settings.tablet.no_response_screen_timer,
			},
			watch: {
				...updated.watch,
				welcomeMessage: settings.watch.login_message.title?.text,
				goodbyeMessage: settings.watch.logout_message.title?.text,
				callForHelp: settings.watch.menu_trigger_group_id,
			},
		};
	}

	return updated;
};

export const mapFormValuesToDto = (values: OrganizationFormValues): OrganizationCreateDto => {
	const {
		ownerEmail,
		ownerFirstName,
		ownerLastName,
		ownerPassword,
		parentOrganization,
		organizationType,
		organisationName,
		timeZone,
		city,
		country,
		address,
	} = values.generalInfo;

	const workingHours: {
		[key: string]: {
			start: {
				hours: number;
				minutes: number;
			};
			end: {
				hours: number;
				minutes: number;
			};
		};
	} = values.settings?.shiftPlanner?.workingHours ?? {};

	const createWorkingDay = (day: string) => {
		const startHours = workingHours[day].start.hours.toString().padStart(2, '0');
		const startMinutes = workingHours[day].start.minutes.toString().padStart(2, '0');
		const endHours = workingHours[day].end.hours.toString().padStart(2, '0');
		const endMinutes = workingHours[day].end.minutes.toString().padStart(2, '0');

		return `${startHours}:${startMinutes}-${endHours}:${endMinutes}`;
	};

	return {
		organization: {
			parentId: parentOrganization,
			type: organizationType,
			name: organisationName,
			timeZone: timeZone ?? '',
			streetAddress: {
				street: address,
				city,
				country,
				longitude: 0,
				latitude: 0,
			},
		},
		users: [
			{
				email: ownerEmail,
				firstName: ownerFirstName,
				lastName: ownerLastName,
				accessLevel: 'owner',
				password: ownerPassword,
				pin: undefined,
			},
			...(values.userInfo.users ?? []).map((user) => ({
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
				accessLevel: 'employee',
				password: user.password,
				pin: user.pin,
			})),
		],
		shiftActivityTypes:
			values.settings?.shiftPlanner?.activities?.map((activity) => ({
				name: activity.name,
				color: activity.color,
			})) ?? [],
		settings: {
			portal: values.generalInfo?.organizationLogo
				? {
						logo: values.generalInfo?.organizationLogo ?? undefined,
					}
				: undefined,
			actions: {
				autoForwardAfterSeconds: values.settings?.actions?.autoForward ?? [],
				isFeedbackEnabled: values.settings?.actions?.sendFeedback ?? false,
				feedbackWhenAccepted: {
					isEnabled: values.settings?.actions?.acceptanceFeedback?.active ?? false,
					message: values.settings?.actions?.acceptanceFeedback?.value ?? '',
				},
				feedbackWhenDeclined: {
					isEnabled: values.settings?.actions?.rejectionFeedback?.active ?? false,
					message: values.settings?.actions?.rejectionFeedback?.value ?? '',
				},
				isAcceptedByOtherEnabled: values.settings?.actions?.acceptedByMessage ?? false,
				acceptedByOtherTitle: values.settings?.actions?.acceptedByMessageTitle ?? '',
				acceptedByOtherBody: values.settings?.actions?.acceptedByMessageBody ?? '',
				isCompletedByUserRequired:
					values.settings?.actions?.completionConfirmation ?? false,
				isAlertScreenEnabled: values.settings?.actions?.useSplashScreen ?? false,
				alertScreenDurationInMilliseconds:
					values.settings?.actions?.incomingMessageDuration ?? 0,
			},
			tasks: {
				defaultTaskDurationInMinutes: values.settings?.tasks?.defaultTaskDuration ?? 0,
				isRemindersEnabled: values.settings?.tasks?.defaultRemindersActive ?? false,
				startReminder: {
					isEnabled: values.settings?.tasks?.reminderBeforeStart?.active ?? false,
					value: values.settings?.tasks?.reminderBeforeStart?.value ?? 0,
				},
				endReminder: {
					isEnabled: values.settings?.tasks?.reminderBeforeEnd?.active ?? false,
					value: values.settings?.tasks?.reminderBeforeEnd?.value ?? 0,
				},
				overdueReminder: {
					isEnabled: values.settings?.tasks?.reminderOverdue?.active ?? false,
					value: values.settings?.tasks?.reminderOverdue?.value ?? 0,
				},
				overdueReminderInterval: values.settings?.tasks?.reminderOverdueInterval ?? 0,
				overdueRemindersMax: values.settings?.tasks?.overdueRemindersNumber ?? 0,
			},
			shifts: {
				workingHours: {
					monday: createWorkingDay('monday'),
					tuesday: createWorkingDay('tuesday'),
					wednesday: createWorkingDay('wednesday'),
					thursday: createWorkingDay('thursday'),
					friday: createWorkingDay('friday'),
					saturday: createWorkingDay('saturday'),
					sunday: createWorkingDay('sunday'),
				},
				timeSelectionInterval: values.settings?.shiftPlanner?.timeSelectionInterval ?? 0,
				zoomLevel: values.settings?.shiftPlanner?.zoomLevel ?? 0,
			},
			tablets: {
				noResponsePeriodInSeconds: values.settings?.tablet?.noResponseTimer ?? 0,
			},
			triggers: {
				shouldThrottle: values.settings?.automation?.blockMultiplePresses ?? false,
				throttlePeriodInSeconds: values.settings?.automation?.minTimeBetweenPushes ?? 0,
			},
			watches: {
				loginMessage: values.settings?.watch?.welcomeMessage ?? '',
				logoutMessage: values.settings?.watch?.goodbyeMessage ?? '',
				returnToWatchFaceAfterMilliseconds:
					values.settings?.watch?.watchFaceForceReturn ?? 0,
				logo: values.settings?.watch?.logo ?? undefined,
			},
		},
	};
};
