import React from 'react';
import Styles from './styles.module.scss';

export const EditorItem = ({ name, value }: { name: string; value: unknown }) => {
	const renderValue = React.useMemo(() => {
		switch (typeof value) {
			case 'string':
				return <span className={Styles.string}>{`"${value}"`}</span>;
			case 'number':
				return <span className={Styles.number}>{`${value}`}</span>;
			case 'boolean':
				return <span className={Styles.boolean}>{`${value}`}</span>;
			default:
				return <span>{`${value as string}`}</span>;
		}
	}, [value]);

	return (
		<li>
			<h4>{`${name}:`}</h4>
			{renderValue}
		</li>
	);
};
