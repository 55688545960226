import React from 'react';
import Styles from '../index.module.scss';
import { DeleteIcon } from '@/components/Elements/Icons';
import { Input } from '@/components/Elements/Input';
import { UploadButton } from '@/components/Elements/UploadButton';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
	items?: { id: string; name?: string; file?: File }[];
	onDelete?: (index: number) => void;
}

export const Table = ({ items, onDelete }: Props) => {
	const { control } = useFormContext();

	return (
		<div className={Styles.container}>
			<div className={Styles.header}>Name</div>
			<div className={Styles.header}>Settings File</div>
			{items?.map((item, index) => {
				return (
					<React.Fragment key={item.id}>
						<div className={Styles.item}>
							<Controller
								control={control}
								defaultValue={''}
								name={`tablet.templates[${index}].name`}
								render={({ field }) => {
									return (
										<Input
											placeholder={`Untitled `}
											value={field.value}
											onChange={field.onChange}
										/>
									);
								}}
							/>
						</div>
						<div className={Styles.item}>
							<UploadButton />
						</div>
						<div
							className={Styles.item}
							onClick={() => {
								onDelete?.(index);
							}}
						>
							<DeleteIcon />
						</div>
					</React.Fragment>
				);
			})}
		</div>
	);
};
