import Styles from './index.module.scss';
import { useAppSelector } from '@/app/hooks/useAppSelector';
import { TotalActionsPerOrganization } from '@/components/Dashboard/TotalActionsPerOrganization';
import { AcceptanceTimeWidget } from '@/components/Dashboard/AcceptanceTimeWidget';
import { ResponseRatio } from '@/components/Dashboard/ResponseRatioWidget';
import { PortalPageLayout } from '@/layouts/PortalPageLayout';
import { useActionDashboardQuery } from '@/app/hooks/useActionDashboardQuery';

export const Dashboard = () => {
	const filter = useAppSelector((state) => state.filter);
	const { data, isFetching } = useActionDashboardQuery(filter);
	const { totalActionsSeries, acceptedTimeSeries, responseRationSeries } = data;

	return (
		<PortalPageLayout title="Dashboard" displayFilter={true} displayPeriodSelector={true}>
			<div className={Styles.container}>
				<TotalActionsPerOrganization isLoading={isFetching} data={totalActionsSeries} />
				<AcceptanceTimeWidget isLoading={isFetching} data={acceptedTimeSeries} />
				<ResponseRatio isLoading={isFetching} data={responseRationSeries} />
			</div>
		</PortalPageLayout>
	);
};
