import Styles from './index.module.scss';
import { MultiSelect } from '../Elements/MultiSelect';
import { Option } from 'components/Elements/MultiSelect/types';
import { useOrganizations } from 'hooks/useOrganizations';
import { useAppSelector } from '@/app/hooks/useAppSelector';
import { useAppDispatch } from '@/app/hooks/useAppDispatch';
import { setOrganizationIds } from '@/app/slices/Filter';

export const OrganizationsTopBarFilter = () => {
	const { organizations } = useOrganizations();
	const dispatch = useAppDispatch();

	const selectedOrganizationsIds = useAppSelector((state) => state.filter.organizationIds ?? []);

	const value = organizations
		.filter((item) => selectedOrganizationsIds.some((id) => id === item.id))
		.map((org) => {
			return {
				name: org.name,
				type: 'organization',
				value: org.id,
			};
		});

	const handleOnChange = (e: Option[]) => {
		const ids = e.filter((item) => item.type === 'organization').map((item) => item.value);
		dispatch(
			setOrganizationIds({
				organizationIds: ids,
				organizationCities: undefined,
				organizationCountries: undefined,
			})
		);
	};

	const organizationOptions: Option[] = organizations.map((org) => {
		return {
			name: org.name,
			type: 'organization',
			value: org.id,
		};
	});

	// const locationOptions: Option[] = [...new Set(organizations.map((o) => o.time_zone))].map(
	// 	(item) => {
	// 		return {
	// 			name: item,
	// 			value: item,
	// 			type: 'time_zone',
	// 		};
	// 	}
	// );

	const options = [...organizationOptions];

	return (
		<div className={Styles.container}>
			<MultiSelect
				options={options}
				value={value}
				onChange={handleOnChange}
				placeholder="All organizations"
			/>
		</div>
	);
};
