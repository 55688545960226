import { Day, WorkingHoursValue } from './types';

export const DEFAULT_WORKING_HOURS: WorkingHoursValue = {
	monday: {
		start: {
			hours: 0,
			minutes: 0,
		},
		end: {
			hours: 0,
			minutes: 0,
		},
	},
	tuesday: {
		start: {
			hours: 0,
			minutes: 0,
		},
		end: {
			hours: 0,
			minutes: 0,
		},
	},
	wednesday: {
		start: {
			hours: 0,
			minutes: 0,
		},
		end: {
			hours: 0,
			minutes: 0,
		},
	},
	thursday: {
		start: {
			hours: 0,
			minutes: 0,
		},
		end: {
			hours: 0,
			minutes: 0,
		},
	},
	friday: {
		start: {
			hours: 0,
			minutes: 0,
		},
		end: {
			hours: 0,
			minutes: 0,
		},
	},
	saturday: {
		start: {
			hours: 0,
			minutes: 0,
		},
		end: {
			hours: 0,
			minutes: 0,
		},
	},
	sunday: {
		start: {
			hours: 0,
			minutes: 0,
		},
		end: {
			hours: 0,
			minutes: 0,
		},
	},
};

export const DAYS: Day[] = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
];
