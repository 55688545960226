import { useCallback, useContext, useEffect, useState } from 'react';
import Styles from './index.module.scss';
import { Context } from './Context';
import { SortingChevronIcon } from '@/components/Elements/Icons';

export const DraggableHeaderContent = () => {
	const [dropIndex, setDropIndex] = useState(-1);
	const {
		headers,
		setHeaders,
		parentRef,
		setSorting,
		setSortingOrder,
		sortingOrder,
		setExpandedItemIndex,
	} = useContext(Context);
	const [activeIndex, setActiveIndex] = useState<
		{ index: number; x: number; tableWidth: number } | undefined
	>(undefined);

	const mouseMove = useCallback(
		(e: MouseEvent) => {
			e.stopPropagation();

			const header = headers.find((_, i) => i === activeIndex?.index);

			if (header && header.ref && header.width) {
				if (activeIndex) {
					header.ref.style.width = `${header.width + (e.pageX - activeIndex.x)}px`;
				}
			}
		},
		[headers, activeIndex]
	);

	const removeListeners = useCallback(() => {
		window.removeEventListener('mousemove', mouseMove);
		window.removeEventListener('mouseup', removeListeners);
	}, [mouseMove]);

	const mouseUp = useCallback(() => {
		setActiveIndex(undefined);
		removeListeners();
	}, [setActiveIndex, removeListeners]);

	useEffect(() => {
		if (activeIndex !== null) {
			window.addEventListener('mousemove', mouseMove);
			window.addEventListener('mouseup', mouseUp);
		}

		return () => {
			removeListeners();
		};
	}, [activeIndex, mouseMove, mouseUp, removeListeners]);

	return (
		<tr>
			{headers.map((header, index) => {
				return (
					<th
						colSpan={Array.isArray(header.element) ? header.element.length : undefined}
						key={header.name}
						ref={(r) => {
							header.ref = r;
							header.width = r?.clientWidth ?? 0;
						}}
						style={{ width: header.width, minWidth: header.minWidth }}
					>
						<div
							className={Styles.headerCell}
							onClick={() => {
								if (header.sortable) {
									setSorting?.(header.key);
									setSortingOrder((prev) => {
										if (prev === 'asc') {
											return 'desc';
										}

										return 'asc';
									});
									setExpandedItemIndex(-1);
								}
							}}
						>
							<div
								className={`${Styles.name} ${dropIndex === index ? Styles.drop : ''}`}
								style={{
									justifyContent:
										header.align === 'left' ? 'flex-start' : 'center',
								}}
								draggable
								onDragOver={(e) => {
									e.preventDefault();
									setDropIndex(index);
								}}
								onDragStart={(e) => {
									e.dataTransfer.setData('columnIndex', `${index}`);
								}}
								onDrop={(e) => {
									e.preventDefault();
									setDropIndex(-1);

									const dragIndex = Number.parseInt(
										e.dataTransfer.getData('columnIndex')
									);

									if (dragIndex !== index) {
										const newHeaders = [...headers];
										const [draggedHeader] = newHeaders.splice(dragIndex, 1);
										newHeaders.splice(index, 0, draggedHeader);
										setHeaders(newHeaders);
									}
								}}
							>
								{header.name}
								{header.sortable && (
									<SortingChevronIcon
										className={sortingOrder === 'desc' ? Styles.desc : ''}
									/>
								)}
							</div>

							<div
								className={Styles.handle}
								onMouseDown={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setActiveIndex({
										index,
										x: e.clientX,
										tableWidth: parentRef?.current?.clientWidth ?? 0,
									});
								}}
							/>
						</div>
					</th>
				);
			})}
		</tr>
	);
};
