import { UserInfoFormValuesSchema } from '@/app/schemas/OrganizationFormValuesSchema';
import { UserInfoFormValues } from '@/app/types/OrganizationFormValues';
import { FormCreateOrganization } from '@/components/Organizations/FormCreateOrganization';
import { useOrganizationFormDataProvider } from '@/components/Organizations/FormOrganizationDataProvider';
import { userInfoDefaultValues } from '@/components/Organizations/FormOrganizationDataProvider/initialState';
import { FormSection } from '@/components/Organizations/FormSection';
import { FormUserInfo } from '@/components/Organizations/FormUserInfo';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

export const UserInfo = () => {
	const { formValues, setFormValues } = useOrganizationFormDataProvider();
	const [prevValues] = useState(formValues.userInfo);
	const onSubmit: SubmitHandler<UserInfoFormValues> = (data) => {
		setFormValues({ userInfo: data });
	};

	return (
		<FormCreateOrganization
			defaultValues={formValues.userInfo || userInfoDefaultValues}
			resolver={zodResolver(UserInfoFormValuesSchema)}
			onSubmit={onSubmit}
		>
			<FormSection
				mode={prevValues ? 'edit' : 'create'}
				urlParam="create"
				submitText="Next: Configure Settings"
				backUrl="/organizations/create/general-info"
				nextUrl="/organizations/create/configure-settings"
			>
				<FormUserInfo
					defaultUsersCount={formValues.userInfo?.users?.length ?? 0}
					organizationName={formValues.generalInfo?.availableHostName ?? ''}
				/>
			</FormSection>
		</FormCreateOrganization>
	);
};
