import Styles from './index.module.scss';
import { PageLayout } from '@/layouts/PageLayout';
import { OrganizationsTopBarFilter } from '@/components/OrganizationsTopBarFilter';
import { PeriodSelector } from '@/components/PeriodSelector';
import { IconButton } from '@/components/Elements/IconButton';
import { TemplatesIcon } from '@/components/Elements/Icons';
import { ButtonLink } from '@/components/Elements/ButtonLink';
import { useAppSelector } from '@/app/hooks/useAppSelector';
import { useAppDispatch } from '@/app/hooks/useAppDispatch';
import { selectPeriod } from 'slices/Filter';
interface Props {
	children?: React.ReactNode;
	title: string;
	url: string;
	urlTitle: string;
}

export const FilterPage = ({ children, title, url, urlTitle }: Props) => {
	const {
		timeType = 'daily',
		startTime = '',
		endTime = '',
	} = useAppSelector((state) => state.filter);

	const dispatch = useAppDispatch();

	return (
		<PageLayout title={title} filter={<OrganizationsTopBarFilter />}>
			<div className={Styles.container}>
				<div className={Styles.header}>
					<PeriodSelector
						value={{
							timeType,
							startTime,
							endTime,
						}}
						onChange={(value) => {
							dispatch(selectPeriod(value));
						}}
					/>
					<div className={Styles.menu}>
						<IconButton>
							<TemplatesIcon />
						</IconButton>
						<ButtonLink to={url}>{urlTitle}</ButtonLink>
					</div>
				</div>
				<div className={Styles.page}>{children}</div>
			</div>
		</PageLayout>
	);
};
