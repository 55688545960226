import { TableVirtuoso } from 'react-virtuoso';
import Styles from './index.module.scss';
import { useMemo, useState } from 'react';
import { TableRow } from './TableRow';
import { Table } from './Table';
import { DraggableHeaderContent } from './DraggableHeaderContent';
import { TableProvider } from './Context';
import { ExpandableItem, HeaderDefinition, Props } from './types';
import { SystemStatus } from '@/app/types/SystemStatus';
import { Item } from './Item';
import { DataLabel } from './DataLabel';
import { ConnectionStatus } from './ConnectionStatus';

export const SystemStatusTable = ({ data = [], endReached }: Props<SystemStatus>) => {
	const [expandedItemIndex, setExpandedItemIndex] = useState(-1);
	const [sorting, setSorting] = useState<string>('name');
	const [sortingOrder, setSortingOrder] = useState<'asc' | 'desc'>('asc');

	const columns: HeaderDefinition<SystemStatus>[] = [
		{
			name: 'name',
			key: 'name',
			id: 1,
			element: (item) => <DataLabel variant="bold">{item.name}</DataLabel>,
			align: 'left',
			sortable: true,
			width: 300,
			minWidth: 300,
		},
		{
			name: 'country',
			key: 'country',
			id: 2,
			align: 'left',
			element: (item) => (
				<DataLabel align="left" variant="light">
					{item.country}
				</DataLabel>
			),
		},
		{
			name: 'city',
			key: 'city',
			id: 3,
			element: (item) => <DataLabel>{item.city}</DataLabel>,
		},
		{
			name: 'device status',
			key: 'devices',
			id: 4,
			element: (item) => (
				<ConnectionStatus on={item.devices.connected} off={item.devices.disconnected} />
			),
		},
		{
			name: 'total devices',
			key: 'devices',
			id: 5,
			element: (item) => <DataLabel>{item.devices.total}</DataLabel>,
		},
		{
			name: 'user status',
			key: 'users',
			id: 6,
			element: (item) => <ConnectionStatus on={item.users.online} off={item.users.offline} />,
		},
		{
			name: 'total users',
			key: 'users',
			id: 7,
			element: (item) => <DataLabel>{item.users.total}</DataLabel>,
		},
	];

	const sortedItems = useMemo(() => {
		const items = [...data];
		return items.sort((a, b) => {
			if (sortingOrder === 'asc') {
				return `${a[sorting]}`.localeCompare(`${b[sorting]}`);
			}

			return `${b[sorting]}`.localeCompare(`${a[sorting]}`);
		});
	}, [sorting, sortingOrder, data]);

	const rows = useMemo((): ExpandableItem[] => {
		const sorted = sortedItems ?? [];
		const items: ExpandableItem[] = [];

		for (let index = 0; index < sorted.length; index++) {
			items.push({
				isExpanded: false,
				item: sorted[index],
			});

			if (index === expandedItemIndex) {
				items.push({
					isExpanded: true,
					item: sorted[index],
				});
			}
		}

		return items;
	}, [sortedItems, expandedItemIndex]);

	return (
		<TableProvider
			columns={columns}
			expandedItemIndex={expandedItemIndex}
			setExpandedItemIndex={setExpandedItemIndex}
			setSorting={setSorting}
			setSortingOrder={setSortingOrder}
			sortingOrder={sortingOrder}
		>
			<TableVirtuoso
				components={{
					Table,
					TableRow,
				}}
				endReached={endReached}
				className={Styles.container}
				data={rows}
				fixedHeaderContent={DraggableHeaderContent}
				itemContent={(_, dataItem) => {
					return <Item status={dataItem} />;
				}}
			/>
		</TableProvider>
	);
};
