import Styles from './index.module.scss';
import { SettingsImageIcon } from '@/components/Elements/Icons';
import { OrganizationNameField } from './OrganizationNameField';
import { UploadImageField } from './UploadImageField';
import { GeneralFormContext, GeneralFormProps } from './types';
import { ParentOrganizationField } from './ParentOrganizationField';
import { OrganizationTypeField } from './OrganizationTypeField';
import { OrganizationAddressField } from './OrganizationAddressField';
import { OrganizationTimeZoneField } from './OrganizationTimeZoneField';
import { OrganizationDetailsField } from './OrganiztionDetailsField';

export const FormGeneralInfo = (props: GeneralFormProps) => {
	return (
		<GeneralFormContext.Provider value={{ ...props }}>
			<div className={Styles.wrapper}>
				<h3>Create New Organization</h3>
				<div className={Styles.container}>
					<p>General Portal Settings</p>
					<OrganizationNameField />
					<ParentOrganizationField />
					<div className={`${Styles.fieldSet} ${Styles.fieldSetSingleLine}`}>
						<OrganizationTypeField />
						<OrganizationAddressField />
					</div>
					<div className={`${Styles.fieldSet} ${Styles.fieldSetSingleLine}`}>
						<OrganizationTimeZoneField />
					</div>
					<div className={Styles.fieldSet}>
						<SettingsImageIcon className={Styles.fieldIcon} />
						<span className={Styles.fieldTitle}>
							Company Logo
							<span className={Styles.fieldTitleInfo}> (for black background)</span>
						</span>
						<UploadImageField />
					</div>
					<OrganizationDetailsField />
				</div>
			</div>
		</GeneralFormContext.Provider>
	);
};
