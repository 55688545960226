import { Navigation } from '@/components/Elements/Navigation';
import { OrganizationsTable } from '@/components/Organizations/Table';
import { PortalPageLayout } from '@/layouts/PortalPageLayout';
import { useOrganizationsStatusQuery } from '@/app/hooks/useOrganizationsStatusQuery';
import { useState } from 'react';
import { TableSearchView } from '@/components/TableSearchView';
import { useAppSelector } from '@/app/hooks/useAppSelector';
import { Outlet } from 'react-router-dom';

export const Organizations = () => {
	const [organizationName, setOrganizationName] = useState('');
	const { organizationCities, organizationCountries, organizationIds } = useAppSelector(
		(state) => state.filter
	);

	const { data, isFetching } = useOrganizationsStatusQuery({
		organizationIds: organizationIds ?? [],
		organizationName,
		pageSize: 250,
		organizationCities,
		organizationCountries,
	});

	return (
		<>
			<PortalPageLayout
				title="Organizations"
				displayFilter={true}
				displayPeriodSelector={false}
				navigation={
					<Navigation>
						<Navigation.ButtonLink to={'create'}>
							New Organization
						</Navigation.ButtonLink>
					</Navigation>
				}
			>
				<TableSearchView placeholder="Search organizations" onSearch={setOrganizationName}>
					<OrganizationsTable isFetching={isFetching} data={data} />
				</TableSearchView>
			</PortalPageLayout>
			<Outlet />
		</>
	);
};
