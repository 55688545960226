import { User } from '@/app/types/User';
import Styles from '../index.module.scss';

interface Props {
	items: { selected: boolean; user: User }[];
	onSelected?: (e: { selected: boolean; user: User }) => void;
}

export const UsersList = ({ items = [], onSelected }: Props) => {
	return (
		<div className={Styles.list}>
			{items.map(({ user, selected }) => {
				return (
					<div
						key={user.id ?? ''}
						className={`${Styles.item} ${selected ? Styles.selected : ''}`}
						onClick={() => {
							onSelected?.({ user, selected });
						}}
					>
						<div className={Styles.avatar}>E</div>
						<span>{user.email}</span>
					</div>
				);
			})}
		</div>
	);
};
