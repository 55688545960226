import Styles from './index.module.scss';
import { CheckBox } from '../Elements/CheckBox';
import { InputSelect } from '../Elements/InputSelect';
import { Input } from '../Elements/Input';
import { UploadButton } from '../Elements/UploadButton';

interface Props {
	children?: React.ReactNode;
	title: string;
	icon?: React.ReactElement;
	subtitle?: string;
	variant?: 'default' | 'child';
	hasBorder?: boolean;
}

export const SettingsRowElement = ({
	title,
	children,
	icon,
	subtitle,
	variant = 'default',
	hasBorder = true,
}: Props) => {
	return (
		<div
			className={`${Styles.container} ${variant === 'child' ? Styles.child : ''} ${!hasBorder ? Styles.noBorder : ''}`}
		>
			{icon}
			<span>
				{title} {subtitle && <span>{subtitle}</span>}
			</span>
			{children}
		</div>
	);
};

const SelectItem = ({
	text,
	size,
	...props
}: React.ComponentPropsWithRef<typeof InputSelect> & {
	text?: string;
	size?: 'default' | 'medium';
}) => {
	return (
		<div className={`${Styles.select} ${size === 'medium' ? Styles.medium : ''}`}>
			{text && <span>{text}</span>}
			<InputSelect {...props} />
		</div>
	);
};

const SelectTimeItem = (props: React.ComponentPropsWithRef<typeof InputSelect>) => {
	return (
		<div className={Styles.timeSelect}>
			<InputSelect {...props} variant="time" />
		</div>
	);
};

const InputItem = ({
	variant = 'default',
	...props
}: React.ComponentPropsWithRef<typeof Input> & { variant?: 'default' | 'small' }) => {
	return (
		<div className={`${Styles.input} ${variant === 'small' ? Styles.small : ''}`}>
			<Input {...props} />
		</div>
	);
};

const SelectTimeGroupItem = ({
	index,
	...props
}: React.ComponentPropsWithRef<typeof InputSelect> & { index: number }) => {
	return (
		<div className={Styles.selectTimeGroupItem}>
			<div className={Styles.number}>{index}</div>
			<InputSelect {...props} variant="time" />
		</div>
	);
};

const UploadButtonItem = ({
	text,
	...props
}: React.ComponentPropsWithRef<typeof UploadButton> & { text: string }) => {
	return (
		<div className={Styles.uploadItem}>
			<span className={Styles.number}>{text}</span>
			<UploadButton {...props} />
		</div>
	);
};

const CheckBoxItem = (props: React.ComponentPropsWithRef<typeof CheckBox>) => {
	return <CheckBox {...props} />;
};

const Group = (props: React.ComponentPropsWithRef<'div'>) => {
	return <div {...props} className={Styles.group} />;
};

SettingsRowElement.SelectItem = SelectItem;
SettingsRowElement.CheckBoxItem = CheckBoxItem;
SettingsRowElement.SelectTimeItem = SelectTimeItem;
SettingsRowElement.Group = Group;
SettingsRowElement.SelectTimeGroupItem = SelectTimeGroupItem;
SettingsRowElement.InputItem = InputItem;
SettingsRowElement.UploadButtonItem = UploadButtonItem;
