import Styles from './index.module.scss';
import { FieldValues, FormProvider, SubmitHandler, UseFormProps, useForm } from 'react-hook-form';

interface Props<T extends FieldValues> extends UseFormProps<T> {
	children: React.ReactNode;
	onSubmit: SubmitHandler<T>;
}

export const FormCreateOrganization = <T extends FieldValues>({
	children,
	onSubmit,
	...props
}: Props<T>) => {
	const options = useForm<T>({ ...props });

	return (
		<FormProvider {...options}>
			<form onSubmit={options.handleSubmit(onSubmit)} className={Styles.container}>
				{children}
			</form>
		</FormProvider>
	);
};
