import Styles from './index.module.scss';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ContextMenu } from '../ContextMenu';
import { ContextMenuItem } from '../ContextMenuItem';
import { ChevronDownIcon } from '../Icons';

export interface InputSelectValue {
	value: string | number;
	text: string;
}

interface Props {
	placeholder?: string;
	value?: InputSelectValue['value'];
	onChange?: (event: InputSelectValue['value']) => void;
	items: InputSelectValue[];
	variant?: 'default' | 'time';
	measurement?: string;
	disabled?: boolean;
}

export const InputSelect = ({
	value,
	items,
	onChange,
	placeholder,
	variant = 'default',
	measurement = '',
	disabled = false,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef<HTMLButtonElement>(null);

	useLayoutEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [isOpen]);

	const itemsDictionary: { [key: string | number]: InputSelectValue } = [...(items ?? [])].reduce(
		(acc, item) => {
			acc[item.value] = item;
			return acc;
		},
		{}
	);

	const formattedValue = useMemo(() => {
		if (value === undefined) return placeholder;

		const item = itemsDictionary[value];
		if (item) {
			return `${item.text} ${measurement}`;
		}

		return '';
	}, [value, measurement, itemsDictionary, placeholder]);

	return (
		<>
			<button
				ref={ref}
				type="button"
				onClick={() => {
					setIsOpen(true);
				}}
				className={`${Styles.container} ${isOpen ? Styles.open : ''} ${variant === 'time' ? Styles.time : ''} ${disabled ? Styles.disabled : ''}`}
			>
				<span className={value === undefined ? Styles.placeholder : ''}>
					{formattedValue}
				</span>
				<ChevronDownIcon />
			</button>
			{isOpen && !disabled && (
				<ContextMenu
					alignment={variant === 'time' ? 'right' : 'left'}
					width={variant === 'time' ? 64 : 'auto'}
					parentRef={ref}
					onCloseRequested={() => {
						setIsOpen(false);
					}}
				>
					{items.map((item) => {
						return (
							<ContextMenuItem
								key={item.value}
								title={item.text}
								onClick={() => {
									onChange?.(item.value);
									setIsOpen(false);
								}}
							/>
						);
					})}
				</ContextMenu>
			)}
		</>
	);
};
