import Styles from './index.module.scss';

import { NotificationListItem } from '@/app/types/Notification';
import { formatInitials } from '@/utils/strings';
import { type MRT_Cell } from 'material-react-table';

export const SentByCell = (props: { cell: MRT_Cell<NotificationListItem, unknown> }) => {
	const item = props.cell.row.original;
	return (
		<div className={Styles.sentBy}>
			{item.sentBy && (
				<>
					<div className={Styles.avatar}>{formatInitials(item.sentBy)}</div>
					<span>{item.sentBy}</span>
				</>
			)}
		</div>
	);
};
