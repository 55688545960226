import moment from 'moment';
import { useLazySearchTasksQuery, useSearchTasksQuery } from '@/app/api/Tasks';
import { useAppSelector } from '@/app/hooks/useAppSelector';
import { Task } from '@/app/types/Task';
import { FilterStatusType } from 'types/TaskStatusFilter';
import { TaskViewModel } from '@/app/types/TaskViewModel';
import { parseTime } from '../utils/task/date';
import completedConfig from '@/app/utils/task/taskCompletedTime';
import scheduledConfig from '@/app/utils/task/taskScheduledTime';
import todayConfig from '@/app/utils/task/taskTodayTime';
import overdueConfig from '@/app/utils/task/taskOverdueTime';

export const parseTimeStamp = (time: string) => {
	return moment
		.parseZone(time, 'YYYY-MM-DDTHH:mm:ss.SSSSSSSZ')
		.utcOffset(moment(new Date()).utcOffset());
};

export const filterByStatus = (tasks: TaskViewModel[], filterStatus: FilterStatusType) => {
	if (filterStatus === 'all') {
		return tasks;
	}

	if (filterStatus === 'attention') {
		return tasks.filter((t) => t.status === 'all_rejected' || t.status === 'overdue');
	}

	return tasks.filter((t) => t.status === filterStatus);
};

export const useTasksList = (title: string, filterStatus: FilterStatusType) => {
	const filter = useAppSelector((state) => state.filter);

	const { data, isFetching } = useSearchTasksQuery({
		filter: {
			...filter,
			title,
		},
	});

	const [triggerLoadMore] = useLazySearchTasksQuery();

	const items = data?.items ?? [];
	const hasMore = data?.meta?.moreResults ?? false;
	const continuationToken = data?.meta?.continuation;

	const loadMore = async () => {
		if (hasMore && continuationToken && !isFetching) {
			await triggerLoadMore({
				filter: {
					...filter,
					title,
				},
				continuationToken,
			});
		}
	};

	const mapStartEndTime = (task: Task) => {
		const { start, end } = parseTime(task);
		switch (task.data.status) {
			case 'completed':
				return {
					start: completedConfig.formatStart(start),
					due: completedConfig.formatEnd(end),
				};
			case 'scheduled':
				return {
					start: scheduledConfig.formatStart(start),
					due: scheduledConfig.formatEnd(end),
				};
			case 'ongoing':
				return {
					start: todayConfig.formatStart(start),
					due: todayConfig.formatEnd(end),
				};

			default:
				return {
					start: overdueConfig.formatStart(start),
					due: overdueConfig.formatEnd(end),
				};
		}
	};

	const mapIndicator = (task: Task) => {
		switch (task.data.status) {
			case 'completed':
				return 'completed';
			case 'scheduled':
				return 'scheduled';
			case 'all_rejected':
			case 'overdue':
				return 'attention';
			default:
				return '';
		}
	};

	const tasks: TaskViewModel[] = items.map((t) => {
		return {
			title: t.content?.title?.text ?? '',
			indicatorVisible: filterStatus === 'all',
			indicator: mapIndicator(t),
			assignee: 'n/a',
			status: t.data?.status ?? '',
			...mapStartEndTime(t),
			progress:
				t.content?.checklist?.length ?? 0 > 0
					? {
							value:
								t.content?.checklist?.filter(
									(c) => c.is_checked === true && c.is_deleted === false
								).length ?? 0,
							max:
								t.content?.checklist?.filter((c) => c.is_deleted === false)
									.length ?? 0,
						}
					: undefined,
		};
	});

	return [filterByStatus(tasks, filterStatus), { loadMore }] as const;
};
