import React, { useState } from 'react';
import { HeaderDefinition } from './types';
import { TaskViewModel } from '@/app/types/TaskViewModel';

export type State = {
	headers: HeaderDefinition<TaskViewModel>[];
	setHeaders: React.Dispatch<React.SetStateAction<HeaderDefinition<TaskViewModel>[]>>;
};

export const Context = React.createContext<State>({} as State);

interface Props {
	columns: HeaderDefinition<TaskViewModel>[];
	children?: React.ReactNode;
}

export const TableProvider = ({ columns, children }: Props) => {
	const [headers, setHeaders] = useState<HeaderDefinition<TaskViewModel>[]>(columns);
	return <Context.Provider value={{ headers, setHeaders }}>{children}</Context.Provider>;
};
