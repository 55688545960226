import Styles from './index.module.scss';
import { useRef, useState } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';
import { MultiSelectProps } from './types';
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from 'components/Elements/Icons';

export const MultiSelect = ({
	value = [],
	options = [],
	placeholder,
	onChange,
}: MultiSelectProps) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const spanRef = useRef<HTMLInputElement>(null);
	const [visible, setVisible] = useState(false);
	const [search, setSearch] = useState('');

	const valueDictionary = value.reduce((acc, item) => {
		return { ...acc, [item.value]: item };
	}, {});

	const { ref: containerRef } = useClickOutside<HTMLDivElement>(() => {
		setVisible(false);
		setSearch('');
	});

	const filtered = options.filter(
		(item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1
	);

	return (
		<div className={Styles.wrapper} ref={containerRef}>
			<div
				className={Styles.container}
				onClick={() => {
					setVisible(true);
					if (inputRef.current) {
						inputRef.current.focus();
					}
				}}
			>
				<div className={Styles.inputArea}>
					{value.length === 0 && placeholder && !visible && (
						<div className={Styles.placeholder}>{placeholder}</div>
					)}

					{value.map((item, index) => {
						return (
							<span key={index} className={Styles.pill}>
								{item.name}
								<CloseIcon
									onClick={() => {
										onChange?.(value.filter((_, i) => index !== i));
									}}
								/>
							</span>
						);
					})}
					<div className={Styles.inputContainer}>
						<input
							value={search}
							ref={inputRef}
							onChange={(e) => {
								if (inputRef.current && spanRef.current) {
									setSearch(e.target.value);
									spanRef.current.innerText = e.target.value.replaceAll(' ', '|');
									inputRef.current.style.minWidth = `${spanRef.current.getBoundingClientRect().width}px`;
								}
							}}
							onKeyDown={(e) => {
								if (e.key === 'Backspace') {
									if (search.length === 0) {
										onChange?.(
											value.filter((_, index) => index !== value.length - 1)
										);
									}
								}
								if (e.key === 'Enter') {
									if (filtered.length > 0) {
										onChange?.([...value, filtered[0]]);
										setSearch('');
										inputRef.current?.focus();
									}
								}
							}}
						/>
					</div>
				</div>
				<div className={Styles.separator} />
				<div className={Styles.button}>
					{visible ? (
						<ChevronUpIcon className={Styles.chevron} />
					) : (
						<ChevronDownIcon className={Styles.chevron} />
					)}
				</div>
			</div>
			<span ref={spanRef} className={Styles.sizeManager} />
			{visible && (
				<div className={Styles.popup}>
					<div className={Styles.items}>
						{filtered.map((item, index) => {
							const isSelected = valueDictionary[item.value] !== undefined;
							return (
								<div
									key={index}
									className={`${Styles.item} ${value.some((i) => i.value === item.value) ? Styles.selected : ''}`}
									onClick={() => {
										if (isSelected) {
											onChange?.(value.filter((i) => i.value !== item.value));
										} else {
											onChange?.([...value, item]);
										}
										setSearch('');
										inputRef.current?.focus();
									}}
								>
									<span>{item.name}</span>
									<span>{isSelected ? '-' : '+'}</span>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};
