import React, { useEffect, useRef } from 'react';
import Styles from '../index.module.scss';
import { Button } from '@/components/Elements/Button';
import { DeleteModal } from '@/components/Modals/DeleteModal';
import { ComponentPropsWithRef } from 'react';
import { useFormState } from 'react-hook-form';

export const ConfirmSubmit = ({ ...props }: ComponentPropsWithRef<typeof Button>) => {
	const [visible, setVisible] = React.useState(false);
	const { isSubmitting, isSubmitted } = useFormState();

	const ref = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (!isSubmitting && isSubmitted) {
			setVisible(false);
		}
	}, [isSubmitting, isSubmitted]);

	return (
		<>
			{visible && (
				<DeleteModal
					excluded={ref.current ? [ref.current] : undefined}
					preventClose={isSubmitting}
					isLoading={isSubmitting}
					cancelText="Cancel"
					confirmText="Save"
					title="Are you sure you wish to save the changes?"
					onClick={() => {
						ref.current?.click();
					}}
					onCloseRequest={() => {
						setVisible(false);
					}}
				/>
			)}
			<Button
				{...props}
				disabled={isSubmitting}
				type="button"
				onClick={() => {
					setVisible(true);
				}}
			></Button>
			<button className={Styles.hidden} ref={ref} type="submit">
				Submit me
			</button>
		</>
	);
};
