import { withDisable } from '@/components/Elements/DisableLink';
import Styles from '../index.module.scss';
import { Button } from '@/components/Elements/Button';
import { useContext } from 'react';
import { FormSectionContext } from '../types';
import { useFormContext, useFormState } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { ConfirmSubmit } from './ConfirmSubmit';

const DisableMavLink = withDisable(NavLink);

export const Footer = () => {
	const { mode, submitText, nextUrl } = useContext(FormSectionContext);
	const { isSubmitted, isValid, isSubmitting, isDirty } = useFormState();
	const { reset } = useFormContext();

	return (
		<div className={Styles.footer}>
			<div className={Styles.navigation}>
				{mode === 'create' && (
					<Button
						key={'default submit'}
						disabled={!isValid || isSubmitting}
						type={'submit'}
					>
						{submitText ?? 'Submit'}
					</Button>
				)}
				{mode === 'create-confirmation' && <ConfirmSubmit>{submitText}</ConfirmSubmit>}
				{mode === 'edit' && (
					<>
						{isDirty && !isSubmitted && (
							<Button
								className={Styles.secondary}
								key={'cancel changes'}
								type={'button'}
								onClick={() => {
									reset();
								}}
							>
								Cancel all changes
							</Button>
						)}
						{nextUrl && (
							<DisableMavLink
								to={nextUrl}
								disabled={!isSubmitted && (isDirty || !isValid)}
								replace
							>
								{submitText}
							</DisableMavLink>
						)}
						{isDirty && !isSubmitted && (
							<Button key={'submit changes'} disabled={!isValid} type={'submit'}>
								Save
							</Button>
						)}
					</>
				)}
			</div>
		</div>
	);
};
