import { UploadButton } from '@/components/Elements/UploadButton';
import Styles from './index.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { GeneralInfoFormValues } from '@/app/types/OrganizationFormValues';
import { resizeAndConvertFile } from '@/app/utils/images';

export const UploadImageField = () => {
	const { control } = useFormContext<GeneralInfoFormValues>();
	return (
		<Controller
			name="organizationLogo"
			control={control}
			render={({ field }) => {
				return (
					<div className={Styles.fieldFlex}>
						<span className={Styles.fieldTitleFade}>Upload logo from computer</span>
						{field.value && (
							<img className={Styles.logoImage} src={field.value} alt="placeholder" />
						)}
						<UploadButton
							onChange={async (e) => {
								try {
									if (!e) {
										return;
									}
									const base64Image = await resizeAndConvertFile(e, 146, 36);
									field.onChange(base64Image);
								} catch {}
							}}
						/>
					</div>
				);
			}}
		></Controller>
	);
};
