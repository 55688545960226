import {
	SettingsCheckSmallIcon,
	SettingsMessageSquareIcon,
	SettingsSunIcon,
} from '@/components/Elements/Icons';
import { SettingsView } from '@/components/SettingsView';
import { AutoForwardField } from './AutoForwardField';
import { GroupField } from './GroupField';
import { Field } from './Field';
import { IncomingMessageDurationField } from './IncomingMessageDurationField';

export const FormActions = () => {
	return (
		<SettingsView title="Actions.">
			<SettingsView.Section title="">
				<AutoForwardField />
				<GroupField
					title="Send Feedback"
					name="actions.sendFeedback"
					icon={<SettingsMessageSquareIcon />}
				>
					<Field
						variant="child"
						title="Acceptance Feedback"
						placeholder="Acceptance Message"
						name="actions.acceptanceFeedback"
					/>
					<Field
						variant="child"
						title="Rejection Feedback"
						placeholder="Rejection Message"
						name="actions.rejectionFeedback"
					/>
				</GroupField>
				<GroupField
					title="Accepted by colleague custom message"
					name="actions.acceptedByMessage"
					icon={<SettingsMessageSquareIcon />}
				>
					<Field
						variant="child"
						title="Title"
						placeholder="Acceptance Message"
						name="actions.acceptedByMessageTitle"
						hasCheckBox={false}
					/>
					<Field
						variant="child"
						title="Body"
						placeholder="Rejection Message"
						name="actions.acceptedByMessageBody"
						hasCheckBox={false}
					/>
				</GroupField>
				<GroupField
					icon={<SettingsCheckSmallIcon />}
					title="Completion Confirmation"
					name="actions.completionConfirmation"
				/>
				<GroupField
					icon={<SettingsSunIcon />}
					title="Use Splash Screens for incoming"
					subtitle="(Watch)"
					name="actions.useSplashScreen"
				>
					<IncomingMessageDurationField />
				</GroupField>
			</SettingsView.Section>
		</SettingsView>
	);
};
