import { createContext } from 'react';

export interface GeneralFormProps {
	timeZones: { text: string; value: string }[];
	parentOrganizations: { text: string; value: string }[];
	isCheckingHostName?: boolean;
	availableHostName?: string;
	isHostNameAvailable?: boolean;
	organizationNameChanged: (e: string) => void;
}

export const GeneralFormContext = createContext<GeneralFormProps>({} as GeneralFormProps);
