import React, { Dispatch, RefObject, SetStateAction, useRef, useState } from 'react';
import { HeaderDefinition } from './types';
import { SystemStatus } from '@/app/types/SystemStatus';

export type State = {
	headers: HeaderDefinition<SystemStatus>[];
	setHeaders: React.Dispatch<React.SetStateAction<HeaderDefinition<SystemStatus>[]>>;
	parentRef: RefObject<HTMLTableElement>;
	expandedItemIndex: number;
	setExpandedItemIndex: (e: number) => void;
	setSorting?: (e: string) => void;
	sortingOrder: 'asc' | 'desc';
	setSortingOrder: Dispatch<SetStateAction<'asc' | 'desc'>>;
};

export const Context = React.createContext<State>({} as State);

interface Props {
	columns: HeaderDefinition<SystemStatus>[];
	children?: React.ReactNode;
	expandedItemIndex: number;
	setExpandedItemIndex: (e: number) => void;
	setSorting?: (e: string) => void;
	sortingOrder: 'asc' | 'desc';
	setSortingOrder: Dispatch<SetStateAction<'asc' | 'desc'>>;
}

export const TableProvider = ({
	columns,
	children,
	expandedItemIndex,
	setExpandedItemIndex,
	setSorting,
	setSortingOrder,
	sortingOrder,
}: Props) => {
	const parentRef = useRef<HTMLTableElement>(null);
	const [headers, setHeaders] = useState<HeaderDefinition<SystemStatus>[]>(columns);

	return (
		<Context.Provider
			value={{
				headers,
				setHeaders,
				parentRef,
				expandedItemIndex,
				setExpandedItemIndex,
				setSorting,
				sortingOrder,
				setSortingOrder,
			}}
		>
			{children}
		</Context.Provider>
	);
};
