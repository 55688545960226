import Styles from './index.module.scss';
import { ChevronDownIcon } from '@/components/Elements/Icons';
import { useOrganizations } from '@/app/hooks/useOrganizations';
import { User } from '@/app/types/User';
import { useUsersList } from './Data/useUsersList';
import { Filter } from './Components/Filter';
import { UsersList } from './Components/UsersList';

interface Props {
	value: User[];
	onChange: (e: User[]) => void;
}

export const UsersPicker = ({ value, onChange }: Props) => {
	const { items, filter, setFilter, handleChange } = useUsersList(value, onChange);
	const { organizations } = useOrganizations();

	return (
		<div className={Styles.container}>
			<h1>
				<ChevronDownIcon />
				Assign Users<span>.</span>
			</h1>
			<div className={Styles.listContainer}>
				<span className={Styles.title}>Select Organization</span>
				<Filter organizations={organizations} value={filter} onChange={setFilter} />
				<UsersList items={items} onSelected={handleChange} />
			</div>
		</div>
	);
};
