import { SettingsLayersIcon } from '@/components/Elements/Icons';
import Styles from './index.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { InputSelect } from '@/components/Elements/InputSelect';
import { useContext } from 'react';
import { GeneralFormContext } from './types';

export const ParentOrganizationField = () => {
	const { control } = useFormContext();
	const { parentOrganizations } = useContext(GeneralFormContext);

	return (
		<div className={Styles.fieldSet}>
			<SettingsLayersIcon className={Styles.fieldIcon} />
			<span className={Styles.fieldTitle}>
				Parent Organization<span>*</span>
			</span>
			<div className={Styles.fieldFlex}>
				<Controller
					name="parentOrganization"
					control={control}
					render={({ field }) => {
						return (
							<InputSelect
								value={field.value}
								onChange={field.onChange}
								placeholder="Choose parent organization"
								items={parentOrganizations}
							/>
						);
					}}
				/>
			</div>
		</div>
	);
};
