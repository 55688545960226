import { NotificationListItem } from '@/app/types/Notification';
import {
	type MRT_Cell,
	type MRT_ColumnDef,
	type MRT_RowVirtualizer,
	MaterialReactTable,
	useMaterialReactTable,
} from 'material-react-table';
import { SVGProps, useCallback, useMemo, useRef } from 'react';
import { SentByCell } from './SentByCell';
import { OrganizationCell } from './OrganizationCell';
import { TitleCell } from './TitleCell';
import { DetailsCell } from './DetailsCell';
import { CountCell } from './CountCell';
import { ChevronDownIcon, EyeIcon, ShareIcon } from '@/components/Elements/Icons';
import { JSX } from 'react/jsx-runtime';
import { RowDetails } from './RowDetails';
import { TableStyles } from './TableStyles';
import { TableOptions } from './TableOptions';
import { ActionMenu } from './ActionMenu';

interface NotificationListProps {
	data: NotificationListItem[];
	isFetching: boolean;
}

export const NotificationsTable = ({ data, isFetching }: NotificationListProps) => {
	const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);
	const columns = useMemo<MRT_ColumnDef<NotificationListItem>[]>(
		() => [
			{
				accessorFn(originalRow) {
					return originalRow.title;
				},
				id: 'title',
				header: 'TITLE',
				enableHiding: false,
				sortDescFirst: true,
				Cell: TitleCell,
				grow: 1,
			},
			{
				accessorFn: (originalRow) => originalRow.sentBy ?? '',
				id: 'sent-by',
				header: 'SENT BY',
				Cell: SentByCell,
				enableSorting: false,
			},
			{
				accessorFn: (originalRow) => originalRow.organizationName ?? '',
				header: 'ORGANIZATION',
				id: 'organization',
				Cell: OrganizationCell,
				enableSorting: false,
			},
			{
				accessorFn: (originalRow) => originalRow.title ?? '',
				header: 'DETAILS',
				id: 'details',
				Cell: DetailsCell,
				enableSorting: false,
			},
			{
				accessorFn: (originalRow) => originalRow.sentTo ?? '',
				header: 'SENT TO',
				id: 'sent-to',
				Cell: ({ cell }: { cell: MRT_Cell<NotificationListItem, unknown> }) => (
					<CountCell value={cell.row.original.sentTo} icon={<ShareIcon />} />
				),
				enableSorting: false,
			},
			{
				accessorFn: (originalRow) => originalRow.readBy ?? '',
				header: 'READ BY',
				id: 'read-by',
				Cell: ({ cell }: { cell: MRT_Cell<NotificationListItem, unknown> }) => (
					<CountCell value={cell.row.original.readBy} icon={<EyeIcon />} />
				),
				enableSorting: false,
			},
		],
		[]
	);
	const fetchMoreOnBottomReached = useCallback(
		(containerRefElement?: HTMLDivElement | null) => {
			if (containerRefElement) {
				const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
				//once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
				if (scrollHeight - scrollTop - clientHeight < 400 && !isFetching) {
				}
			}
		},
		[isFetching]
	);
	const table = useMaterialReactTable({
		...TableStyles,
		...TableOptions,
		...ActionMenu,
		rowVirtualizerInstanceRef,
		columns,
		data,
		state: {
			isLoading: isFetching,
			showSkeletons: isFetching,
		},
		initialState: {
			sorting: [
				{
					id: 'title',
					desc: false,
				},
			],
		},
		icons: {
			ArrowDownwardIcon: (
				props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement> & { title?: string }
			) => <ChevronDownIcon {...props} width={8} height={8} />,
			// ExpandMoreIcon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
			// 	return <ChevronDownIcon {...props} width={12} height={12} />;
			// },
		},
		muiTableContainerProps: {
			sx: {
				maxHeight: '100%',
			},
			onScroll: (event: React.UIEvent<HTMLDivElement>) =>
				fetchMoreOnBottomReached(event.target as HTMLDivElement),
		},
		renderDetailPanel: RowDetails,
	});
	return <MaterialReactTable table={table} />;
};
