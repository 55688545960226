import React from 'react';
import Styles from './index.module.scss';
import { DeleteIcon } from '@/components/Elements/Icons';
import { formatInitials } from '@/utils/strings';

interface Props {
	items: { id: string; name: string; color: string }[];
	onChange?: (index: number, data: { name: string; color: string }) => void;
}

export const ActivitiesList = ({ items, onChange }: Props) => {
	return (
		<div className={Styles.container}>
			<span className={Styles.header}>Name</span>
			{items.map((field, index) => {
				return (
					<React.Fragment key={field.id}>
						<div className={Styles.title}>
							<div className={Styles.avatar} style={{ background: field.color }}>
								<span>{formatInitials(field.name)}</span>
							</div>
							<span>{field.name}</span>
						</div>
						<div
							className={Styles.delete}
							onClick={() => {
								onChange?.(index, { name: field.name, color: field.color });
							}}
						>
							<DeleteIcon />
						</div>
					</React.Fragment>
				);
			})}
		</div>
	);
};
