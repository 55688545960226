import { SettingsStarIcon } from '@/components/Elements/Icons';
import Styles from './index.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { GeneralInfoFormValues } from '@/app/types/OrganizationFormValues';
import { Input } from '@/components/Elements/Input';
import { Button } from '@/components/Elements/Button';
import { useContext } from 'react';

import { GeneralFormContext } from './types';

export const OrganizationNameField = () => {
	const { control, setValue } = useFormContext<GeneralInfoFormValues>();
	const { isCheckingHostName, isHostNameAvailable, availableHostName, organizationNameChanged } =
		useContext(GeneralFormContext);

	return (
		<div className={Styles.fieldSet}>
			<SettingsStarIcon className={Styles.fieldIcon} />
			<span className={Styles.fieldTitle}>
				Organization Name<span>*</span>
			</span>
			<div className={Styles.fieldFlex}>
				<>
					{!isCheckingHostName && (!isHostNameAvailable || !availableHostName) && (
						<span className={Styles.fieldErrorTitle}>
							Organization Name already exist! Try different name.
						</span>
					)}
				</>
			</div>
			<div className={Styles.fieldFlex}>
				<Controller
					name="organisationName"
					control={control}
					render={({ field }) => {
						return (
							<Input
								value={field.value}
								onChange={field.onChange}
								placeholder="Enter Organization Name"
								onDebounceChange={(e) => {
									organizationNameChanged?.(e as string);
								}}
							/>
						);
					}}
				/>
				<Button
					onClick={() => {
						setValue('ownerFirstName', 'Owner');
						setValue('ownerLastName', 'Admin');
						setValue('ownerEmail', `owner@${availableHostName ?? ''}`);
						setValue('availableHostName', availableHostName);
					}}
					className={Styles.fieldButton}
					disabled={isCheckingHostName || !isHostNameAvailable || !availableHostName}
				>
					Populate
				</Button>
			</div>
		</div>
	);
};
