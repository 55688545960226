import Styles from './index.module.scss';
import { NotificationListItem } from '@/app/types/Notification';
import { type MRT_Cell } from 'material-react-table';

export const TitleCell = (props: { cell: MRT_Cell<NotificationListItem, unknown> }) => {
	const item = props.cell.row.original;
	const isExpanded = props.cell.row.getIsExpanded();

	return (
		<div className={Styles.title}>
			<p>{item.title}</p>
			<span style={{ opacity: isExpanded ? 0 : 1 }}>{item.body}</span>
		</div>
	);
};
