import { ActivitiesForm } from './Form';
import { ActivitiesList } from './List';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SettingsView } from '@/components/SettingsView';
import { SettingsFormValues } from '@/app/types/OrganizationFormValues';

export const FormShiftPlannerActivities = () => {
	const { control } = useFormContext<SettingsFormValues>();
	const { insert, fields, remove } = useFieldArray({
		control,
		name: 'shiftPlanner.activities',
	});

	return (
		<SettingsView title="Activities.">
			<SettingsView.Section title="Add New Activity">
				<ActivitiesForm onChange={insert} />
			</SettingsView.Section>
			<SettingsView.Section title="List of Activities that will be created in organization">
				<ActivitiesList items={fields} onChange={remove} />
			</SettingsView.Section>
		</SettingsView>
	);
};
