import { Navigation } from './Navigation';
import { DropdownNavigation } from './DropdownNavigation';
import { Logo } from './Logo';
import { Expander } from './Expander';

export const Sidebar = () => {
	return (
		<Expander>
			<Logo />
			<Navigation />
			<DropdownNavigation />
		</Expander>
	);
};
