import { TableProps } from 'react-virtuoso';

export const Table = ({ style, ...props }: TableProps) => (
	<table
		{...props}
		style={{
			...style,
			border: 0,
			borderWidth: 0,
			width: '100%',
			tableLayout: 'fixed',
			overflow: 'scroll',
		}}
	/>
);
