import Styles from './index.module.scss';
import { PageLayout } from '../../layouts/PageLayout';
import { OrganizationsTopBarFilter } from '../OrganizationsTopBarFilter';

interface Props {
	title: string;
	children?: React.ReactNode;
	className?: string;
}

export const FilterPageLayout = ({
	title,
	children = <h1>{`</> Coming Soon`}</h1>,
	className,
}: Props) => {
	return (
		<PageLayout title={title} filter={<OrganizationsTopBarFilter />}>
			<div className={`${Styles.container} ${className ?? ''}`}>
				<div className={Styles.page}>{children}</div>
			</div>
		</PageLayout>
	);
};
