import Styles from './index.module.scss';
import { FilterPage } from '@/layouts/FilterPage';
import { useState } from 'react';
import { useDebounceInputHandler } from '@/app/hooks/useDebounceInputHandler';
import { SearchInput } from '@/components/Elements/SearchInput';
import { FilterStatusType } from '@/app/types/TaskStatusFilter';
import { useTasksList } from '@/app/hooks/useTaskList';
import { TaskStatusFilter, TaskTable } from '@/components/Task';

export const List = () => {
	const [title, setTitle] = useState('');
	const [status, setStatus] = useState<FilterStatusType>('all');
	const [data, { loadMore }] = useTasksList(title, status);
	const handler = useDebounceInputHandler(setTitle);

	return (
		<FilterPage title="Tasks" url="/tasks/new" urlTitle="New Task">
			<div className={Styles.container}>
				<div className={Styles.filter}>
					<SearchInput placeholder="Search tasks" onChange={handler} />
					<TaskStatusFilter value={status} onChange={setStatus} />
				</div>
				<TaskTable data={data} endReached={loadMore} />
			</div>
		</FilterPage>
	);
};
