import Styles from './index.module.scss';
import { useClickOutside } from '@/app/hooks/useClickOutside';

interface Props {
	onClickOutside: () => void;
	children?: React.ReactNode;
}

export const Popup = ({ onClickOutside, children }: Props) => {
	const { ref } = useClickOutside<HTMLDivElement>(onClickOutside);
	return (
		<div ref={ref} className={Styles.popup}>
			{children}
		</div>
	);
};
