import { SettingsRowElement } from '@/components/SettingsRowElement';
import { ComponentPropsWithRef } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

interface Props extends ComponentPropsWithRef<typeof SettingsRowElement> {
	name: string;
	placeholder?: string;
	hasCheckBox?: boolean;
}

export const Field = ({ name, placeholder, hasCheckBox = true, ...props }: Props) => {
	const { control } = useFormContext();
	const active = useWatch({ name: `${name}.active` });

	return (
		<SettingsRowElement {...props}>
			<Controller
				name={hasCheckBox ? `${name}.value` : name}
				control={control}
				render={({ field }) => {
					return (
						<SettingsRowElement.InputItem
							value={field.value}
							onChange={field.onChange}
							placeholder={placeholder}
							disabled={!hasCheckBox ? false : !active}
						/>
					);
				}}
			/>
			{hasCheckBox && (
				<Controller
					name={`${name}.active`}
					control={control}
					render={({ field }) => {
						return (
							<SettingsRowElement.CheckBoxItem
								value={field.value}
								onChange={field.onChange}
							/>
						);
					}}
				/>
			)}
		</SettingsRowElement>
	);
};
