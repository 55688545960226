import Styles from './index.module.scss';
import { ButtonSelectProps } from './types';

export const ButtonsSelect = ({ value, options, onChange }: ButtonSelectProps) => {
	return (
		<div className={Styles.container}>
			{options.map((option) => {
				return (
					<button
						key={option.value}
						type="button"
						className={value === option.value ? Styles.selected : ''}
						onClick={() => {
							onChange?.(option.value);
						}}
					>
						<div className={Styles.pointer} />
						{option.name}
					</button>
				);
			})}
		</div>
	);
};
