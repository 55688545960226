import { useAppDispatch } from '@/app/hooks/useAppDispatch';
import { useAppSelector } from '@/app/hooks/useAppSelector';
import { useOrganizations } from '@/app/hooks/useOrganizations';
import { setOrganizationIds } from '@/app/slices/Filter';
import { Option } from '@/components/Elements/MultiSelect/types';

export const useTopBarFilter = () => {
	const { organizations } = useOrganizations();

	const cities: string[] = organizations
		.map((org) => org.street_address?.city ?? '')
		.filter((city) => city !== '')
		.filter((item, index, self) => self.indexOf(item) === index);

	const countries: string[] = organizations
		.map((org) => org.street_address?.country ?? '')
		.filter((country) => country !== '')
		.filter((item, index, self) => self.indexOf(item) === index);

	const dispatch = useAppDispatch();

	const selectedOrganizationsIds = useAppSelector((state) => state.filter.organizationIds);
	const selectedOrganizationCities = useAppSelector((state) => state.filter.organizationCities);
	const selectedOrganizationCountries = useAppSelector(
		(state) => state.filter.organizationCountries
	);

	const value = [
		...organizations
			.filter((item) => selectedOrganizationsIds?.some((id) => id === item.id))
			.map((item) => {
				return {
					name: item.name,
					type: 'organization',
					value: item.id,
				};
			}),
		...cities
			.filter((item) => selectedOrganizationCities?.some((city) => city === item))
			.map((item) => {
				return {
					name: item,
					type: 'city',
					value: item,
				};
			}),
		...countries
			.filter((item) => selectedOrganizationCountries?.some((city) => city === item))
			.map((item) => {
				return {
					name: item,
					type: 'country',
					value: item,
				};
			}),
	];

	const handleOnChange = (e: Option[]) => {
		const organizationIds = e
			.filter((item) => item.type === 'organization')
			.map((item) => item.value);

		const organizationCountries = e
			.filter((item) => item.type === 'country')
			.map((item) => item.value);

		const organizationCities = e
			.filter((item) => item.type === 'city')
			.map((item) => item.value);

		dispatch(
			setOrganizationIds({
				organizationIds: (organizationIds ?? []).length > 0 ? organizationIds : undefined,
				organizationCities:
					(organizationCities ?? []).length > 0 ? organizationCities : undefined,
				organizationCountries:
					(organizationCountries ?? []).length > 0 ? organizationCountries : undefined,
			})
		);
	};

	const citiesOptions = cities.map((city) => {
		return {
			name: city,
			type: 'city',
			value: city,
		};
	});

	const countriesOptions = countries.map((country) => {
		return {
			name: country,
			type: 'country',
			value: country,
		};
	});

	// const streetsOptions = streets.map((street) => {
	// 	return {
	// 		name: street,
	// 		type: 'street',
	// 		value: street,
	// 	};
	// });

	const organizationOptions: Option[] = organizations.map((org) => {
		return {
			name: org.name,
			type: 'organization',
			value: org.id,
		};
	});

	const options = [
		...organizationOptions,
		...citiesOptions,
		...countriesOptions,
		// ...streetsOptions,
	];

	return [options, value, handleOnChange] as const;
};
