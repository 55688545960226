import { convertToTimeFromPeriod } from '@/app/utils/period';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FilterTimeType, MessageContainerFilter } from 'types/Filter';

export const initialState: MessageContainerFilter = {
	pageSize: 100,
	organizationIds: [],
	timeType: 'daily',
	...convertToTimeFromPeriod('daily'),
};

export const filterSlice = createSlice({
	name: 'filter',
	initialState,
	reducers: {
		setOrganizationIds: (
			state,
			action: PayloadAction<{
				organizationIds: string[] | undefined;
				organizationCities: string[] | undefined;
				organizationCountries: string[] | undefined;
			}>
		) => {
			state.organizationIds = action.payload.organizationIds;
			state.organizationCities = action.payload.organizationCities;
			state.organizationCountries = action.payload.organizationCountries;
		},
		selectPeriod: (
			state,
			action: PayloadAction<{ startTime: string; endTime: string; timeType: FilterTimeType }>
		) => {
			const { startTime, endTime, timeType } = action.payload;
			state.startTime = startTime;
			state.endTime = endTime;
			state.timeType = timeType;
		},
	},
});

export const { setOrganizationIds, selectPeriod } = filterSlice.actions;

export default filterSlice.reducer;
