import React from 'react';
import Styles from './Container.module.scss';

interface Props extends React.ComponentPropsWithRef<'div'> {
	className?: string;
}

const Container = ({ className, ...props }: Props) => {
	return <div {...props} className={`${Styles.container} ${className ?? ''}`} />;
};

export default Container;
