export const createEmailFromOrganizationName = (organizationName: string) => {
	if (!organizationName) {
		return '';
	}

	try {
		return `${organizationName.replace(/\s/g, '').toLowerCase()}.com`;
	} catch {
		return '';
	}
};
