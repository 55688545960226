import { setupListeners } from '@reduxjs/toolkit/query';
import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './api/api';
import { filterSlice } from './slices/Filter';
import { notificationFilterSlice } from './slices/NotificationFilter';
import { rtkQueryErrorLogger } from './middleware/rtkQueryErrorHandler';

export const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		[filterSlice.reducerPath]: filterSlice.reducer,
		[notificationFilterSlice.reducerPath]: notificationFilterSlice.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(
			apiSlice.middleware,
			rtkQueryErrorLogger
		),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
