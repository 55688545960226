import Styles from './index.module.scss';
import { NotificationListItem } from '@/app/types/Notification';
import { ShareIcon } from '@/components/Elements/Icons';
import { type MRT_Cell } from 'material-react-table';

export const DetailsCell = (props: { cell: MRT_Cell<NotificationListItem, unknown> }) => {
	const item = props.cell.row.original;

	return (
		<div className={Styles.details}>
			<ShareIcon />
			<p>{item.details}</p>
		</div>
	);
};
