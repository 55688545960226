import Styles from './index.module.scss';
import moment from 'moment';
import { Calendar } from '../Calendar';
import { IconButton } from '../IconButton';
import { CalendarIcon } from '../Icons';
import { Props } from './types';
import { useMemo, useState } from 'react';
import { Popup } from './Popup';
import { useValueHelpers } from './hooks';
import { Switch } from './Switch';

export const CalendarPicker = ({ value, onChange }: Props) => {
	const startTime = moment(value?.startTime ?? moment().startOf('day'));
	const endTime = moment(value?.endTime ?? moment().endOf('day'));
	const [visible, setVisible] = useState(false);
	const switchItems = useMemo(() => {
		return [
			{ text: startTime.format('ddd, DD MMM YYYY'), value: 'start' },
			{ text: endTime.format('ddd, DD MMM YYYY'), value: 'end' },
		];
	}, [startTime, endTime]);

	const [activeSwitch, setActiveSwitch] = useState<string>('start');
	const updateValue = useValueHelpers(activeSwitch, value);

	if (value?.timeType === 'monthly') {
		return <></>;
	}

	return (
		<div className={Styles.container}>
			<IconButton onClick={() => setVisible(true)}>
				<CalendarIcon />
			</IconButton>
			{visible && (
				<Popup
					onClickOutside={() => {
						setVisible(false);
					}}
				>
					{value?.timeType === 'custom' && (
						<Switch
							value={activeSwitch}
							items={switchItems}
							onChange={setActiveSwitch}
						/>
					)}
					<Calendar
						selectionMode={value?.timeType === 'weekly' ? 'row' : 'custom'}
						value={{ start: startTime.toDate(), end: endTime.toDate() }}
						onChange={(e) => {
							const args = updateValue(e);
							onChange?.(args);
							if (value?.timeType === 'custom' && activeSwitch === 'start') {
								setActiveSwitch('end');
							}
						}}
					/>
				</Popup>
			)}
		</div>
	);
};
