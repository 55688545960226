import Styles from './index.module.scss';
import { Logo as LogoIcon } from 'components/Elements/Icons';

export const Logo = () => {
	return (
		<div className={Styles.header}>
			<div className={Styles.logo}>
				<LogoIcon />
			</div>
			<span className={Styles.text}>Turnpike</span>
		</div>
	);
};
