import Resizer from 'react-image-file-resizer';

export const createImageFromFile = (file: File) => {
	try {
		return URL.createObjectURL(file);
	} catch {
		return null;
	}
};

export const resizeAndConvertFile = (
	file: File,
	maxWidth: number,
	maxHeight: number
): Promise<string> =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			maxWidth,
			maxHeight,
			'PNG',
			100,
			0,
			(uri) => {
				resolve(uri as string);
			},
			'base64'
		);
	});
