import { ItemProps } from 'react-virtuoso';
import { ExpandableItem } from './types';

export const TableRow = ({ ...props }: ItemProps<ExpandableItem>) => {
	return <tr {...props} />;
};

// TODO - Finish Expandable Items

// onClick={() => {
// 	setRows?.((prev) => {
// 		const newArray = [...prev].filter((i) => i.type === 'collapsed');

// 		newArray.splice(props['data-index'], 0, {
// 			item: {
// 				name: 'test',
// 				description: 'test',
// 			},
// 			type: 'expanded',
// 		});

// 		return [...newArray];
// 	});
// }}
