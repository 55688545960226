import {
	addMonths,
	eachDayOfInterval,
	endOfDay,
	endOfMonth,
	endOfWeek,
	getISOWeek,
	isSameDay,
	isWithinInterval,
	startOfDay,
	startOfMonth,
	startOfWeek,
} from 'date-fns';

import { useCallback, useEffect, useMemo, useState } from 'react';

type WeekType = { week: number; dates: Date[] }[];
type DispatchType = {
	prev: () => void;
	next: () => void;
	date: Date;
	isDateSelected: (value: Date) => boolean;
	isWeekSelected: (weekNumber: number) => boolean;
	isLastSelectedDate: (value: Date) => boolean;
	isFirstSelected: (value: Date) => boolean;
};

export const useCalendarDates = (
	value?: { start: Date; end: Date },
	focusDate?: Date
): [WeekType, DispatchType] => {
	const currentValue = useMemo(() => {
		return (
			value || {
				start: startOfDay(new Date()),
				end: endOfDay(new Date()),
			}
		);
	}, [value]);

	const [date, setDate] = useState(focusDate || currentValue?.start || new Date());

	useEffect(() => {
		if (focusDate) {
			setDate(focusDate);
		}
	}, [focusDate]);

	const dates = useMemo(() => {
		const monthStarts = startOfMonth(date);
		const monthEnds = endOfMonth(date);

		const start = startOfWeek(monthStarts, { weekStartsOn: 1 });
		const end = endOfWeek(monthEnds, { weekStartsOn: 1 });

		return eachDayOfInterval({ start, end });
	}, [date]);

	const prev = useCallback(() => {
		setDate(addMonths(date, -1));
	}, [date]);

	const next = useCallback(() => {
		setDate(addMonths(date, 1));
	}, [date]);

	const isDateSelected = useCallback(
		(date: Date) => {
			return isWithinInterval(date, currentValue);
		},
		[currentValue]
	);

	const isLastSelectedDate = useCallback(
		(date: Date) => {
			return isDateSelected(date) && isSameDay(date, currentValue.end);
		},
		[isDateSelected, currentValue.end]
	);
	const isFirstSelected = useCallback(
		(date: Date) => {
			return isDateSelected(date) && isSameDay(date, currentValue.start);
		},
		[isDateSelected, currentValue.start]
	);

	const weeksValue = useMemo(() => {
		const weeks: WeekType = [];
		const numberOfWeeks = dates.length / 7;

		let weekStart = 0;
		let weekEnd = 7;

		for (let i = 0; i < numberOfWeeks; i++) {
			weeks.push({
				week: getISOWeek(dates[weekStart]),
				dates: [],
			});

			for (let index = weekStart; index < weekEnd; index++) {
				weeks[i].dates.push(dates[index]);
			}

			weekStart += 7;
			weekEnd += 7;
		}

		return weeks;
	}, [dates]);

	const isWeekSelected = useCallback(
		(weekNumber: number) => {
			return (
				weeksValue
					.find((w) => w.week === weekNumber)
					?.dates.every((d) => isWithinInterval(d, currentValue)) || false
			);
		},
		[currentValue, weeksValue]
	);

	return [
		weeksValue,
		{ prev, next, date, isDateSelected, isWeekSelected, isLastSelectedDate, isFirstSelected },
	];
};
