import timezones from 'timezones-list';
import { useParentOrganizations } from './useOrganizations';
import { useState } from 'react';
import { useCheckNameQuery } from '../api/Organizations';

export const useOrganizationGeneralFormOptions = () => {
	const { organizations } = useParentOrganizations();
	const [organizationName, setOrganizationName] = useState<string>('');

	const { data, isFetching } = useCheckNameQuery(
		{ organizationName },
		{ skip: organizationName === '' }
	);

	const timeZones = timezones
		.map((timezone) => {
			return {
				text: timezone.tzCode,
				value: timezone.tzCode,
			};
		})
		.sort((a, b) => a.text.localeCompare(b.text));

	const parentOrganizations = (organizations ?? [])?.map((org) => {
		return {
			text: org.name,
			value: org.id,
		};
	});

	const organizationNameChanged = (e: string) => {
		setOrganizationName(e);
	};

	const hostNameResponse =
		organizationName === ''
			? {
					isCheckingHostName: true,
					isHostNameAvailable: false,
					availableHostName: undefined,
				}
			: {
					isCheckingHostName: isFetching,
					availableHostName: data?.availableHostname,
					isHostNameAvailable: data?.isAvailable,
				};

	return { timeZones, parentOrganizations, organizationNameChanged, ...hostNameResponse };
};
