import { SettingsRowElement } from '@/components/SettingsRowElement';
import { ComponentPropsWithRef } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

interface Props extends ComponentPropsWithRef<typeof SettingsRowElement> {
	name: string;
}

export const GroupField = ({ name, children, ...props }: Props) => {
	const { control } = useFormContext();
	const active = useWatch({ name: `${name}` });

	return (
		<>
			<SettingsRowElement {...props}>
				<Controller
					name={name}
					control={control}
					render={({ field }) => {
						return (
							<SettingsRowElement.CheckBoxItem
								value={field.value}
								onChange={field.onChange}
							/>
						);
					}}
				/>
			</SettingsRowElement>
			{active && children}
		</>
	);
};
