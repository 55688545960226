import { Dashboard } from './Dashboard';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { NotFound } from './NotFound';
import { NewNotification, NotificationList } from './Notifications';
import { Actions } from './Actions';
import { SystemStatus } from './SystemStatus';
import { Devices } from './Devices';
import { Users } from './Users';
import { Settings } from './Settings';
import { Organizations } from './Organizations';
import { NewTask, TaskList } from './Tasks';
import { ErrorPage } from './ErrorPage';
import { AuthenticationLayout } from '@/layouts/AuthenticationLayout/AuthenticationLayout';
import { Settings as OrganizationSettings } from './Organization/Settings';
import { Summary as OrganizationSummery } from './Organization/Summery';
import { SettingsDefault } from './Organization/SettingsDefault';
import { FormShiftPlannerActivities } from '@/components/Organizations/FormShiftPlannerActivities';
import { FormShiftPlannerWorkingHours } from '@/components/Organizations/FormShiftPlannerWorkingHours';
import { FormShiftPlannerOtherSettings } from '@/components/Organizations/FormShiftPlannerOtherSettings';
import { FormTasks } from '@/components/Organizations/FormTasks';
import { FormActions } from '@/components/Organizations/FormActions';
import { FormAutomation } from '@/components/Organizations/FormAutomation';
import { FormTablet } from '@/components/Organizations/FormTablet';
import { FormWatch } from '@/components/Organizations/FormWatch';
import { GeneralInfo } from './Organization/GeneralInfo';
import { UserInfo } from './Organization/UserInfo';
import { CreateOrganization } from './Organization/Create';
import { OrganizationDelete } from './OrganizationDelete';
import { DefaultLayout } from '@/layouts/DefaultLayout';

export default createBrowserRouter([
	{
		element: <AuthenticationLayout errorComponent={ErrorPage} />,
		errorElement: <NotFound />,
		children: [
			{
				element: <DefaultLayout />,
				errorElement: <NotFound />,
				children: [
					{
						index: true,
						element: <Navigate to="/dashboard" replace={true} />,
					},
					{
						path: '/dashboard',
						element: <Dashboard />,
					},
					{
						path: '/notifications',
						element: <NotificationList />,
					},
					{
						path: '/notifications/new',
						element: <NewNotification />,
					},
					{
						path: '/tasks',
						element: <TaskList />,
					},
					{
						path: '/tasks/new',
						element: <NewTask />,
					},
					{
						path: '/actions',
						element: <Actions />,
					},
					{
						path: '/system-status',
						element: <SystemStatus />,
					},
					{
						path: '/devices',
						element: <Devices />,
					},
					{
						path: '/users',
						element: <Users />,
					},
					{
						path: '/settings',
						element: <Settings />,
					},
					{
						path: '/organizations',
						element: <Organizations />,
						children: [
							{
								path: '/organizations/delete/:id',
								element: <OrganizationDelete />,
							},
						],
					},
					{
						path: '*',
						element: <Navigate to="/" replace={true} />,
					},
				],
			},
			{
				path: '/organizations/create',
				element: <CreateOrganization />,
				children: [
					{
						index: true,
						element: (
							<Navigate to="/organizations/create/general-info" replace={true} />
						),
					},
					{
						path: '/organizations/create/general-info',
						element: <GeneralInfo />,
					},
					{
						path: '/organizations/create/user-info',
						element: <UserInfo />,
					},
					{
						path: '/organizations/create/configure-settings',
						element: <OrganizationSettings />,
						children: [
							{
								index: true,
								element: <SettingsDefault />,
							},
							{
								path: '/organizations/create/configure-settings/branding/watch',
								element: <FormWatch />,
							},
							{
								path: '/organizations/create/configure-settings/branding/tablet',
								element: <FormTablet />,
							},
							{
								path: '/organizations/create/configure-settings/branding/actions',
								element: <FormActions />,
							},
							{
								path: '/organizations/create/configure-settings/shift-planner',
								children: [
									{
										index: true,
										element: (
											<Navigate
												to={`/organizations/create/configure-settings/shift-planner/activities`}
												replace={true}
											/>
										),
									},
									{
										path: '/organizations/create/configure-settings/shift-planner/activities',
										element: <FormShiftPlannerActivities />,
									},
									{
										path: '/organizations/create/configure-settings/shift-planner/working-hours',
										element: <FormShiftPlannerWorkingHours />,
									},
									{
										path: '/organizations/create/configure-settings/shift-planner/other-settings',
										element: <FormShiftPlannerOtherSettings />,
									},
								],
							},
							{
								path: '/organizations/create/configure-settings/messaging',
								children: [
									{
										index: true,
										element: (
											<Navigate
												to={`/organizations/create/configure-settings/messaging/tasks`}
												replace={true}
											/>
										),
									},
									{
										path: '/organizations/create/configure-settings/messaging/tasks',
										element: <FormTasks />,
									},
									{
										path: '/organizations/create/configure-settings/messaging/actions',
										element: <FormActions />,
									},
								],
							},
							{
								path: '/organizations/create/configure-settings/automation/multiple-presses',
								element: <FormAutomation />,
							},
							{
								path: '/organizations/create/configure-settings/device-settings',
								children: [
									{
										index: true,
										element: (
											<Navigate
												to={`/organizations/create/configure-settings/device-settings/watch`}
												replace={true}
											/>
										),
									},
									{
										path: '/organizations/create/configure-settings/device-settings/watch',
										element: <FormWatch />,
									},
									{
										path: '/organizations/create/configure-settings/device-settings/tablet',
										element: <FormTablet />,
									},
								],
							},
							{
								path: '*',
								element: <SettingsDefault />,
							},
						],
					},
					{
						path: '/organizations/create/summary',
						element: <OrganizationSummery />,
					},
				],
			},
		],
	},
]);
