import { apiSlice } from '../api';
import { PaginatedResponse } from 'types/Response';
import { TaskSearchRequest } from 'types/Request';
import { Task } from '@/app/types/Task';

export const tasksApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		searchTasks: builder.query<PaginatedResponse<Task>, TaskSearchRequest>({
			query: ({ continuationToken, ...params }) => ({
				url: `tasks/search`,
				method: 'POST',
				body: {
					...params.filter,
					continuationToken,
				},
			}),
			forceRefetch: ({ currentArg, previousArg }) => {
				return (
					currentArg?.continuationToken !== previousArg?.continuationToken &&
					currentArg?.continuationToken !== undefined
				);
			},
			serializeQueryArgs: ({ endpointName, queryArgs }) => {
				return `${endpointName}(${JSON.stringify(queryArgs.filter)})`;
			},
			merge: (currentState, incomingState) => {
				currentState.items = [
					...(currentState.items ?? []),
					...(incomingState.items ?? []),
				];

				currentState.meta.continuation = incomingState?.meta?.continuation;
				currentState.meta.moreResults = incomingState?.meta?.moreResults;
			},
			providesTags: [{ type: 'TASKS', id: 'PARTIAL-LIST' }],
		}),
		createTask: builder.mutation<void, Partial<Task>>({
			query: (body) => {
				return {
					url: `/tasks`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: () => [{ type: 'TASKS', id: 'PARTIAL-LIST' }],
		}),
	}),

	overrideExisting: false,
});

export const { useCreateTaskMutation, useSearchTasksQuery, useLazySearchTasksQuery } =
	tasksApiSlice;
