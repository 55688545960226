import { StatisticsDownIcon, StatisticsUpIcon } from '@/components/Elements/Icons';
import Styles from './index.module.scss';

interface Props {
	value: boolean;
	onChange: (e: boolean) => void;
}

export const SortSwitch = ({ value, onChange }: Props) => {
	return (
		<div className={Styles.container}>
			<button
				type="button"
				onClick={() => onChange(true)}
				className={value ? Styles.selected : ''}
			>
				<StatisticsUpIcon />
			</button>
			<button
				type="button"
				onClick={() => onChange(false)}
				className={!value ? Styles.selected : ''}
			>
				<StatisticsDownIcon />
			</button>
		</div>
	);
};
