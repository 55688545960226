import { Link } from 'react-router-dom';
import Styles from './index.module.scss';
import { TemplatesIcon } from '../Icons';

interface Props {
	children?: React.ReactNode;
	align?: 'left' | 'right';
}

export const Navigation = ({ children, align = 'right' }: Props) => {
	return (
		<nav
			className={Styles.container}
			style={{ justifyContent: align === 'left' ? 'flex-start' : 'flex-end' }}
		>
			{children}
		</nav>
	);
};

const ButtonLink = (props: React.ComponentPropsWithRef<typeof Link>) => {
	return <Link {...props} className={Styles.link} />;
};

const IconLink = (props: React.ComponentPropsWithRef<typeof Link>) => {
	return (
		<Link {...props} className={Styles.iconLink}>
			<TemplatesIcon />
		</Link>
	);
};

Navigation.ButtonLink = ButtonLink;
Navigation.IconLink = IconLink;
