import { TableVirtuoso } from 'react-virtuoso';
import Styles from './index.module.scss';
import { useMemo, useState } from 'react';

import { TableRow } from './TableRow';
import { Table } from './Table';
import { DraggableHeaderContent } from './DraggableHeaderContent';
import { TableProvider } from './Context';
import { TaskItem } from './TaskItem';
import { Title } from './Title';
import { Status } from './Status';
import { TaskViewModel } from '@/app/types/TaskViewModel';
import { ExpandableItem, HeaderDefinition, Props } from './types';

export const TaskTable = ({ data = [], endReached }: Props<TaskViewModel>) => {
	const [columns] = useState<HeaderDefinition<TaskViewModel>[]>(() => {
		return [
			{
				name: 'task title',
				key: 'title',
				id: 1,
				element: (item) => <Title item={item} />,
				width: 300,
				minWidth: 300,
			},
			{ name: 'status', key: 'status', id: 2, element: (item) => <Status item={item} /> },
			{
				name: 'assignee',
				key: 'assignee',
				id: 3,
				element: (item) => <span className={Styles.assignee}>{item.assignee}</span>,
			},
			{
				name: 'start',
				key: 'start',
				id: 4,
				element: (item) => <span className={Styles.assignee}>{item.start}</span>,
			},
			{
				name: 'due',
				key: 'due',
				id: 5,
				element: (item) => <span className={Styles.assignee}>{item.due}</span>,
			},
		];
	});

	const rows = useMemo((): ExpandableItem[] => {
		return data.map((item) => {
			return {
				item,
				type: 'collapsed',
			};
		});
	}, [data]);

	return (
		<TableProvider columns={columns}>
			<TableVirtuoso
				components={{
					Table,
					TableRow,
				}}
				endReached={endReached}
				className={Styles.container}
				data={rows}
				fixedHeaderContent={DraggableHeaderContent}
				itemContent={(_, dataItem) => {
					return <TaskItem task={dataItem} />;
				}}
			/>
		</TableProvider>
	);
};
