import { useOrganizationsQuery } from '../api/Organizations';
import { Organization } from '@/app/types/Organization';

export const useOrganizations = () => {
	const { data, isFetching } = useOrganizationsQuery();

	return { organizations: data?.organizations ?? [], isFetching };
};

export const useOrganization = (id: string) => {
	const { item: organization } = useOrganizationsQuery(undefined, {
		selectFromResult: ({ data }) => {
			return {
				item: data?.organizations?.find((item) => item.id === id),
			};
		},
	});

	return { organization };
};

export const useOrganizationsDictionary = (): {
	[key: string]: Organization;
} => {
	const { organizations } = useOrganizations();

	return organizations.reduce((acc, curr) => ((acc[curr.id] = curr), acc), {});
};

export const useParentOrganizations = () => {
	const { data, isFetching } = useOrganizationsQuery();

	return { organizations: data?.parentOrganizations ?? [], isFetching };
};
