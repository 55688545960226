import React, { useCallback, useRef } from 'react';
import Styles from './index.module.scss';
import { PlusIcon } from '../Icons';

export type Props = {
	title?: string;
	className?: string;
	disabled?: boolean;
	onChange?: (file: File) => void;
	value?: string;
	tooltip?: string;
};

export const UploadButton = ({
	title,
	className,
	value,
	onChange,
	tooltip,

	disabled = false,
}: Props) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const handleClick = useCallback(() => inputRef?.current?.click(), []);

	const onFileSelected = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			e.stopPropagation();
			e.preventDefault();
			e.persist();
			const files = e.target.files || [];

			if (files.length < 1) return;

			if (onChange) {
				onChange(files[0]);
			}
		},
		[onChange]
	);

	return (
		<div
			className={`${Styles.container} ${className ? className : ''} ${
				disabled ? Styles.disabled : ''
			} `}
			title={tooltip}
		>
			{!value && title && <span>{title}</span>}
			{value && <img object-fit="fill" src={`data:image/jpeg;base64,${value}`} />}
			<div onClick={!disabled ? handleClick : undefined} className={Styles.button}>
				<PlusIcon width={16} height={16} viewBox="0,0,16,16" />
				<span>Choose file</span>
			</div>
			<input
				onChange={onFileSelected}
				ref={inputRef}
				style={{ display: 'none' }}
				type="file"
				name="logo"
				accept="image/png"
			/>
		</div>
	);
};
