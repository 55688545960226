import { ChevronDownIcon } from '@/components/Elements/Icons';
import Styles from './index.module.scss';
import { NavLink } from 'react-router-dom';
import React from 'react';

interface Props {
	children?: React.ReactNode;
	title: string;
	to: string;
	icon?: React.ReactElement;
	isExpandable?: boolean;
	defaultExpanded?: boolean;
	isEditable?: boolean;
	variant?: 'default' | 'error';
}

export const Expandable = ({
	children,
	title,
	to,
	icon,
	isExpandable,
	isEditable = true,
	defaultExpanded = false,
	variant = 'default',
}: Props) => {
	const [isExpanded, setIsExpanded] = React.useState(defaultExpanded);
	return (
		<div
			className={`${Styles.group} ${isExpandable ? Styles.groupExpandable : ''} ${isExpanded || !isExpandable ? Styles.groupExpanded : ''} ${variant === 'error' ? Styles.error : ''}`}
		>
			<div
				className={`${Styles.header}`}
				onClick={() => {
					setIsExpanded(() => !isExpanded);
				}}
			>
				{icon && <div className={Styles.icon}>{icon}</div>}
				<h4>{title}</h4>
				{isEditable && (
					<NavLink onClick={(e) => e.stopPropagation()} to={to} replace>
						Edit
					</NavLink>
				)}
				<ChevronDownIcon />
			</div>
			<div className={Styles.content}>{children}</div>
		</div>
	);
};
