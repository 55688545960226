import React from 'react';
import Styles from './index.module.scss';
import { useFormContext, useWatch } from 'react-hook-form';
import { OrganizationFormValues } from '@/app/types/OrganizationFormValues';
import { OrganizationErrorObject } from '@/app/types/Organization';
import { toErrorsDictionary } from '@/app/utils/organization/data';

const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
type Day = keyof OrganizationFormValues['settings']['shiftPlanner']['workingHours'];

interface Props {
	errors: OrganizationErrorObject[] | undefined;
}

export const ShiftPlanner = ({ errors }: Props) => {
	const { control } = useFormContext<OrganizationFormValues>();
	const { shiftPlanner } = useWatch({ control, name: 'settings' });

	const days = DAYS_OF_WEEK.map((day) => {
		const value = shiftPlanner.workingHours?.[day.toLowerCase() as Day];

		if (!value) {
			return {
				name: day,
				time: '',
			};
		}

		const startHour = `${value.start.hours}`.padStart(2, '0');
		const startMinutes = `${value.start.minutes}`.padStart(2, '0');
		const endHour = `${value.end.hours}`.padStart(2, '0');
		const endMinutes = `${value.end.minutes}`.padStart(2, '0');
		return {
			name: day,
			time: `${startHour}:${startMinutes} - ${endHour}:${endMinutes}`,
		};
	});

	const mapper = toErrorsDictionary(errors);

	return (
		<div className={`${Styles.container}`}>
			<h4 className={Styles.fullWidth}>Activities</h4>
			{shiftPlanner.activities?.map((activity, index) => {
				return (
					<p className={Styles.fullWidth} key={index}>
						{activity.name}
					</p>
				);
			})}
			{mapper?.['settings.shifts.activities'] && (
				<span className={`${Styles.error}`} style={{ gridColumn: 1 }}>
					{mapper?.['settings.shifts.activities']}
				</span>
			)}
			<h4 className={Styles.fullWidth}>Working hours</h4>
			{days.map((day) => {
				return (
					<React.Fragment key={day.name}>
						<p>{day.name}</p>
						<span>{day.time}</span>
					</React.Fragment>
				);
			})}

			{mapper?.['settings.shifts.workinghours'] && (
				<span className={`${Styles.error}`} style={{ gridColumn: 1 }}>
					{mapper?.['settings.shifts.workinghours']}
				</span>
			)}
			<h4 className={Styles.fullWidth}>Other settings</h4>
			<p>Time Selection Interval</p>
			<span>{shiftPlanner.timeSelectionInterval ?? 0}</span>
			{mapper?.['settings.shifts.timeselectioninterval'] && (
				<span className={`${Styles.error}`} style={{ gridColumn: 2 }}>
					{mapper?.['settings.shifts.timeselectioninterval']}
				</span>
			)}

			<p>Default Zoom Level (Timeline)</p>
			<span>{shiftPlanner?.zoomLevel ?? 0}</span>
			{mapper?.['settings.shifts.zoomLevel'] && (
				<span className={`${Styles.error}`} style={{ gridColumn: 2 }}>
					{mapper?.['settings.shifts.zoomLevel']}
				</span>
			)}
		</div>
	);
};
