import { useContext } from 'react';
import Styles from './index.module.scss';
import { ExpandableItem } from './types';
import { Context } from './Context';

interface Props {
	task: ExpandableItem;
}

export const TaskItem = ({ task: { item, type } }: Props) => {
	const { headers } = useContext(Context);

	if (type === 'expanded') {
		return (
			<>
				<td colSpan={5} style={{ height: 200 }}>
					details goes here
				</td>
			</>
		);
	}

	return (
		<>
			{headers.map((header) => {
				return (
					<td key={header.id}>
						<div className={Styles.cell}>
							{header.element
								? header.element(item)
								: `${item[header.key]?.toString() ?? ''}`}
						</div>
					</td>
				);
			})}
		</>
	);
};
