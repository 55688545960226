import { OrganizationFormValues } from '@/app/types/OrganizationFormValues';
import { OrganizationFormValuesContext } from './types';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useCreateOrganizationMutation } from '@/app/api/Organizations';
import { mapFormValuesToDto } from '@/app/utils/organization/mappers';
import { OrganizationErrorResponse } from '@/app/types/Organization';

interface Props {
	initialValues?: Partial<OrganizationFormValues>;
	children: React.ReactNode;
}

export const FormOrganizationDataProvider = ({ initialValues, children }: Props) => {
	const [trigger, { isLoading, error, isSuccess }] = useCreateOrganizationMutation();
	const [formValues, setLocalFormValues] = useState<Partial<OrganizationFormValues>>(
		initialValues ?? {}
	);
	const setFormValues = useCallback((e: Partial<OrganizationFormValues>) => {
		setLocalFormValues((prev) => ({ ...prev, ...e }));
	}, []);

	const create = useCallback(
		(formValues: Partial<OrganizationFormValues>) => {
			return trigger(mapFormValuesToDto(formValues as OrganizationFormValues));
		},
		[trigger]
	);

	const errors = useMemo(() => {
		if (error) {
			const errorObj = error as OrganizationErrorResponse;
			if (!Array.isArray(errorObj.data)) {
				return [errorObj.data];
			} else {
				return errorObj.data;
			}
		}

		return undefined;
	}, [error]);

	return (
		<OrganizationFormValuesContext.Provider
			value={{
				formValues: formValues,
				setFormValues,
				isSubmitting: isLoading,
				create,
				errors,
				isSuccess,
			}}
		>
			{children}
		</OrganizationFormValuesContext.Provider>
	);
};

export const useOrganizationFormDataProvider = () => {
	const context = useContext(OrganizationFormValuesContext);

	if (!context) {
		throw new Error(
			'useOrganizationFormDataProvider must be used within a FormOrganizationDataProvider'
		);
	}

	return context;
};
