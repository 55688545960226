import Styles from './index.module.scss';

export const CountCell = ({ value, icon }: { value: string; icon: React.ReactElement }) => {
	return (
		<div className={Styles.count}>
			{icon}
			<p>{value}</p>
		</div>
	);
};
