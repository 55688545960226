import { useRef, useState } from 'react';
import Styles from './index.module.scss';

import { ContextIcon, DeleteIcon, EditIcon } from '@/components/Elements/Icons';

import { ContextMenu } from '@/components/Elements/ContextMenu';
import { ContextMenuItem } from '@/components/Elements/ContextMenuItem';
import { useNavigate } from 'react-router-dom';

interface Props {
	id: string;
}

export const MoreCell = ({ id }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef<HTMLButtonElement>(null);
	const navigate = useNavigate();

	return (
		<td>
			<button
				ref={ref}
				onClick={() => {
					setIsOpen(!isOpen);
				}}
				className={`${Styles.moreButton} ${isOpen ? Styles.active : ''}`}
			>
				<ContextIcon />

				{isOpen && (
					<ContextMenu
						parentRef={ref}
						onCloseRequested={() => {
							setIsOpen(false);
						}}
					>
						<ContextMenuItem icon={<EditIcon />} onClick={() => {}} title="Edit" />
						<ContextMenuItem
							icon={<DeleteIcon />}
							onClick={() => {
								navigate(`/organizations/delete/${id}`);
							}}
							title="Delete"
						/>
					</ContextMenu>
				)}
			</button>
		</td>
	);
};
