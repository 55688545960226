import Styles from './index.module.scss';
import { Props } from './types';
import { useHandlers } from './hooks';
import { ChevronDownIcon } from '../Icons';

export const TimePicker = (props: Props) => {
	const { header, headerValue, handleChange, timeType } = useHandlers(props);
	const monthlyClassName = timeType === 'monthly' ? Styles.monthly : '';
	const customClassName = timeType === 'custom' ? Styles.custom : '';

	return (
		<div
			className={`
			${Styles.container} 
			${monthlyClassName} 
			${customClassName}`}
		>
			<button
				type="button"
				onClick={() => {
					handleChange?.(-1);
				}}
			>
				<ChevronDownIcon />
			</button>
			<div>
				<h4>{header}</h4>
				<span>{headerValue}</span>
			</div>
			<button
				type="button"
				onClick={() => {
					handleChange?.(1);
				}}
			>
				<ChevronDownIcon />
			</button>
		</div>
	);
};
