import Styles from './index.module.scss';
import { OrganizationErrorObject } from '@/app/types/Organization';

interface Props {
	errors?: OrganizationErrorObject[];
}

export const Errors = ({ errors }: Props) => {
	return (
		<div className={Styles.container}>
			{errors?.map((error, index) => {
				return (
					<div key={index} className={Styles.item}>
						<p>Property:</p>
						<span>{error.property ?? ''}</span>
						<p>Error:</p>
						<span>{error?.error ?? ''}</span>
					</div>
				);
			})}
		</div>
	);
};
