import { MultiSelect } from '@/components/Elements/MultiSelect';
import Styles from './index.module.scss';
import { PeriodSelector } from '@/components/PeriodSelector';
import { ProfileDropdown } from '@/components/ProfileDropdown';
import { useTopBarFilter } from './hooks/useTopBarFilter';
import { usePeriodSelector } from './hooks/usePeriodSelector';

interface Props {
	title: string;
	displayFilter?: boolean;
	displayPeriodSelector?: boolean;
	navigation?: React.ReactNode;
	children?: React.ReactNode;
}

export const PortalPageLayout = ({
	title,
	displayFilter = true,
	displayPeriodSelector = true,
	navigation,
	children,
}: Props) => {
	const [options, organizationFilterValue, onOrganizationFilterChanged] = useTopBarFilter();
	const [periodValue, onPeriodValueChanged] = usePeriodSelector();

	return (
		<div className={Styles.container}>
			<div className={Styles.header}>
				<h1>{title}</h1>
				<div className={Styles.topBar}>
					{displayFilter && (
						<MultiSelect
							options={options}
							value={organizationFilterValue}
							onChange={onOrganizationFilterChanged}
							placeholder="All organizations"
						/>
					)}
				</div>
				<ProfileDropdown />
			</div>
			<div className={Styles.periodSelector}>
				{displayPeriodSelector && (
					<div className={Styles.selector}>
						<PeriodSelector value={periodValue} onChange={onPeriodValueChanged} />
					</div>
				)}
				{navigation}
			</div>
			{children}
		</div>
	);
};
