import { useDeleteOrganizationMutation } from '@/app/api/Organizations';
import { useOrganizationsDictionary } from '@/app/hooks/useOrganizations';
import { DeleteModal } from '@/components/Modals/DeleteModal';
import { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

export const OrganizationDelete = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const organizations = useOrganizationsDictionary();
	const organization = organizations[id ?? ''];
	const [trigger, { isLoading, isSuccess }] = useDeleteOrganizationMutation();

	useEffect(() => {
		if (isSuccess) {
			navigate('/organizations', { replace: true });
		}
	}, [isSuccess, navigate]);

	if (!organization) {
		return <Navigate to="/organizations" replace={true} />;
	}

	return (
		<>
			<DeleteModal
				isLoading={isLoading}
				preventClose={isLoading}
				title="Are you sure you wish to delete?"
				onCloseRequest={() => {
					navigate('/organizations', { replace: true });
				}}
				onClick={async () => {
					await trigger(organization.id);
				}}
			>
				<span>{organization.name}</span>
			</DeleteModal>
		</>
	);
};
