import { OrganizationFormValues } from '@/app/types/OrganizationFormValues';
import Styles from './index.module.scss';
import { useFormContext, useWatch } from 'react-hook-form';
import { OrganizationErrorObject } from '@/app/types/Organization';
import { toErrorsDictionary } from '@/app/utils/organization/data';

interface Props {
	errors: OrganizationErrorObject[] | undefined;
}

export const GeneralInfo = ({ errors }: Props) => {
	const { control } = useFormContext<OrganizationFormValues>();
	const { organisationName, ownerEmail, ownerFirstName, ownerLastName, ownerPassword, timeZone } =
		useWatch({ control, name: 'generalInfo' });

	const mapper = toErrorsDictionary(errors);

	return (
		<div className={`${Styles.container}`}>
			<h5>{'Organization Name'}</h5>
			<span className={`${Styles.singleValue} ${Styles.upperCase}`}>{organisationName}</span>
			<h5>{'Organization Owner Details'}</h5>
			<div className={Styles.details}>
				<span className={Styles.title}>{'Name:'}</span>
				<span className={Styles.text}>{`${ownerFirstName} ${ownerLastName}`}</span>
				{mapper?.['users[0].name'] && (
					<span className={Styles.error}>{mapper?.['users[0].name']}</span>
				)}
				<span className={Styles.title}>{'Email:'}</span>
				<span className={Styles.text}>{ownerEmail}</span>
				{mapper?.['users[0].email'] && (
					<span className={Styles.error}>{mapper?.['users[0].email']}</span>
				)}
				<span className={Styles.title}>{'Password:'}</span>
				<span className={Styles.text}>{ownerPassword}</span>
				{mapper?.['users[0].password'] && (
					<span className={Styles.error}>{mapper?.['users[0].password']}</span>
				)}
			</div>
			<h5>{'Time Zone'}</h5>
			<span className={`${Styles.singleValue}`}>{timeZone}</span>
			{mapper?.['organization.timezone'] && (
				<span className={Styles.error}>{mapper?.['organization.timezone']}</span>
			)}
		</div>
	);
};
