import { ArrowIcon } from '@/components/Elements/Icons';
import Styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
	buttons?: { children: string; onClick: () => void }[];
	children?: React.ReactNode;
}

export const FormPage = ({ buttons = [], children }: Props) => {
	const navigate = useNavigate();
	return (
		<div className={Styles.container}>
			<div className={Styles.header}>
				<button
					onClick={() => {
						navigate(-1);
					}}
				>
					<ArrowIcon />
				</button>
				{buttons.map((props, index) => {
					return <button key={index} {...props} />;
				})}
			</div>

			<div className={Styles.page}>{children}</div>
		</div>
	);
};
