import { ArrowCircleRightIcon } from '../Icons';
import Styles from './index.module.scss';
interface Props {
	items: { value: string; text: string }[];
	value?: string;
	onChange?: (e: string) => void;
}

export const Switch = ({ items = [], value, onChange }: Props) => {
	return (
		<div className={Styles.header}>
			{items.map((item) => {
				return (
					<button
						key={item.value}
						type="button"
						className={`${value === item.value ? Styles.active : ''}`}
						onClick={() => {
							onChange?.(item.value);
						}}
					>
						<ArrowCircleRightIcon />
						{item.text ?? ''}
					</button>
				);
			})}
		</div>
	);
};
