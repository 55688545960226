import { SettingsCornerUpIcon } from '@/components/Elements/Icons';
import { SettingsRowElement } from '@/components/SettingsRowElement';
import { useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

export const AutoForwardField = () => {
	const items = useMemo(() => {
		return new Array(300).fill(0).map((_, index) => {
			return { text: `${index + 1}`, value: index + 1 };
		});
	}, []);
	const { fields } = useFieldArray({ name: 'actions.autoForward' });
	const { control } = useFormContext();

	return (
		<SettingsRowElement
			title="Auto-forward Time"
			subtitle="(Seconds)"
			icon={<SettingsCornerUpIcon />}
		>
			<SettingsRowElement.Group>
				{fields.map((field, index) => {
					return (
						<Controller
							control={control}
							name={`actions.autoForward[${index}]`}
							key={field.id}
							render={({ field }) => {
								return (
									<SettingsRowElement.SelectTimeGroupItem
										index={index + 1}
										value={field.value}
										onChange={field.onChange}
										items={items}
									/>
								);
							}}
						/>
					);
				})}
			</SettingsRowElement.Group>
		</SettingsRowElement>
	);
};
