import Styles from './index.module.scss';
import { TabButton } from '../Elements/TabButton';
import { TimePicker } from '../Elements/TimePicker';
import { FilterTimeType } from 'types/Filter';
import { convertToTimeFromPeriod } from '@/app/utils/period';
import { CalendarPicker } from '../Elements/CalendarPicker';

type Value = {
	startTime: string;
	endTime: string;
	timeType: FilterTimeType;
};

interface Props {
	value?: Value;
	onChange?: (item: Value) => void;
}

export const PeriodSelector = ({ value, onChange }: Props) => {
	const types: FilterTimeType[] = ['daily', 'weekly', 'monthly', 'custom'];

	return (
		<div className={Styles.container}>
			{types.map((item) => {
				return (
					<TabButton
						key={item}
						isSelected={value?.timeType === item}
						onClick={() => {
							onChange?.({
								timeType: item,
								...convertToTimeFromPeriod(item),
							});
						}}
					>
						{item}
					</TabButton>
				);
			})}

			<TimePicker value={value} onChange={onChange} />
			<CalendarPicker key={value?.timeType} value={value} onChange={onChange} />
		</div>
	);
};
