import React from 'react';
import Styles from './index.module.scss';

interface Props {
	children?: React.ReactNode;
	title: string;
	toolbar?: React.ReactNode;
}

export const Widget = ({ children, title, toolbar }: Props) => {
	return (
		<div className={Styles.card}>
			<div className={Styles.border}>
				<div className={Styles.header}>
					<h1>{title}</h1>
					<div className={Styles.toolbar}>{toolbar}</div>
				</div>
				{children}
			</div>
		</div>
	);
};
