import { SettingsMessageSquareIcon, SettingsTabletIcon } from '@/components/Elements/Icons';
import { TabsPicker } from '@/components/Elements/TabsPicker';
import { SettingsRowElement } from '@/components/SettingsRowElement';
import { SettingsView } from '@/components/SettingsView';
import { WorkingHoursPicker } from '@/components/WorkingHoursPicker';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TemplatesField } from './TemplatesField';
import { SettingsFormValues } from '@/app/types/OrganizationFormValues';

export const FormTablet = () => {
	const { control } = useFormContext<SettingsFormValues>();
	const status = useWatch({ name: 'tablet.status' });

	return (
		<SettingsView title="Tablet.">
			<SettingsView.Section title="Tablet Settings">
				<SettingsRowElement
					title="Tablet Current Status"
					icon={<SettingsTabletIcon />}
					hasBorder={status === 'scheduled' ? false : true}
				>
					<Controller
						name="tablet.status"
						control={control}
						render={({ field }) => (
							<TabsPicker
								value={field.value}
								onChange={field.onChange}
								items={[
									{ text: 'Active', value: 'active' },
									{ text: 'Inactive', value: 'inactive' },
									{ text: 'Schedule', value: 'scheduled' },
								]}
							/>
						)}
					/>
				</SettingsRowElement>
				{status === 'scheduled' && (
					<Controller
						name="tablet.workingHours"
						control={control}
						render={({ field }) => (
							<WorkingHoursPicker
								variant="inline"
								title="Tablet Active Time"
								value={field.value}
								onChange={field.onChange}
							/>
						)}
					/>
				)}
				<SettingsRowElement
					title="No Response Message Timer "
					subtitle="(Seconds)"
					icon={<SettingsMessageSquareIcon />}
				>
					<Controller
						control={control}
						name="tablet.noResponseTimer"
						render={({ field }) => {
							return (
								<SettingsRowElement.SelectTimeItem
									value={field.value}
									onChange={field.onChange}
									items={[
										{
											text: '10',
											value: '10',
										},
										{
											text: '30',
											value: '30',
										},
										{
											text: '45',
											value: '45',
										},
										{
											text: '60',
											value: '60',
										},
										{
											text: '90',
											value: '90',
										},
										{
											text: '120',
											value: '120',
										},
									]}
								/>
							);
						}}
					/>
				</SettingsRowElement>
			</SettingsView.Section>

			<TemplatesField />
		</SettingsView>
	);
};
