import { User } from '@/app/types/User';
import Styles from '../../index.module.scss';
import { CloseIcon } from '@/components/Elements/Icons';

interface Props {
	value: User[];
	onChange?: (e: User[]) => void;
}

export const Recipients = ({ value, onChange }: Props) => {
	return (
		<div className={Styles.recipients}>
			<span className={Styles.label}>To:</span>
			<div className={Styles.list}>
				{value.map((rec) => {
					return (
						<div key={rec.id ?? ''} className={Styles.item}>
							<span>{rec.email}</span>
							<CloseIcon
								onClick={() => {
									onChange?.((value ?? []).filter((val) => val.id !== rec.id));
								}}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};
