import { SettingsRowElement } from '@/components/SettingsRowElement';
import { Controller, useFormContext } from 'react-hook-form';

export const IncomingMessageDurationField = () => {
	const { control } = useFormContext();

	return (
		<SettingsRowElement
			variant="child"
			title="Duration of incoming message splash screen in milliseconds"
			subtitle="(Watch)"
		>
			<Controller
				name={`actions.incomingMessageDuration`}
				control={control}
				render={({ field }) => {
					return (
						<SettingsRowElement.InputItem
							value={field.value}
							onChange={field.onChange}
							variant="small"
						/>
					);
				}}
			/>
		</SettingsRowElement>
	);
};
