import { ItemProps } from 'react-virtuoso';
import { ExpandableItem } from './types';
import { useContext } from 'react';
import { Context } from './Context';

export const TableRow = ({ ...props }: ItemProps<ExpandableItem>) => {
	const { setExpandedItemIndex, expandedItemIndex } = useContext(Context);
	return (
		<tr
			{...props}
			onClick={() => {
				if (props.item.isExpanded) {
					return;
				}

				if (props['data-index'] === expandedItemIndex) {
					setExpandedItemIndex(-1);
					return;
				}

				if (expandedItemIndex !== -1 && props['data-index'] > expandedItemIndex + 1) {
					setExpandedItemIndex(props['data-index'] - 1);
					return;
				}

				setExpandedItemIndex(props['data-index']);
			}}
		/>
	);
};
