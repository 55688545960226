import Styles from '../index.module.scss';
import debounce from 'lodash/debounce';
import { Select } from '@/components/Elements/Select';
import { SearchRoundedIcon } from '@/components/Elements/Icons';
import { Organization } from '@/app/types/Organization';
import { UsersSearchFilter } from '@/app/types/Filter';

interface Props {
	organizations: Organization[];
	value: Partial<UsersSearchFilter>;
	onChange?: (e: Partial<UsersSearchFilter>) => void;
}

export const Filter = ({ organizations, value, onChange }: Props) => {
	const options = [
		{ name: 'All organizations', value: '-1', type: 'user' },
		...organizations.map((org) => {
			return {
				name: org.name,
				value: org.id,
				type: 'user',
			};
		}),
	];

	const selected = options.find((o) => o.value === value?.organizationIds?.[0]);

	const handleTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange?.({
			...(value ?? {}),
			name: e.target.value,
		});
	};

	const debouncedHandler = debounce(handleTextChanged, 750);

	return (
		<div className={Styles.searchArea}>
			<Select
				placeholder="All organizations"
				value={selected}
				onChange={(e) => {
					onChange?.({
						...(value ?? {}),
						organizationIds: e.value === '-1' ? [] : [e.value],
					});
				}}
				options={options}
			/>
			<div className={Styles.searchContainer}>
				<span className={Styles.searchTitle}>Select Group or Group Members</span>

				<div className={Styles.search}>
					<SearchRoundedIcon />
					<input placeholder="Search Users" onChange={debouncedHandler} />
				</div>
			</div>
		</div>
	);
};
