import { SettingsFormValuesSchema } from '@/app/schemas/OrganizationFormValuesSchema';
import { SettingsFormValues } from '@/app/types/OrganizationFormValues';
import { FormCreateOrganization } from '@/components/Organizations/FormCreateOrganization';
import { OrganizationSettingsLayout } from '@/layouts/OrganziationSettingsLayout';
import { SubmitHandler } from 'react-hook-form';
import { Outlet } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useOrganizationFormDataProvider } from '@/components/Organizations/FormOrganizationDataProvider';
import { useDefaultSettingsQuery } from '@/app/api/Organizations';
import { mapSettingsToFormValues } from '@/app/utils/organization/mappers';
import { FormSection } from '@/components/Organizations/FormSection';
import { useState } from 'react';

export const Settings = () => {
	const { formValues, setFormValues } = useOrganizationFormDataProvider();
	const [prevValues] = useState(formValues.settings);
	const { data: defaultSettings } = useDefaultSettingsQuery();

	const updateDefaultSettings = mapSettingsToFormValues(defaultSettings);

	const onSubmit: SubmitHandler<SettingsFormValues> = (data) => {
		setFormValues({ settings: data });
	};

	return (
		<FormCreateOrganization
			defaultValues={formValues.settings || updateDefaultSettings}
			resolver={zodResolver(SettingsFormValuesSchema)}
			onSubmit={onSubmit}
		>
			<FormSection
				mode={prevValues ? 'edit' : 'create'}
				urlParam="create"
				submitText="Next: Summary"
				backUrl="/organizations/create/user-info"
				nextUrl="/organizations/create/summary"
			>
				<OrganizationSettingsLayout
					urlParam="create"
					organization={formValues.generalInfo?.organisationName ?? ''}
					numberOfUsers={formValues?.userInfo?.users?.length ?? 0}
				>
					<Outlet />
				</OrganizationSettingsLayout>
			</FormSection>
		</FormCreateOrganization>
	);
};
