import Styles from './index.module.scss';
import { FilterPage } from '@/layouts/FilterPage';
import { ButtonsSelect } from '@/components/Elements/ButtonSelect';
import { useState } from 'react';
import { useNotificationList } from '@/app/hooks/useNotificationList';
import { SearchInput } from '@/components/Elements/SearchInput';
import { useDebounceInputHandler } from '@/app/hooks/useDebounceInputHandler';
import { NotificationsTable } from '@/components/Notification/NotificationsTable';

export const List = () => {
	const [title, setTitle] = useState('');
	const [status, setStatus] = useState<string>('sent');
	const [data, { isFetching }] = useNotificationList(title, status === 'scheduled');
	const handler = useDebounceInputHandler(setTitle);

	return (
		<>
			<FilterPage title="Notifications" url="/notifications/new" urlTitle="New Notification">
				<div className={Styles.container}>
					<SearchInput placeholder="Search tasks" onChange={handler} />
					<ButtonsSelect
						value={status}
						onChange={setStatus}
						options={[
							{ name: 'sent', value: 'sent' },
							{ name: 'scheduled', value: 'scheduled' },
						]}
					/>
				</div>
				<NotificationsTable data={data} isFetching={isFetching} />
			</FilterPage>
		</>
	);
};
