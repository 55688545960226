import { NavLink } from 'react-router-dom';

type ComponentType = React.ComponentProps<typeof NavLink>;

export const withDisable = (Component: React.ComponentType<ComponentType>) => {
	return ({ disabled, ...props }: ComponentType & { disabled?: boolean }) => {
		return (
			<Component
				{...props}
				data-disabled={disabled ? true : false}
				onClick={(e) => {
					if (disabled) {
						e.preventDefault();
						e.stopPropagation();
					} else {
						props.onClick?.(e);
					}
				}}
			/>
		);
	};
};
