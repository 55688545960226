import toast from 'react-hot-toast';
import { PageLayout } from '@/layouts/PageLayout';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useCreateNotificationMutation } from '@/app/api/Notifications';
import { Notification } from '@/app/types/Notification';
import { User } from '@/app/types/User';
import { FormPage } from '@/layouts/FormPage';
import { RecipientsForm } from '@/components/Form/RecipientsForm';
import { NotificationForm } from '@/components/Notification';
import { NotificationFormValues } from '@/components/Notification/NotificationForm/types';

export const New = () => {
	const navigate = useNavigate();
	const [create, { isSuccess, isError, isLoading }] = useCreateNotificationMutation();

	useEffect(() => {
		if (isSuccess && !isError) {
			navigate(-1);
		}

		if (isError) {
			toast.custom(() => (
				<span
					style={{
						background: '#fe3266',
						display: 'flex',
						gap: 8,
						padding: 16,
						borderRadius: 18,
						color: '#fff',
						fontFamily: 'Graphik',
						fontSize: 16,
						maxWidth: 350,
					}}
				>
					Something went wrong! <br /> Please contact system administrator
				</span>
			));
		}
	}, [isSuccess, isError, navigate]);

	const { control, handleSubmit } = useForm<NotificationFormValues>({
		defaultValues: {
			title: '',
			body: '',
			users: [],
		},
	});

	const onSubmit: SubmitHandler<NotificationFormValues> = async (model) => {
		const groups = new Map<string, User[]>();
		(model.users ?? []).forEach((item) => {
			groups.set(item.org_id ?? '', [...(groups.get(item.org_id ?? '') ?? []), item]);
		});

		const notification: Partial<Notification> = {
			content: {
				title: {
					text: model.title,
				},
				body: {
					text: model.body,
				},
			},
			recipient_groups: [...(groups ?? [])].map((gr) => {
				return {
					group_number: 0,
					org_id: gr[0],
					recipients: [...(gr[1] ?? [])].map((user) => {
						return {
							type: 'user',
							id: user.id ?? '',
						};
					}),
				};
			}),
		};

		await create(notification);
	};

	const buttons = [{ children: 'Save as Template', onClick: console.log }];

	return (
		<PageLayout title="Notifications">
			<FormPage buttons={buttons}>
				<RecipientsForm
					title="Notification"
					control={control}
					titlePath="title"
					bodyPath="body"
					recipientsPath="users"
					onSubmit={handleSubmit(onSubmit)}
				>
					<NotificationForm isLoading={isLoading} />
				</RecipientsForm>
			</FormPage>
		</PageLayout>
	);
};
