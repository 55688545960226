import { useContext } from 'react';
import { TableProps } from 'react-virtuoso';
import { Context } from './Context';

export const Table = ({ style, ...props }: TableProps) => {
	const { parentRef } = useContext(Context);
	return (
		<table
			{...props}
			ref={parentRef}
			style={{
				...style,
				border: 0,
				borderWidth: 0,
				width: '100%',
				overflow: 'scroll',
				tableLayout: 'fixed',
			}}
		/>
	);
};
