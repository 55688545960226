import { SettingsMessageHeartIcon } from '@/components/Elements/Icons';
import { SettingsRowElement } from '@/components/SettingsRowElement';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
	name: string;
	title: string;
}

export const InputField = ({ name, title }: Props) => {
	const { control } = useFormContext();

	return (
		<SettingsRowElement title={title} subtitle="(Login)" icon={<SettingsMessageHeartIcon />}>
			<Controller
				control={control}
				name={`watch.${name}`}
				render={({ field }) => {
					return (
						<SettingsRowElement.InputItem
							value={field.value}
							onChange={field.onChange}
							placeholder={title}
						/>
					);
				}}
			/>
		</SettingsRowElement>
	);
};
