import { WorkingHoursPicker } from '@/components/WorkingHoursPicker';
import { SettingsView } from '@/components/SettingsView';
import { Controller, useFormContext } from 'react-hook-form';
import { SettingsFormValues } from '@/app/types/OrganizationFormValues';

export const FormShiftPlannerWorkingHours = () => {
	const { control } = useFormContext<SettingsFormValues>();

	return (
		<SettingsView title="Working hours.">
			<SettingsView.Section title="Working Hours">
				<Controller
					name="shiftPlanner.workingHours"
					control={control}
					render={({ field }) => {
						return <WorkingHoursPicker value={field.value} onChange={field.onChange} />;
					}}
				/>
			</SettingsView.Section>
		</SettingsView>
	);
};
