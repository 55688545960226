import Styles from './index.module.scss';

interface Props {
	value?: boolean;
	onChange?: (e: boolean) => void;
	disabled?: boolean;
}

export const CheckBox = ({ value, onChange, disabled }: Props) => {
	return (
		<div
			className={`${Styles.container} ${disabled ? Styles.disabled : ''} ${value ? Styles.active : ''}`}
			onClick={() => {
				onChange?.(!value);
			}}
		>
			<div className={Styles.rect}></div>
			<div className={Styles.circle}></div>
		</div>
	);
};
