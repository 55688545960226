import { isEndOfDay, isEndOfDayToday, isLaterToday, isStartOfDay, isTomorrow } from './date';

import { Moment } from 'moment';

const formatStart = (time: Moment) => {
	if (!time.isValid()) {
		return '';
	}

	if (isLaterToday(time)) {
		return `Today, ${time.format('HH:mm')}`;
	}

	if (isTomorrow(time) && isStartOfDay(time)) {
		return 'Tomorrow';
	}

	if (isTomorrow(time)) {
		return `Tomorrow, ${time.format('HH:mm')}`;
	}

	if (isStartOfDay(time)) {
		return `${time.format('ddd, DD MMM')}`;
	}

	return `${time.format('ddd, DD MMM')}, ${time.format('HH:mm')}`;
};

const formatEnd = (time: Moment) => {
	if (!time.isValid()) {
		return '';
	}

	if (isEndOfDayToday(time)) {
		return `End of Day`;
	}

	if (isLaterToday(time)) {
		return `Today, ${time.format('HH:mm')}`;
	}

	if (isTomorrow(time) && !isEndOfDay(time)) {
		return `Tomorrow, ${time.format('HH:mm')}`;
	}

	if (isTomorrow(time)) {
		return `Tomorrow`;
	}

	if (!isEndOfDay(time)) {
		return `${time.format('ddd, DD MMM')}, ${time.format('HH:mm')}`;
	}

	return `${time.format('ddd, DD MMM')}`;
};

export default {
	formatStart,
	formatEnd,
};
