import { CloseIcon } from '@/components/Elements/Icons';
import Styles from './index.module.scss';
import ReactDOM from 'react-dom';
import { Button } from '@/components/Elements/Button';
import { useClickOutside } from '@/app/hooks/useClickOutside';
import { useCallback } from 'react';

interface Props {
	title: string;
	header?: string;
	children?: React.ReactNode;
	isLoading?: boolean;
	onCloseRequest?: () => void;
	onClick?: () => void;
	confirmText?: string;
	cancelText?: string;
	preventClose?: boolean;
	type?: HTMLButtonElement['type'];
	excluded?: HTMLElement[];
	variant?: 'delete' | 'warning';
}

export const DeleteModal = ({
	title,
	children,
	isLoading,
	onClick,
	onCloseRequest,
	excluded,
	confirmText = 'Delete',
	cancelText = 'Cancel',
	preventClose = false,
	type = 'button',
	header = 'JUST CHECKING...',
	variant = 'delete',
}: Props) => {
	const close = useCallback(() => {
		if (preventClose) return;
		onCloseRequest?.();
	}, [preventClose, onCloseRequest]);

	const { ref } = useClickOutside<HTMLDivElement>(close, excluded);

	return (
		<>
			{ReactDOM.createPortal(
				<div className={Styles.container} ref={ref}>
					{!preventClose && (
						<button className={Styles.closeButton} type="button" onClick={close}>
							<CloseIcon />
						</button>
					)}
					<div className={Styles.header}>
						<h3>{header}</h3>
						<span>{title}</span>
					</div>
					<div className={Styles.body}>{children}</div>
					<div className={Styles.footer}>
						{variant === 'delete' && (
							<>
								<Button
									variant="secondary"
									type="button"
									className={Styles.cancel}
									disabled={isLoading}
									onClick={onCloseRequest}
								>
									{cancelText}
								</Button>
								<Button
									type={type}
									className={Styles.delete}
									disabled={isLoading}
									onClick={onClick}
								>
									{confirmText}
								</Button>
							</>
						)}
						{variant === 'warning' && (
							<Button
								type={type}
								className={Styles.delete}
								disabled={isLoading}
								onClick={close}
							>
								{confirmText}
							</Button>
						)}
					</div>
				</div>,
				document.body
			)}
		</>
	);
};
