import React from 'react';
import Styles from './NavigationButton.module.scss';
import { CalendarLeftIcon, CalendarRightIcon } from '../Icons';

interface Props extends React.ComponentPropsWithRef<'div'> {
	direction: 'left' | 'right';
}

const NavigationButton = ({ direction, ...rest }: Props) => {
	return (
		<div {...rest} className={Styles.container}>
			{direction === 'left' ? <CalendarLeftIcon /> : <CalendarRightIcon />}
		</div>
	);
};

export default NavigationButton;
