import { NotificationListItem } from '@/app/types/Notification';
import { type MRT_TableOptions } from 'material-react-table';

export const TableStyles: Partial<MRT_TableOptions<NotificationListItem>> = {
	muiTableBodyCellProps: () => ({
		sx: {
			'&': {
				color: '#1c202d',
				fontFamily: 'Graphik',
				fontSize: 16,
				padding: '11.5px 16px',
			},
		},
	}),
	muiDetailPanelProps: {
		onClick: (e) => {
			e.stopPropagation();
		},
		sx: {
			background: '#f7f8f9',
		},
	},
	muiTableHeadCellProps: {
		sx: {
			'&': {
				paddingTop: 0,
				paddingBottom: 0,
				color: '#1c202d',
				fontFamily: 'Graphik-Medium',
				fontSize: 12,
				boxShadow: 'unset',
			},
			'& .Mui-TableHeadCell-Content-Wrapper': {
				opacity: 0.5,
			},
			'&:first-of-type': {
				// display: 'none',
				// padding: 0,
				// margin: 0,
				// background: 'green',
				// width: 0,
				// minWidth: 0,
			},
		},
	},
	muiTableHeadRowProps: {
		style: {
			boxShadow: 'unset',
		},
	},
	layoutMode: 'grid',
	displayColumnDefOptions: {
		'mrt-row-actions': {
			muiTableHeadCellProps: {
				style: {
					// display: 'none',
				},
			},
			muiTableBodyCellProps: {
				style: {
					// display: 'flex',
					// justifyContent: 'flex-end',
					// flex: 1,
				},
			},
		},
		'mrt-row-expand': {
			minSize: 0,
			size: 0,
			muiTableBodyCellProps: {
				style: {
					display: 'none',
				},
			},
			muiTableHeadCellProps: {
				style: {
					display: 'none',
				},
			},
		},
	},

	muiTableBodyRowProps: ({ row, table }) => ({
		hover: false,
		onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //set only this row to be expanded
		sx: {
			'&:hover  ': {
				backgroundColor: '#f7f8f9',
			},
			'&:hover > td ': {
				backgroundColor: '#f7f8f9',
			},
			'& > td': {
				backgroundColor: row.getIsExpanded() ? '#f7f8f9' : '#fff',
			},
			'& > td:first-of-type': {
				// display: 'none',
				// width: 0,
				// minWidth: 0,
				// padding: 0,
			},
		},
	}),
};
