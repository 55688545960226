import Styles from './index.module.scss';
import { SearchInput } from '@/components/Elements/SearchInput';
import { useEffect, useState } from 'react';
import { useDebounce } from '@/app/hooks/useDebounce';

interface Props {
	onSearch?: (e: string) => void;
	placeholder: string;
	children?: React.ReactNode;
}

export const TableSearchView = ({ onSearch, placeholder, children }: Props) => {
	const [value, setValue] = useState('');
	const debouncedValue = useDebounce(value);

	useEffect(() => {
		onSearch?.(debouncedValue);
	}, [debouncedValue, onSearch]);

	return (
		<div className={Styles.container}>
			<div className={Styles.header}>
				<SearchInput
					value={value}
					onChange={(e) => {
						setValue(e.target.value);
					}}
					placeholder={placeholder}
				/>
			</div>
			<div className={Styles.content}>{children}</div>
		</div>
	);
};
