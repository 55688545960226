import {
	GeneralInfoFormValues,
	SettingsFormValues,
	UserInfoFormValues,
} from '@/app/types/OrganizationFormValues';

export const generalInfoDefaultValues: GeneralInfoFormValues = {
	organisationName: '',
	availableHostName: undefined,
	parentOrganization: '',
	organizationType: 'customer',
	organizationLogo: undefined,
	country: '',
	city: '',
	address: '',
	timeZone: 'Europe/Stockholm',
	ownerFirstName: '',
	ownerLastName: '',
	ownerEmail: '',
	ownerPassword: '',
};

export const userInfoDefaultValues: UserInfoFormValues = {
	users: [],
};

export const settingsDefaultValues: SettingsFormValues = {
	shiftPlanner: {
		activities: [],
		workingHours: {
			monday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
			tuesday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
			wednesday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
			thursday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
			friday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
			saturday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
			sunday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
		},
		zoomLevel: 30,
		timeSelectionInterval: 15,
	},
	tasks: {
		defaultTaskDuration: 5,
		defaultRemindersActive: true,
		reminderBeforeStart: {
			active: true,
			value: 15,
		},
		reminderBeforeEnd: {
			active: true,
			value: 15,
		},
		reminderOverdue: {
			active: true,
			value: 15,
		},
		reminderOverdueInterval: 15,
		overdueRemindersNumber: 2,
	},
	actions: {
		autoForward: [1, 2, 3],
		sendFeedback: true,
		acceptanceFeedback: {
			active: true,
			value: '',
		},
		rejectionFeedback: {
			active: true,
			value: '',
		},
		acceptedByMessage: true,
		acceptedByMessageTitle: '',
		acceptedByMessageBody: '',
		useSplashScreen: true,
		completionConfirmation: true,
		incomingMessageDuration: 15,
	},
	automation: {
		blockMultiplePresses: true,
		minTimeBetweenPushes: 5,
	},
	tablet: {
		status: 'active',
		workingHours: {
			monday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
			tuesday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
			wednesday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
			thursday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
			friday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
			saturday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
			sunday: {
				start: {
					hours: 9,
					minutes: 0,
				},
				end: {
					hours: 18,
					minutes: 0,
				},
			},
		},
		noResponseTimer: 10,
		templates: [],
	},
	watch: {
		welcomeMessage: '',
		goodbyeMessage: '',
		callForHelp: '',
		watchFaceForceReturn: 20,
		logo: undefined,
	},
};
