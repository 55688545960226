import Styles from './index.module.scss';
import { Button } from '@/components/Elements/Button';
import { Control, FieldPath, FieldValues } from 'react-hook-form';

interface Props<T extends FieldValues> {
	control: Control<T>;
	checklistPath: FieldPath<T>;
	isLoading: boolean;
}

export const TaskForm = <T extends FieldValues>({ isLoading }: Props<T>) => {
	return (
		<div className={Styles.container}>
			{/* <section>
				<Controller
					control={control}
					name={checklistPath}
					render={({ field: { onChange, value } }) => (
						<Checklist onChange={onChange} value={value} />
					)}
				/>
			</section>

			<section>
				<div className={`${Styles.field} ${Styles.checkField}`}>
					<RepeatIcon width={16} height={16} viewBox="0 0 12 12" />
					<span>Repeat</span>
					<CheckBox value={false} disabled={true} />
				</div>
				<SelectTextOptions
					value={[
						{ title: 'Everyday', value: false },
						{ title: 'Weekdays', value: false },
						{ title: 'Weekend', value: false },
						{ title: 'Custom', value: true },
					]}
				/>
				<SelectCheckbox
					value={[
						{ title: 'Mon', value: true },
						{ title: 'Tue', value: true },
						{ title: 'Wed', value: false },
						{ title: 'Thu', value: false },
						{ title: 'Fri', value: false },
						{ title: 'Sat', value: false },
						{ title: 'Sun', value: false },
					]}
				/>
			</section> */}

			<div className={Styles.submitArea}>
				<Button type="submit" disabled={isLoading}>
					Send Task
				</Button>
			</div>
		</div>
	);
};
