import { msalInstance, scopes } from '@/services/msal';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
	reducerPath: 'api',
	tagTypes: ['NOTIFICATIONS', 'TASKS', 'ORGANIZATIONS', 'USERS', 'SETTINGS', 'SYSTEM_STATUS'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${import.meta.env.VITE_API_BASE_URL}`,
		prepareHeaders: async (headers) => {
			const account = msalInstance.getAllAccounts()[0];
			if (account) {
				const accessTokenResponse = await msalInstance.acquireTokenSilent({
					scopes: scopes,
					account: account,
				});

				if (accessTokenResponse) {
					const token = accessTokenResponse.accessToken;

					if (headers && token) {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
						headers.set('Authorization', `Bearer ${token}`);
					}
				}
			}

			return headers;
		},
	}),

	endpoints: () => ({}),
});
