import Styles from './index.module.scss';
import { OrganizationsTableProps, SortState } from './types';
import { MoreCell } from './MoreCell';
import { Header } from './Header';
import { LoadingCell } from './LoadingCell';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useElementSize from '@/app/hooks/useElementSize';

export const OrganizationsTable = ({ data, isFetching }: OrganizationsTableProps) => {
	const loadingRowsLength = useRef(10);
	const [sortState, setSortState] = useState<SortState>({ column: 'name', ascending: true });
	const tableRef = useRef<HTMLTableElement>(null);

	const [wrapperRef, { width }] = useElementSize();

	useLayoutEffect(() => {
		if (tableRef.current) {
			tableRef.current.style.width = `${width}px`;
		}
	}, [width]);

	const sorted = data?.sort((a, b) => {
		if (sortState.column === 'name') {
			return sortState.ascending
				? a.name.localeCompare(b.name)
				: b.name.localeCompare(a.name);
		}

		if (sortState.ascending) {
			return a[sortState.column] > b[sortState.column] ? 1 : -1;
		} else {
			return a[sortState.column] < b[sortState.column] ? 1 : -1;
		}
	});

	useEffect(() => {
		if (!isFetching) {
			loadingRowsLength.current = data?.length || 10;
		}
	});

	return (
		<div ref={wrapperRef} className={Styles.wrapper}>
			<table
				ref={tableRef}
				className={`${Styles.container} ${isFetching ? Styles.fetching : ''}`}
			>
				<thead>
					<tr>
						<Header
							isSortable={true}
							ascending={sortState.ascending}
							title="name"
							tableRef={tableRef}
							onClick={() => {
								setSortState({ column: 'name', ascending: !sortState.ascending });
							}}
						/>
						<Header tableRef={tableRef} title="country" />
						<Header tableRef={tableRef} title="city" />
						<Header tableRef={tableRef} minSize={200} title="created" />
						<Header tableRef={tableRef} minSize={200} title="modified" />
						<Header tableRef={tableRef} title="total devices" />
						<Header tableRef={tableRef} title="total users" />
						<th style={{ width: 30 }}>
							<div />
						</th>
					</tr>
				</thead>
				<tbody>
					{isFetching && (
						<>
							{new Array(loadingRowsLength.current).fill(0).map((_, index) => {
								return (
									<tr key={index}>
										<LoadingCell />
										<LoadingCell />
										<LoadingCell />
										<LoadingCell />
										<LoadingCell />
										<LoadingCell />
										<LoadingCell />
										<td />
									</tr>
								);
							})}
						</>
					)}
					{!isFetching && (
						<>
							{sorted.map((item) => {
								return (
									<tr key={item.id}>
										<td>{item.name}</td>
										<td>{item.country}</td>
										<td>{item.city}</td>
										<td>{item.created}</td>
										<td>{item.modified}</td>
										<td>{item.devices}</td>
										<td>{item.users}</td>
										<MoreCell id={item.id} />
									</tr>
								);
							})}
						</>
					)}
				</tbody>
			</table>
		</div>
	);
};
