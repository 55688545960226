import React from 'react';
import Styles from './index.module.scss';
import { SettingsMinusIcon, SettingsPlusIcon } from '../Icons';

const INTERVALS = [30, 20, 15, 10];

interface Props extends Omit<React.ComponentPropsWithRef<'div'>, 'onChange'> {
	value?: number;
	onChange?: (e: number) => void;
}

export const ZoomPicker = ({ value = 30, onChange, ...props }: Props) => {
	return (
		<div {...props} className={`${Styles.container}`}>
			<button
				type="button"
				onClick={() => {
					onChange?.(
						INTERVALS.indexOf(value) === 0
							? INTERVALS[INTERVALS.length - 1]
							: INTERVALS[INTERVALS.indexOf(value) - 1]
					);
				}}
			>
				<SettingsMinusIcon />
			</button>
			<div className={Styles.track}>
				<div className={Styles.slider}>
					{INTERVALS.map((interval) => {
						return (
							<div
								key={interval}
								className={`${Styles.thump} ${interval === value ? Styles.active : ''}`}
								onClick={() => {
									onChange?.(interval);
								}}
							>
								<div className={Styles.marker} />
							</div>
						);
					})}
				</div>
			</div>
			<button
				type="button"
				onClick={() => {
					onChange?.(
						INTERVALS.indexOf(value) === INTERVALS.length - 1
							? INTERVALS[0]
							: INTERVALS[INTERVALS.indexOf(value) + 1]
					);
				}}
			>
				<SettingsPlusIcon />
			</button>
		</div>
	);
};
