import Styles from './index.module.scss';
import { SettingsStarIcon, SettingsUserIcon } from '@/components/Elements/Icons';
import { Input } from '@/components/Elements/Input';
import { Button } from '@/components/Elements/Button';
import { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { UserInfoFormValues } from '@/app/types/OrganizationFormValues';
import { createEmailFromOrganizationName } from '@/app/utils/organization/strings';
import { withPasswordReveal } from '@/components/Elements/PasswordRevealInput';

const PasswordRevealInput = withPasswordReveal(Input);

interface Props {
	defaultUsersCount: number;
	organizationName: string;
}

export const FormUserInfo = ({ defaultUsersCount, organizationName }: Props) => {
	const { control } = useFormContext<UserInfoFormValues>();
	const [userCount, setUserCount] = useState(defaultUsersCount);
	// const users = useWatch({ control, name: 'users', defaultValue: [] });

	const { fields, remove, update, append } = useFieldArray({ control, name: 'users' });

	return (
		<div className={Styles.wrapper}>
			<div className={Styles.container}>
				<p>User Info</p>
				<div className={`${Styles.fieldSet}`}>
					<SettingsStarIcon className={Styles.fieldIcon} />
					<span className={Styles.fieldTitle}>Number of user for organization</span>
					<div className={Styles.fieldFlex}>
						<span className={Styles.fieldSubtitle}>
							By default users will be part of “Everyone” group
						</span>
					</div>
					<div className={Styles.fieldFlex}>
						<Input
							placeholder="Enter Number"
							value={userCount === 0 ? '' : userCount}
							onChange={(e) => {
								if (e.target.value) {
									setUserCount(parseInt(e.target.value));
								} else {
									setUserCount(0);
								}
							}}
							className={Styles.smallInput}
						/>
						<Button
							type="button"
							className={Styles.fieldButton}
							disabled={userCount === 0 || isNaN(userCount)}
							onClick={() => {
								remove(fields.map((_, index) => index));
								new Array(userCount).fill(0).forEach(() => {
									append({
										firstName: '',
										lastName: '',
										email: '',
										password: '',
										pin: '',
									});
								});
							}}
						>
							Apply
						</Button>
						<Button
							type="button"
							className={Styles.fieldButton}
							disabled={fields?.length === 0}
							onClick={() => {
								fields.forEach((field, index) => {
									const currentIndex = index + 1;
									const pinSuffix = `${currentIndex}`.padStart(3, '0');
									return update(index, {
										firstName: 'Watch',
										lastName: `${currentIndex}`.padStart(2, '0'),
										email: `user${currentIndex}@${createEmailFromOrganizationName(organizationName)}`,
										pin: `${1}${pinSuffix}`,
										password: `WatchUser${currentIndex}`,
									});
								});
							}}
						>
							Populate
						</Button>
					</div>
				</div>
				{fields?.map((field, index) => {
					return (
						<div key={field.id} className={Styles.fieldSet}>
							<SettingsUserIcon className={Styles.fieldIcon} />
							<span className={Styles.fieldTitle}>
								{`${index + 1}`.padStart(2, '0')} User Details<span>*</span>
							</span>
							<div className={`${Styles.fieldGridPassword} ${Styles.singleColumn}`}>
								<span className={Styles.fieldSmallTitle}>Name</span>
								<div className={Styles.inputContainer}>
									<Controller
										control={control}
										name={`users.${index}.firstName`}
										defaultValue={''}
										render={({ field }) => {
											return (
												<Input
													value={field.value}
													onChange={field.onChange}
													placeholder="Firstname"
												/>
											);
										}}
									/>
									<div className={Styles.separator} />
									<Controller
										control={control}
										name={`users.${index}.lastName`}
										defaultValue={''}
										render={({ field }) => {
											return (
												<Input
													value={field.value}
													onChange={field.onChange}
													placeholder="Lastname"
												/>
											);
										}}
									/>
								</div>
								<span className={Styles.fieldSmallTitle}>Email</span>
								<Controller
									control={control}
									name={`users.${index}.email`}
									defaultValue={''}
									render={({ field }) => {
										return (
											<Input
												type="email"
												value={field.value}
												onChange={field.onChange}
												placeholder="owner@organizationname.com"
											/>
										);
									}}
								/>
								<div className={Styles.fieldGridPassword}>
									<span className={Styles.fieldSmallTitle}>Password</span>
									<span className={Styles.fieldSmallTitle}>Watch-PIN</span>
									<Controller
										control={control}
										name={`users.${index}.password`}
										defaultValue={''}
										render={({ field }) => {
											return (
												<PasswordRevealInput
													value={field.value}
													onChange={field.onChange}
													type="password"
													placeholder="Set Password"
												/>
											);
										}}
									/>

									<Controller
										control={control}
										name={`users.${index}.pin`}
										defaultValue={''}
										render={({ field }) => {
											return (
												<PasswordRevealInput
													value={field.value}
													onChange={field.onChange}
													type="password"
													placeholder="Set Password"
												/>
											);
										}}
									/>
								</div>
							</div>
							{index === 0 && (
								<span className={Styles.description}>
									Password must be minimum eight characters, at least one
									uppercase letter, one lowercase letter and one number
								</span>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};
