import {
	TotalActionsPerOrganizationItem,
	TotalActionsPerOrganizationTriggerItem,
} from '@/app/types/Dashboard';
import { useMemo } from 'react';

export const config: Highcharts.Options = {
	chart: {
		backgroundColor: 'transparent',
		type: 'column',
		marginLeft: 48,
		marginRight: 0,
	},
	accessibility: {
		enabled: false,
	},
	plotOptions: {
		column: {
			pointPadding: 0,
			groupPadding: 0,
			pointPlacement: 'between',
			dataSorting: {
				enabled: true,
			},
		},
	},
	credits: {
		enabled: false,
	},
	title: {
		text: '',
	},
	legend: {
		itemStyle: {
			fontSize: '10px',
			fontFamily: 'Graphik',
		},
		verticalAlign: 'bottom',
		// layout: 'proximate',
		align: 'left',
		alignColumns: true,
		backgroundColor: 'transparent',
		padding: 12,
		margin: 0,
		itemMarginTop: 0,
		y: 16,
		x: 4,

		symbolHeight: 7,
		symbolWidth: 7,
		symbolPadding: 3,
	},
	xAxis: {
		visible: false,
		gridLineWidth: 0,
		labels: {
			enabled: false,
		},
		categories: [''],
	},
	yAxis: {
		title: {
			text: 'Number of Actions',
			style: {
				fontSize: '14px',
				color: '#B6B6B6',
				fontFamily: 'Graphik',
				fontWeight: '600',
				textOverflow: 'none',
				width: 150,
			},
		},
		gridLineDashStyle: 'Dash',
		gridLineColor: '#9194A1',
	},
	series: [],
};

interface SeriesData {
	name: string;
	data: number[];
	type: string;
	pointWidth: number;
	triggerData: TotalActionsPerOrganizationTriggerItem[];
	itemTotal: number;
}

interface PointFormatterContext {
	series: {
		name: string;
		userOptions: SeriesData;
	};
	color: string;
	y: number;
}

export const useFormattedData = (data: TotalActionsPerOrganizationItem[] = []) => {
	return useMemo(() => {
		try {
			if (!data || data.length === 0) {
				return null;
			}

			const itemSeries: SeriesData[] = data.map((item) => {
				return {
					name: item.organizationName,
					data: [item.organizationTotal],
					type: 'column',
					pointWidth: 20,
					triggerData: item.triggeredData,
					itemTotal: item.organizationTotal,
				};
			});

			return {
				...config,
				tooltip: {
					useHTML: true,
					headerFormat: '',
					pointFormatter: function (this: PointFormatterContext) {
						const triggerData = this.series.userOptions.triggerData || [];
						const triggerInfo = triggerData
							.map((trigger: TotalActionsPerOrganizationTriggerItem) => {
								return ` ${trigger.percentage}% ${trigger.trigger || 'N/A'} (${trigger.triggerGroup})`;
							})
							.join('<br>');

						return `
						<div class="tooltip-container">
						<p class="tooltip-header"><span style="color:${this.color}">\u25CF</span>
						${this.series.name}</br><b>
						Total: ${this.y}</p>
						</b>
						<hr>${triggerInfo}
						</div>`;
					},
				},
				series: itemSeries,
			};
		} catch (error) {
			return null;
		}
	}, [data]);
};
