import Styles from './index.module.scss';

export const LoadingCell = () => {
	const lengths = [50, 90, 120];
	return (
		<td>
			<span
				className={Styles.loading}
				style={{ paddingLeft: lengths[Math.floor(Math.random() * lengths.length)] }}
			></span>
		</td>
	);
};
