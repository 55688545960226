import { NotificationListItem } from '@/app/types/Notification';
import { MRT_ActionMenuItem } from 'material-react-table';
import { Delete, Edit } from '@mui/icons-material';

import { type MRT_TableOptions } from 'material-react-table';

export const ActionMenu: Partial<MRT_TableOptions<NotificationListItem>> = {
	renderRowActionMenuItems: ({ table }) => {
		return [
			<MRT_ActionMenuItem
				icon={<Edit />}
				key="edit"
				label="Edit"
				onClick={() => console.info('Edit')}
				table={table}
			/>,
			<MRT_ActionMenuItem
				icon={<Delete />}
				key="delete"
				label="Delete"
				onClick={() => console.info('Delete')}
				table={table}
			/>,
		];
	},
};
