import moment, { Moment } from 'moment';

const formatStart = (time: Moment) => {
	if (!time.isValid()) {
		return '';
	}
	if (moment(new Date()).isSame(time, 'day')) {
		return 'Today';
	}

	if (moment(new Date()).add(-1, 'day').isSame(time, 'day')) {
		return 'Yesterday';
	}

	return `${time.format('ddd, DD MMM')}`;
};

const formatEnd = (time: Moment) => {
	if (!time.isValid()) {
		return '';
	}

	if (moment(new Date()).isSame(time, 'day')) {
		return 'Today';
	}

	if (moment(new Date()).add(-1, 'day').isSame(time, 'day')) {
		return 'Yesterday';
	}

	return `${time.format('ddd, DD MMM')}`;
};

const formatCompleted = (time: Moment) => {
	if (moment(new Date()).isSame(time, 'day')) {
		return `Today ${time.format('HH:mm')}`;
	}

	if (moment(new Date()).add(-1, 'day').isSame(time, 'day')) {
		return `Yesterday, ${time.format('HH:mm')}`;
	}

	return `${time.format('ddd, DD MMM')}, ${time.format('HH:mm')}`;
};

export default {
	formatStart,
	formatEnd,
	formatCompleted,
};
